/**
 *
 * @param {Date} startPeriod
 * @param {Date} endPeriod
 * @param {string} service
 * @param {string} options
 * @returns
 */
const ecbGenericDownload = async (startPeriod, endPeriod, service, options) => {
  const url = new URL(`/service/data/${service}/${options.toUpperCase()}`, 'https://sdw-wsrest.ecb.europa.eu');
  url.searchParams.append('startPeriod', startPeriod.toISOString().substring(0, 10));
  url.searchParams.append('endPeriod', endPeriod.toISOString().substring(0, 10));
  url.searchParams.append('detail', 'dataonly');
  const init = { headers: { Accept: 'application/vnd.sdmx.data+json;version=1.0.0-wd' } };
  const response = await fetch(url, init);
  const body = await response.json();
  return body;
};

export default ecbGenericDownload;
