import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import ServerRequest from 'models/ServerRequest';
import MessageContext from 'contexts/MessageContext';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const AdminSettings = () => {
  const { authorities: { ADMIN } } = React.useContext(AppViewContext);
  const { download } = React.useContext(DownloadContext);
  const { setMessage } = React.useContext(MessageContext);

  const clickTest = async () => {
    const request = new ServerRequest('/admin/fix-database');
    const isSuccess = await download.noContent(request, 'Error fixing database');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickISINtoLEI = async () => {
    const request = new ServerRequest('/admin/download-isin-lei');
    const isSuccess = await download.noContent(request, 'Error downloading ISIN-LEI');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickISINtest = async () => {
    const request = new ServerRequest('/admin/isin-test');
    const isSuccess = await download.noContent(request, 'Error testing ISIN');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickECB = async () => {
    const request = new ServerRequest('/admin/download-ecb');
    const isSuccess = await download.noContent(request, 'Error downloading ECB');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickTags = async () => {
    const request = new ServerRequest('/admin/fix-tags');
    const isSuccess = await download.noContent(request, 'Error fixing Tags');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickESMA = async () => {
    const request = new ServerRequest('/esma/download');
    const isSuccess = await download.noContent(request, 'Error downloading ESMA securities');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickTest2 = async () => {
    const request = new ServerRequest('/nit/anna/problems');
    const isSuccess = await download.json(request, 'Error downloading ESMA securities');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  const clickClean = async () => {
    const request = new ServerRequest('/nit/anna/clean');
    const isSuccess = await download.noContent(request, 'Error downloading ESMA securities');
    if (isSuccess) setMessage({ text: 'hurray all done', type: 'success' });
  };

  return ADMIN.includes('SUPER') ? (
    <Card className="co-card-stack" key="ADMIN">
      <div className="co-item">
        <Typography type="h1">Database management</Typography>
      </div>
      <div className="form-buttons">
        <Button onClick={clickTest}>Fix Database</Button>
        <Button onClick={clickISINtoLEI}>ISIN to LEI</Button>
        <Button onClick={clickTags}>Fix Tags</Button>
        <Button onClick={clickISINtest}>ISIN test</Button>
        <Button onClick={clickECB}>ECB</Button>
        <Button onClick={clickESMA}>ESMA</Button>
        <Button onClick={clickTest2}>ANNA problems</Button>
        <Button onClick={clickClean}>Clean ANNA</Button>
      </div>
    </Card>
  ) : '';
};

export default AdminSettings;
