import DateRange from './DateRange';
import PeriodRange from './PeriodRange';

/**
 * Object consisting of an absolute range (DateRange) and a relative range (PeriodRange)
 */
class TimeRange {
  constructor(timeRange) {
    Object.assign(this, timeRange);

    if ('absolute' in this) {
      if (this.absolute !== null && ('start' in this.absolute || 'end' in this.absolute)) {
        this.absolute = new DateRange(this.absolute);
      } else {
        delete this.absolute;
      }
    }

    if ('relative' in this) {
      if (this.relative !== null && ('start' in this.relative || 'end' in this.relative)) {
        this.relative = new PeriodRange(this.relative);
      } else {
        delete this.relative;
      }
    }
  }

  toString() {
    if (this.absolute && ('start' in this.absolute || 'end' in this.absolute)) {
      return this.absolute.toString();
    } if (this.relative && ('start' in this.relative || 'end' in this.relative)) {
      return this.relative.toString();
    }
    return '';
  }

  isEmpty() {
    if ('absolute' in this && !this.absolute.isEmpty()) return false;
    if ('relative' in this && !this.relative.isEmpty()) return false;
    return true;
  }

  update(name, value) {
    return new TimeRange({ ...this, [name]: value });
  }
}

export default TimeRange;
