class FileId {
  constructor(fileId) {
    Object.assign(this, fileId);
  }

  toString() {
    return `${this.attachmentId}-${this.messageId}-${this.streamId}`;
  }
}

export default FileId;
