import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Workflow from '../../model/Workflow';

import WorkflowTable from './WorkflowTable';
import WorkflowForm from './WorkflowForm';

const Workflows = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [workflows, setWorkflows] = React.useState();

  const service = new CrudService({ download, name: 'Workflows', path: '/mtn/workflows' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedWorkflows = body.map((u) => new Workflow(u));
        setWorkflows(downloadedWorkflows);
      } else {
        setError(true);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setDetails(new Workflow());
      await setView('FORM');
    },
    save: async (data) => {
      const body = await service.update(data);

      if (body !== null) {
        const updatedWorkflow = new Workflow(body);
        const updatedWorkflows = CrudService.updateData(workflows, updatedWorkflow);
        setWorkflows(updatedWorkflows);
        await setView('TABLE');
      }
    },
    delete: async ({ original: workflow }) => {
      const body = await service.delete(workflow);
      if (body) {
        const filteredWorkflows = workflows.filter((u) => u.id !== workflow.id);
        setWorkflows(filteredWorkflows);
      }
    },
    edit: async ({ original: workflow }) => {
      setDetails(workflow);
      await setView('FORM');
    },

  };

  switch (view) {
    case 'FORM':
      return (
        <WorkflowForm
          workflow={details}
          handle={handle}
        />
      );
    case 'TABLE':
      return (
        <WorkflowTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          workflows={workflows}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Workflows;
