import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import ServerRequest from 'models/ServerRequest';

const AnnaCompanyModal = ({ company, hideModal, updateCompany }) => {
  const [data, setData] = React.useState(company);
  const [isLoading, setLoading] = React.useState(false);

  const onChange = {
    input(event) {
      const { name, value } = event.target;
      if (name === 'nameLong') {
        setData({ ...data, nameLong: value });
      } else {
        const [type, field] = name.split('.');
        const address = { ...data[type], [field]: value };
        setData({ ...data, [type]: address });
      }
    },
  };

  const onClick = {
    save: async () => {
      setLoading(true);

      const body = { ...data };
      delete body.companyTags;
      delete body.actionMenu;

      const request = new ServerRequest('/nit/anna/company', {
        body: JSON.stringify(body),
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
      });

      await fetch(request);
      setLoading(false);

      updateCompany(data);
      hideModal();
    },

  };

  const test = (value) => value && value.includes('��');

  const items = () => {
    const addressTypes = ['reg', 'hq'];
    const addressItems = ['address1', 'address2', 'city', 'country', 'postalCode'];
    const list = [];
    addressTypes.forEach((type) => {
      addressItems.forEach((item) => {
        if (test(company[type]?.[item])) {
          list.push(
            <TextField key={`${type}.${item}`} label={`${type} ${item}`} name={`${type}.${item}`} onChange={onChange.input} value={data[type][item] || ''} />,
          );
        }
      });
    });
    return list;
  };

  return (
    <Modal className="co-items" show closeButton onClose={hideModal} parentNode={document.body}>
      <div>
        <Typography type="h1">Correct Diacriticals</Typography>
      </div>
      <div>
        {test(company.nameLong) ? <TextField label="Name Long" name="nameLong" onChange={onChange.input} value={data.nameLong || ''} /> : ''}
        {items()}
      </div>
      <div className="co-form-buttons">
        <Button loading={isLoading} onClick={onClick.save}>Save</Button>
      </div>
    </Modal>
  );
};

AnnaCompanyModal.propTypes = {
  company: PropTypes.shape({
    nameLong: PropTypes.string,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
};

export default AnnaCompanyModal;
