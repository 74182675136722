/* global SYMPHONY */

import PropTypes from 'prop-types';
import React from 'react';

import Loader from '@symphony-ui/uitoolkit-components/components/loader/Loader';

import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';

import { setupLinkPrefix } from 'utils/system/setup-url';
import ServerRequest from 'models/ServerRequest';

import LocationRouter from './location-router';
import AppViewContext from '../contexts/AppViewContext';

import '@symphony-ui/uitoolkit-styles/uitoolkit.scss';

const LINK_PREFIX = setupLinkPrefix();

// const LoadContainer = Styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const ContainerWrapper = Styled.div`
//   max-width: 100%;
//   margin: 0px 21px;
// `;

const Routes = ({ theme }) => {
  const [appView, setAppView] = React.useState('FRONT_PAGE');
  const [authorities, setAuthorities] = React.useState({});
  const [checked, setChecked] = React.useState(false);
  const [offline, setOffline] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const changeTheme = (newTheme) => {
    const { themeV2 } = newTheme;
    document.body.className = `symphony-external-app ${themeV2.name} ${themeV2.size}`;

    window.themeColor = themeV2.name.toUpperCase();
    window.themeSize = themeV2.size;

    // setTheme({
    //   ...PROJECT_THEMES.find((t) => t.mode === themeV2.name.toUpperCase()),
    //   size: themeV2.size,
    // });
  };

  React.useEffect(() => {
    if (appView && appView !== 'FRONT_PAGE') {
      try {
        localStorage.setItem('view', appView);
      } catch (error) {
        // unable to store settings
      }
    }
  }, [appView]);

  React.useEffect(() => {
    changeTheme(theme);
  }, []);

  // const splitAuthorities = (str) => {
  //   console.log('Splitting authorities: %o', str);
  //   const total = {};

  //   str.map((a) => a.split('_')).forEach((e) => {
  //     if (e[1] in total) {
  //       total[e[1]].push(e[2]);
  //     } else {
  //       total[e[1]] = [e[2]];
  //     }
  //   });
  //   return total;
  // };

  const moduleMap = {
    'uk.co.vanadis.cedar': 'CEDAR',
    'uk.co.vanadis.core': 'ADMIN',
    'uk.co.vanadis.nit': 'NIT',
    'uk.co.vanadis.release': 'RELEASE',
  };

  const renameAuthorities = (auth) => {
    const mapped = {};
    Object.entries(auth).forEach(([key, value]) => {
      mapped[moduleMap[key]] = value;
    });
    return mapped;
  };

  React.useEffect(() => {
    if (!checked) {
      const request = new ServerRequest('/check');

      (async () => {
        try {
          const response = await fetch(request);
          setOffline(false);
          if (response.status === 200) {
            const { authorities: foundAuthorities, company, id } = await response.json();
            setUser({ company, id });

            const total = renameAuthorities(foundAuthorities);
            setAuthorities(total);
            try {
              localStorage.setItem('authorities', JSON.stringify(total));
            } catch (error1) {
              // no access to localStorage
            }

            if (Object.keys(total).length) {
              let storedView = null;
              try {
                storedView = localStorage.getItem('view');
              } catch (error) {
              //
              }
              setAppView(storedView !== null ? storedView : 'MAIN');
            }
          } else {
            setAuthorities({});
            try {
              localStorage.removeItem('authorities');
            } catch (error) {
              //
            }
          }
        } catch (error) { // Unable to access the Server.
          //          console.error(error);
          setOffline(true);
          //          console.log('UNABLE TO REACH THE SERVER');
          try {
            const savedAuthorities = localStorage.getItem('authorities');
            if (savedAuthorities) {
              const authArray = JSON.parse(savedAuthorities);
              setAuthorities(authArray);
            }
            if (savedAuthorities.length) {
              let storedView = null;
              try {
                storedView = localStorage.getItem('view');
              } catch (error3) {
              //
              }
              setAppView(storedView !== null ? storedView : 'MAIN');
            }
          } catch (error1) {
            setAuthorities([]);
          }
        } finally {
          setChecked(true);
        }
      })();
    }
  }, [checked]);

  React.useEffect(() => {
    // setTheme({
    //   ...PROJECT_THEMES.find((t) => t.mode === window.themeColor),
    //   size: window.themeSize,
    // });

    const uiService = SYMPHONY.services.subscribe('ui');
    if (typeof uiService.listen === 'function') {
      uiService.listen('themeChangeV2',
        async () => {
          try {
            const newTheme = await SYMPHONY.remote.hello();
            changeTheme(newTheme);
          } catch (error) {
            // none
          }
        });
    }
  }, []);

  React.useEffect(() => {
    try {
      sessionStorage.setItem('retry', 4000);
    } catch (e) {
    // hello
    }
  }, []);

  const Default = () => <Redirect to={`${LINK_PREFIX}/index.html`} />;
  if (!checked) {
    return (
      <Loader />
    );
  }

  return (

    <AppViewContext.Provider value={{
      appView,
      user,
      authorities,
      offline,
      setAppView,
      setChecked,
      setOffline,
    }}
    >

      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`${LINK_PREFIX}/app.html`}
            component={() => (
              <LocationRouter />
            )}
          />
          <Route
            exact
            path={`${LINK_PREFIX}/index.html`}
            component={() => (
              <LocationRouter />
            )}
          />
          <Route component={Default} />
        </Switch>
      </BrowserRouter>

    </AppViewContext.Provider>
  // {/* </ThemeProvider> */}
  // </ContainerWrapper>
  );
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({ getJWTFromSymphony }, dispatch),
// });

// const mapStateToProps = (state) => ({
//   jwt: state.user.jwt,
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(Routes);

Routes.propTypes = {
  theme: PropTypes.shape({
    themeV2: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.string,
    }),
  }).isRequired,
};

export default Routes;
