class Security {
  constructor(report) {
    Object.assign(this, report);
  }

  static sort = (s0, s1) => {
    if (s0.issuername < s1.issuername) {
      return -1;
    } if (s0.issuername > s1.issuername) {
      return 1;
    }
    return 0;
  }
}

export default Security;
