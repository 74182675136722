import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CardHeaderTable from 'components/card-header/CardHeaderTable';
import Benchmark from 'models/Benchmark';
import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';

const BenchmarkTable = ({
  handle, hasError, isLoading, benchmarks,
}) => {
  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'name',
      header: 'Benchmark',
    },
  ]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Benchmarks" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={benchmarks}
        emptyMessage="You have no benchmarks yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

BenchmarkTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  benchmarks: PropTypes.arrayOf(PropTypes.instanceOf(Benchmark)),
};

BenchmarkTable.defaultProps = {
  benchmarks: [],
  hasError: false,
  isLoading: false,
};

export default BenchmarkTable;
