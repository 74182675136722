import ServerRequest from '../models/ServerRequest';

class CrudService {
  constructor({
    name, path, download,
  }) {
    this.name = name;
    this.path = path;
    this.download = download;
  }

  delete = async (data) => {
    const request = new ServerRequest(this.path, { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });

    return this.download.noContent(request, `Error deleting ${this.name}`);
  }

  create = async (data) => {
    const request = new ServerRequest(this.path, { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'POST' });

    return this.download.json(request, `Error saving ${this.name}`);
  }

  update = async (data) => {
    const request = new ServerRequest(this.path, { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });

    return this.download.json(request, `Error saving ${this.name}`);
  }

  get = async () => {
    const request = new ServerRequest(this.path);

    return this.download.json(request, `Error downloading ${this.name}`);
  };

  static updateData(data, item) {
    console.log('DATA: %o, ITEM: %o', data, item);
    const index = data.findIndex((d) => d.id === item.id);
    console.log('INDEC = %o', index);
    const newData = [...data];
    if (index === -1) {
      newData.push(item);
    } else {
      newData[index] = item;
    }
    return newData;
  }
}

export default CrudService;
