import PropTypes from 'prop-types';
import React from 'react';

import Switch from '@symphony-ui/uitoolkit-components/components/selection/Switch';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const PermissionGroup = ({ label, setValue, value }) => {
  const onChange = (name) => (event) => {
    const newValue = { ...value, [name]: event.target.value === 'false' };
    setValue(newValue);
  };

  return (
    <div>
      <Typography type="h3">{label}</Typography>
      <Switch label="Read" name="read" onChange={onChange('read')} status={value.read ? 'checked' : 'unchecked'} value={value.read ? 'true' : 'false'} />
      <Switch label="Write" name="write" onChange={onChange('write')} status={value.write ? 'checked' : 'unchecked'} value={value.write ? 'true' : 'false'} />
    </div>
  );
};

PermissionGroup.propTypes = {
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.shape({
    read: PropTypes.bool,
    write: PropTypes.bool,
  }).isRequired,
};

export default PermissionGroup;
