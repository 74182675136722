import PropTypes from 'prop-types';
import React from 'react';

import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import { change, validationChanged } from 'utils/change';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import { errorMessage } from 'utils/initialValidation';
import Currency from '../../models/Currency';

const CurrencyForm = ({ currency, save }) => {
  const [data, setData] = React.useState(currency);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    value: [Validators.Required, Validators.MinLength(3)],
  };

  const onChange = change(data, setData);
  const onValidationChanged = validationChanged(errorState, setErrorState);

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      name="Currency"
      save={save}
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="currency-form">

        <Validation validator={validators.value} errorMessage={errorMessage} onValidationChanged={onValidationChanged('value')}>
          <TextField label="Name" maxLength="3" name="value" onChange={onChange.input} value={data.value || ''} />
        </Validation>

      </div>

    </FormWrapper>
  );
};

CurrencyForm.propTypes = {
  currency: PropTypes.instanceOf(Currency),
  save: PropTypes.func.isRequired,
};

CurrencyForm.defaultProps = {
  currency: new Currency(),
};

export default CurrencyForm;
