import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Company from '../../models/Company';

import CompanyTable from './CompanyTable';
import CompanyForm from './CompanyForm';

const Companies = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [companies, setCompanies] = React.useState();
  const [details, setDetails] = React.useState();
  const [isLoading, setLoading] = React.useState(true);
  const [hasError, setError] = React.useState(false);
  const [types, setTypes] = React.useState([]);

  const service = new CrudService({ download, name: 'Company', path: '/core/companies' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedCompanies = body.map((u) => new Company(u));
        setCompanies(downloadedCompanies);
      } else {
        setError(true);
      }
    })();

    (async () => {
      const request = new ServerRequest('/core/companies/type/options');
      const body = await download.json(request, 'Unable to download types');
      if (body !== null) {
        setTypes(body);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setDetails(new Company());
      await setView('FORM');
    },
    delete: async ({ original: company }) => {
      const body = await service.delete(company);
      if (body) {
        const filteredCompanies = companies.filter((u) => u.id !== company.id);
        setCompanies(filteredCompanies);
      }
    },
    edit: async ({ original: company }) => {
      setDetails(company);
      await setView('FORM');
    },
    save: async (data) => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedCompany = new Company(body);
        const updatedCompanies = CrudService.updateData(companies, updatedCompany);
        setCompanies(updatedCompanies);
        await setView('TABLE');
      }
    },

  };

  switch (view) {
    case 'FORM':
      return (
        <CompanyForm
          company={details}
          save={handle.save}
          types={types}
        />
      );
    case 'TABLE':
      return (
        <CompanyTable
          companies={companies}
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          types={types}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Companies;
