import FileId from './FileId';

class FileInfo {
  constructor(info) {
    Object.assign(this, info);

    if (typeof this.fileId === 'object') {
      this.fileId = new FileId(this.fileId);
    }
  }
}

export default FileInfo;
