import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';

import { Button, Typography } from '@symphony-ui/uitoolkit-components';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import Company from '../../models/Company';

const CompanyTable = ({
  handle, hasError, isLoading, companies, types,
}) => {
  const { download } = React.useContext(DownloadContext);

  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'name',
      className: 'co-table-text co-table-text-xl',
      header: 'Name',
    },
    {
      accessor: 'lei',
      className: 'co-table-lei',
      header: 'LEI',
    },
    {
      accessor: (originalRow) => types.filter((t) => originalRow.types.includes(t.value)).map((t) => t.label).sort().join(', '),
      header: 'Types',
      id: 'types',
    },
  ]);

  const click = {
    bloomberg() {
      const request = new ServerRequest('/core/companies/tickers');
      download.noContent(request, 'Unable to download Tickers');
    },
    download() {
      const request = new ServerRequest('/mic/download');
      download.noContent(request, 'Unable to download MICs');
    },
  };

  return (
    <Card>

      <div className="card-header">
        <Typography type="h1">
          Companies
        </Typography>
        <div className="co-dropdown-row">
          <Button disabled={hasError} onClick={click.bloomberg}>Download Tickers</Button>
          <Button disabled={hasError} onClick={click.download}>Download MICs</Button>
          <Button disabled={hasError} onClick={handle.create}>Create New</Button>
        </div>
      </div>
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={companies}
        emptyMessage="You have no companies yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

CompanyTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.instanceOf(Company)),
  types: PropTypes.arrayOf(PropTypes.shape({

  })).isRequired,
};

CompanyTable.defaultProps = {
  companies: [],
  hasError: false,
  isLoading: false,
};

export default CompanyTable;
