import Period from 'nit/models/Period';
import DateRange from '../models/DateRange';
import PeriodRange from '../models/PeriodRange';
import TimeRange from '../models/TimeRange';
import { actionCell, dateCell, numberCell } from '../../core/utils/table';

const parseNumber = (number) => (number ? Number(number) : number);

const parseDate = (date) => {
  if (date === '9999-12-31 00:00:00.0') {
    return 'Perpetual';
  }
  return (date ? new Date(date) : date);
};

/**
 * ESMA searches contain date ranges as an array. These have to be changed
 * to a TimeRange object first before being printed.
 *
 * @param {*} dateArray
 * @returns
 */
const parseDateArray = (dateArray) => {
  const timeRange = new TimeRange();

  const [absoluteStart, absoluteEnd, relativeStart, relativeEnd] = dateArray;
  if (absoluteStart || absoluteEnd) {
    timeRange.absolute = new DateRange({ start: absoluteStart, end: absoluteEnd });
  } else if (relativeStart || relativeEnd) {
    timeRange.relative = new PeriodRange({
      start: Period.from(relativeStart),
      end: Period.from(relativeEnd),
    });
  }
  return timeRange;
};

const esma = {
  objectify(security) {
    return {
      ...security,
      bnd_maturity_date: parseDate(security.bnd_maturity_date),
      bnd_nmnl_value_total: parseNumber(security.bnd_nmnl_value_total),
      bnd_nmnl_value_unit: parseNumber(security.bnd_nmnl_value_unit),
      mrkt_trdng_rqst_date: parseDate(security.mrkt_trdng_rqst_date),
      mrkt_trdng_start_date: parseDate(security.mrkt_trdng_start_date),
    };
  },
  columns() {
    return [
      actionCell,
      { accessor: 'issuername', className: 'co-table-text co-table-text-l', header: 'Name' },
      { accessor: 'gnr_notional_curr_code', className: 'co-centered', header: 'Currency' },
      numberCell('bnd_nmnl_value_total', 'Nom. value total'),
      { accessor: 'rate', className: 'co-table-rate', header: 'Rate' },
      dateCell('bnd_maturity_date', 'Maturity'),
      dateCell('mrkt_trdng_rqst_date', 'Mkt Rqst Date'),
      dateCell('mrkt_trdng_start_date', 'Mkt Start Date'),
      { accessor: 'gnr_full_name', className: 'co-table-text co-table-text-l', header: 'Full name' },
      { accessor: 'gnr_short_name', className: 'co-table-text co-table-text-l', header: 'Short name' },
      { accessor: 'bnd_seniority', className: 'co-centered', header: 'Seniority' },
      numberCell('bnd_nmnl_value_unit', 'Nom. value unit'),
      { accessor: 'time_lastUpdate', header: 'Last Update' },
      { accessor: 'isin', header: 'ISIN' },
      { accessor: 'cfi.category', header: 'Category' },
      { accessor: 'cfi.group', className: 'co-table-text co-table-text-l', header: 'Group' },
      { accessor: 'cfi.attribute1', header: 'Attribute 1' },
      { accessor: 'cfi.attribute2', header: 'Attribute 2' },
      { accessor: 'cfi.attribute3', header: 'Attribute 3' },
      { accessor: 'cfi.attribute4', header: 'Attribute 4' },
      { accessor: 'tags', header: 'Tags' },
    ];
  },
  companyColumns: [
    actionCell,
    { accessor: 'lei', className: 'co-table-lei', header: 'LEI' },
    { accessor: 'entity.legalName.name', className: 'co-table-text co-table-text-l', header: 'Name' },
  ],
  companyMatcher: (security) => (c) => c.company.lei === security.lei,
  companySortField: 'entity.legalName.name',
  fields: {
    amount: 'bnd_nmnl_value_total', cfi: 'gnr_cfi_code', currency: 'gnr_notional_curr_code', issuername: 'issuername',
  },
  getCurrency: (search) => {
    const textCriteria = search.query.textCriteria.find((t) => t.name === 'gnr_notional_curr_code');
    return textCriteria ? textCriteria.values[0] : '-';
  },
  getCreationDate: (search) => {
    const textCriteria = search.query.textCriteria.find((t) => t.name === 'mrkt_trdng_rqst_date');
    return textCriteria ? parseDateArray(textCriteria.values).toString() : '-';
  },
  getMaturity: (search) => {
    const textCriteria = search.query.textCriteria.find((t) => t.name === 'bnd_maturity_date');
    return textCriteria ? parseDateArray(textCriteria.values).toString() : '-';
  },
  markupMap(companies) {
    return (s) => {
      let rate = '';
      if (s.bnd_fixed_rate) {
        rate = s.bnd_fixed_rate;
      } else if (s.bnd_fltng_rt_ndx_name === 'FORMULA') {
        rate = 'FORMULA';
      } else if (s.bnd_fltng_rt_ndx_trm_value) {
        rate = s.bnd_fltng_rt_ndx_trm_value;
        rate += `${s.bnd_fltng_rt_ndx_trm_unit.substring(0, 1)} `;

        if (s.bnd_fltng_rt_ndx_name) rate += `${s.bnd_fltng_rt_ndx_name} `;
        if (s.bnd_fltng_rt_ndx_code) rate += `${s.bnd_fltng_rt_ndx_code} `;
        if (Number.parseFloat(s.bnd_fltng_rate_bs_pnt_sprd) >= 0) rate += '+';
        rate += `${s.bnd_fltng_rate_bs_pnt_sprd} bps`;
      }

      const newSecurity = { ...s, rate };
      if (companies.length) {
        const company = companies.find((c) => c.company.lei === s.lei);
        //      console.log('Found company: %o', company);
        if (company) {
          newSecurity.issuername = company.company.entity.legalName.name;
        }
      }
      return newSecurity;
    };
  },
  properties: [
    { label: 'Issuer', field: 'issuername', width: 36 },
    { label: 'LEI', field: 'lei', width: 36 },
    { label: 'ISIN', field: 'isin', width: 16 },
    { label: 'Currency', field: 'gnr_notional_curr_code', width: 6 },
    {
      label: 'Nominal Value Total', field: 'bnd_nmnl_value_total', width: 16, type: 'number',
    },
    {
      label: 'Maturity Date', field: 'bnd_maturity_date', width: 14, type: 'date',
    },
    { label: 'Fixed Rate', field: 'bnd_fixed_rate', width: 14 },
    {
      label: 'Nominal Value Unit', field: 'bnd_nmnl_value_unit', width: 14, type: 'number',
    },
    { label: 'Seniority', field: 'bnd_seniority', width: 14 },
    { label: 'MIC', field: 'mic' },
    { label: 'Category', field: 'cfi.category' },
    { label: 'Group', field: 'cfi.group', width: 36 },
    { label: 'Attribute1', field: 'cfi.attribute1', width: 20 },
    { label: 'Attribute2', field: 'cfi.attribute2', width: 20 },
    { label: 'Attribute3', field: 'cfi.attribute3', width: 20 },
    { label: 'Attribute4', field: 'cfi.attribute4', width: 20 },
  ],
};

export default esma;
