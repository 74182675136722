import PropTypes from 'prop-types';
import React from 'react';

import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';

import FilterItemObject from '../../models/FilterItem';
import FilterItem from './FilterItem';

import './FilterItem.scss';

const RemoteFilterItem = ({
  disabled, label, name, onChange, path, value,
}) => {
  const { download } = React.useContext(DownloadContext);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (path !== null) {
      download.options(path, `Error downloading ${name} filter`, setOptions);
    }
  }, []);

  return (
    <FilterItem
      disabled={disabled}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};

RemoteFilterItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(ServerRequest)]),
  value: PropTypes.instanceOf(FilterItemObject),
};

RemoteFilterItem.defaultProps = {
  disabled: false,
  path: null,
  value: new FilterItemObject({ exclude: true }),
};

export default RemoteFilterItem;
