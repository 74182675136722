import React from 'react';

import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';

import AppViewContext from 'contexts/AppViewContext';

import ImageCard from '../../components/ImageCard';
import './main.css';

import { localIconUrl as cedarIconUrl } from '../../../cedar/utils/iconUrls';
import { localIconUrl as nitIconUrl } from '../../../nit/utils/iconUrls';

const Main = () => {
  const { setAppView, authorities } = React.useContext(AppViewContext);

  const click = (destination) => () => new Promise(() => setAppView(destination));

  return (
    <div id="main-page">
      <div id="image-grid">
        {'CEDAR' in authorities
          ? <ImageCard id="cedar" onClick={click('CEDAR')} text="CEDAR"><img className="image-icon" src={cedarIconUrl(240)} alt="nana" /></ImageCard> : ''}
        {'NIT' in authorities
          ? <ImageCard id="nit" onClick={click('NIT')} text="New Issue Tracker"><img className="image-icon" src={nitIconUrl(240)} alt="nana" /></ImageCard> : ''}
        {'MTN' in authorities
          ? <ImageCard id="mtn" onClick={click('MTN')} text="MTN App"><img className="image-icon" src={nitIconUrl(240)} alt="nana" /></ImageCard> : ''}
        {'SYND' in authorities
          ? <ImageCard id="synd" onClick={click('SYND')} text="Syndicate App"><img className="image-icon" src={nitIconUrl(240)} alt="nana" /></ImageCard> : ''}
        {'ADMIN' in authorities
          ? <ImageCard id="admin" onClick={click('ADMIN')} text="Admin"><Icon className="main-settings-icon" iconName="settings" /></ImageCard> : ''}
      </div>
    </div>
  );
};

export default Main;
