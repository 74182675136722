import React from 'react';

import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import ServerRequest from 'models/ServerRequest';
import CrudService from 'services/CrudService';

import UnknownPage from 'components/unknown-page/UnknownPage';
import Order from '../../model/Order';
import OrderForm from './OrderForm';
import OrderTable from './OrderTable';

const Orders = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [order, setOrder] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  const service = new CrudService({ download, name: 'Order', path: '/synd/orders' });

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/synd/orders');
      const body = await download.json(request, 'Error getting orders from server.');
      setLoading(false);
      if (body !== null) {
        setOrders(body.map((d) => new Order(d)));
      } else {
        setError(true);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setOrder(new Order());
      await setView('FORM');
    },
    delete: async (item) => {
      const newIssueData = item.row.original.data;
      const body = await service.delete(newIssueData);
      if (body) {
        const filteredOrders = orders.filter((n) => n.id !== newIssueData.id);
        setOrders(filteredOrders);
      }
    },
    edit: async (item) => {
      const rowData = item.row.original.data;
      setOrder(rowData);
      await setView('FORM');
    },
    save: (data) => async () => {
      const body = await (data.id ? service.update(data) : service.create(data));
      if (body !== null) {
        const updatedOrder = new Order(body);
        const updatedOrders = CrudService.updateData(orders, updatedOrder);
        setOrders(updatedOrders);
        await setView('TABLE');
      }
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <OrderForm
          order={order}
          save={handle.save}
        />
      );
    case 'TABLE':
      return (
        <OrderTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          orders={orders}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Orders;
