import PropTypes from 'prop-types';
import React from 'react';

import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const Metadata = ({ hideModal, value }) => {
  const onClose = () => {
    hideModal();
  };
  return (
    <Modal className="co-items" closeButton onClose={onClose} parentNode={document.body} show>
      <div>
        <Typography type="h1">Metadata</Typography>
      </div>
      <div>
        <Typography type="small">Id</Typography>
        <Typography variant="bold">{value.id}</Typography>
      </div>
      <div>
        <Typography type="small">Created By</Typography>
        <Typography variant="bold">{value.createdBy.displayName}</Typography>
        <Typography variant="bold">{value.createdBy.company}</Typography>
      </div>
      <div>
        <Typography type="small">Created</Typography>
        <Typography variant="bold">{new Date(value.createdDate).toLocaleString()}</Typography>
      </div>
      {value.lastModifiedBy ? (
        <div>
          <Typography type="small">Last Modified By</Typography>
          <Typography variant="bold">{value.lastModifiedBy.displayName}</Typography>
          <Typography variant="bold">{value.lastModifiedBy.company}</Typography>
        </div>
      ) : ''}
      {value.lastModifiedDate ? (
        <div>
          <Typography type="small">Last Modified</Typography>
          <Typography variant="bold">{new Date(value.lastModifiedDate).toLocaleString()}</Typography>
        </div>
      ) : ''}
    </Modal>
  );
};

Metadata.propTypes = {
  hideModal: PropTypes.func.isRequired,
  value: PropTypes.shape({
    createdBy: PropTypes.shape({
      displayName: PropTypes.string,
      company: PropTypes.string,
    }),
    createdDate: PropTypes.string,
    id: PropTypes.string,
    lastModifiedBy: PropTypes.shape({
      displayName: PropTypes.string,
      company: PropTypes.string,
    }),
    lastModifiedDate: PropTypes.string,
  }).isRequired,
};

export default Metadata;
