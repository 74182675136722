/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import {
  useFilters, useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';

import { matchSorter } from 'match-sorter';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import Loader from '@symphony-ui/uitoolkit-components/components/loader/Loader';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import ActionsMenu from './ActionsMenu';
import DownArrow from './DownArrow';
import UpArrow from './UpArrow';

import './CustomTable.scss';
import GlobalFilter from './filters/GlobalFilter';
import DefaultColumnFilter from './filters/DefaultColumnFilter';

const CustomTable = ({
  actionsMenu, data, columns, emptyMessage, errorMessage, hasError, isLoading,
}) => {
  const [show, setShow] = React.useState(false);
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [actionRow, setActionRow] = React.useState(null);
  const getArrow = (test) => (test ? (<UpArrow />) : (<DownArrow />));

  const showMenu = (row) => (event) => {
    console.log('SHOWING MENU %o row o% show: %o', event, row, show);
    setX(event.clientX - 15);
    setY(event.clientY - 30);
    setShow(!show);
    setActionRow(row);
  };

  React.useEffect(() => {
    console.log('IN TABLE SHOW IS: %o', show);
  }, [show]);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const fuzzyTextFilterFn = (rows, id, filterValue) => matchSorter(rows,
    filterValue,
    { keys: [(row) => row.values[id]] });

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns, data, defaultColumn, filterTypes,
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination);

  React.useEffect(() => {
    document.querySelectorAll('.co-table-text').forEach((e) => {
      if (e.scrollWidth > e.offsetWidth) {
        e.title = e.textContent;
      }
    });
  }, [data]);

  const abc = (d) => {
    console.log(d);
    setShow(d);
  };

  return (
    <div className="co-table-container" style={{ maxHeight: '600px' }}>
      <ActionsMenu
        actionsMenu={actionsMenu}
        row={actionRow}
        setShow={abc}
        show={show}
        x={x}
        y={y}
      />

      <table {...getTableProps()} className="co-table tk-text-ellipsis">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps({ className: 'co-header-row' })}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`${column.className} co-header`}
                >
                  <div className="co-header-wrapper">

                    <LabelTooltipDecorator label={column.header || ''} tooltip={column.tooltip} />

                    {/* Add a sort direction indicator */}
                    <span className="co-header-arrow">
                      {column.isSorted
                        ? getArrow(column.isSortedDesc)
                        : ''}
                    </span>
                  </div>
                  {/* Add a filter element */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        {!isLoading && rows.length
          ? (
            <tbody {...getTableBodyProps()}>

              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="co-row">

                    {row.cells.map((cell) => {
                      const a = cell.column.id;
                      return (
                        <td {...cell.getCellProps([{ className: cell.column.className }])}>
                          { a === 'actions'
                            ? (
                              <Button className="co-table-button" iconButton onClick={showMenu(cell.row)} variant="tertiary">
                                <Icon className="co-table-icon" iconName="more" />
                              </Button>
                            )
                            : cell.render('Cell')}
                        </td>

                      );
                    })}

                  </tr>
                );
              })}
            </tbody>
          )
          : (
            <tbody>
              <tr><td colSpan="10000" className="co-table-loader">{isLoading ? <Loader /> : <Typography type="h1">{hasError ? errorMessage : emptyMessage}</Typography>}</td></tr>
            </tbody>
          )}
      </table>

    </div>
  );
};

CustomTable.propTypes = {
  actionsMenu: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.shape({})), PropTypes.func],
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,

};

CustomTable.defaultProps = {
  actionsMenu: [],
  isLoading: false,
  emptyMessage: 'No entries yet',
  errorMessage: 'Unable to connect to server. Please check connection.',
  hasError: false,
};

export default CustomTable;
