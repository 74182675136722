import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import NewIssue from '../../model/NewIssue';

const NewIssueTable = ({
  handle, hasError, isLoading, issuerOptions, newIssues, states,
}) => {
  const actionsMenu = ({ original: { data: newIssue } }) => {
    const stateIndex = states.findIndex((s) => s.value === newIssue.state);

    const menu = [];
    if (stateIndex + 1 < states.length) {
      const newLabel = stateIndex > -1 ? `Change to ${states[stateIndex + 1].label}` : '?';
      menu.push({ label: newLabel, callback: handle.nextStateMenu, type: 'info' });
    }
    menu.push({ label: 'Manage', callback: handle.manage, type: 'info' });
    menu.push({ label: 'Delete', callback: handle.delete, type: 'error' });
    return menu;
  };

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'issuerId',
      Cell: (cellProps) => {
        const issuerOption = issuerOptions.find((t) => cellProps.cell.value === t.value);
        return (
          <div className="cell-wrapper">
            <div className="custom-cell" type="flat">
              {issuerOption ? issuerOption.label : ''}
            </div>
          </div>
        );
      },
      header: 'Issue',
    },
    { accessor: 'name', header: 'Name' },
    { accessor: 'stateText', header: 'State' },
  ]);

  const mapNewIssueToRow = (newIssue) => {
    const stateIndex = states.findIndex((s) => s.value === newIssue.state);

    return {
      data: newIssue,
      stateText: stateIndex > -1 ? states[stateIndex].label : '',
      ...newIssue,
    };
  };

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="New Issues" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={newIssues.map(mapNewIssueToRow)}
        emptyMessage="You have no new issues yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

NewIssueTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    manage: PropTypes.func,
    nextStateMenu: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  issuerOptions: PropTypes.arrayOf(PropTypes.shape({})),
  newIssues: PropTypes.arrayOf(PropTypes.instanceOf(NewIssue)),
  states: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
};

NewIssueTable.defaultProps = {
  hasError: false,
  isLoading: false,
  issuerOptions: [],
  newIssues: [],
  states: [],
};

export default NewIssueTable;
