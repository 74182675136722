import React from 'react';
import PropTypes from 'prop-types';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './MyCard.scss';

const MyCard = ({ header, icon, text }) => (
  <Card className="my-card">
    <div className="card-box">
      {icon}
      <div className="card-title">
        <Typography type="h1">{header}</Typography>
      </div>
    </div>
    <div className="card-text">
      <Typography>{text}</Typography>
    </div>
  </Card>
);

MyCard.propTypes = {
  header: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default MyCard;
