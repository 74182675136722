import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';

import AppViewContext from 'contexts/AppViewContext';
import ServerRequest from 'models/ServerRequest';
import ChangeTheme from 'pages/settings/ChangeTheme';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

const Settings = ({ children }) => {
  const { setAppView } = React.useContext(AppViewContext);

  const click = {
    logout: async () => {
      const request = new ServerRequest('/logout', { method: 'POST' });
      await fetch(request);
      try {
        localStorage.removeItem('authorities');
      } catch (error) {
        // ignore in case no access to localStorage
      }
      window.location = window.location.href;
    },
    menu: () => new Promise(() => setAppView('MAIN')),
  };

  return (
    <div>
      {window.jwt ? '' : <ChangeTheme /> }
      {children}
      <Card>
        <div className="form-buttons">
          <Button onClick={click.logout}>Log out</Button>
          <Button onClick={click.menu}>Apps Menu</Button>
        </div>
      </Card>
    </div>
  );
};

Settings.propTypes = {
  children: PropTypes.node,
};

Settings.defaultProps = {
  children: (''),
};
export default Settings;
