import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';

import TagInput from 'components/tag-input/TagInput';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import ServerRequest from 'models/ServerRequest';

const CompanyTagsModal = ({
  companyTags, hideModal, updateTags, tagOptions,
}) => {
  const [data, setData] = React.useState(companyTags);
  const [loading, setLoading] = React.useState(false);

  const onChange = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const onClick = {
    save: async () => {
      setLoading(true);

      const request = new ServerRequest('/nit/companies/tags', {
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
        method: (data.id ? 'PATCH' : 'POST'),
      });

      await fetch(request);
      setLoading(false);

      updateTags(data);
      hideModal();
    },
  };

  return (
    <Modal className="co-items" show closeButton onClose={hideModal} parentNode={document.body}>
      <div>
        <Typography type="h1">Tag Company</Typography>
      </div>
      <div>
        <TagInput
          name="tags"
          onChange={onChange.input}
          tagOptions={tagOptions}
          tags={data.tags || []}
        />
      </div>
      <div className="co-form-buttons">
        <Button loading={loading} onClick={onClick.save}>Save</Button>
      </div>
    </Modal>
  );
};

CompanyTagsModal.propTypes = {

  companyTags: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.string),
  updateTags: PropTypes.func.isRequired,
};

CompanyTagsModal.defaultProps = {
  tagOptions: [],
};

export default CompanyTagsModal;
