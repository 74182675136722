import { defaultPermissions } from 'components/metadata/utils';

class Order {
  constructor(order) {
    Object.assign(this, order);

    if (!this.permissions) this.permissions = defaultPermissions;

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }
}

export default Order;
