import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import { getOptions, validationChanged } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import TrancheObject from '../../model/Tranche';

import './Tranche.css';

const Tranche = ({
  benchmarkOptions, currencyOptions, disabled, top, tranche, setTranche,
}) => {
  const [errorState, setErrorState] = React.useState({});

  const change = {
    dropdown: (name) => (event) => {
      setTranche({ ...tranche, [name]: event.value });
    },
    input(event) {
      const { name, value } = event.target;
      const newTranche = new TrancheObject({ ...tranche, [name]: value });
      setTranche(newTranche);
    },
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  const validators = {
    currency: CustomValidators.RequiredOption,
    term: Validators.Required,
    maturity: Validators.Required,
    spreadGuidance: Validators.Required,
    benchmarkId: CustomValidators.RequiredOption,
  };

  return (
    <div className="co-item">
      <div style={{ minWidth: '90px' }}>
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('currency')} validator={validators.currency}>
          <Dropdown isDisabled={disabled} label={top ? 'Currency' : ''} name="currency" onChange={change.dropdown('currency')} options={currencyOptions} value={getOptions(tranche.currency || [], currencyOptions)} />
        </Validation>
      </div>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('term')} validator={validators.term}>
        <TextField disabled={disabled} label={top ? 'Term' : ''} name="term" onChange={change.input} value={tranche.term || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('maturity')} validator={validators.maturity}>
        <TextField disabled={disabled} label={top ? 'Maturity' : ''} name="maturity" onChange={change.input} value={tranche.maturity || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('spreadGuidance')} validator={validators.spreadGuidance}>
        <TextField disabled={disabled} label={top ? 'Spread Guidance' : ''} name="spreadGuidance" onChange={change.input} value={tranche.spreadGuidance || ''} />
      </Validation>

      <div style={{ minWidth: '200px' }}>
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('benchmarkId')} validator={validators.benchmarkId}>
          <Dropdown isDisabled={disabled} label={top ? 'Benchmark' : ''} name="benchmarkId" onChange={change.dropdown('benchmarkId')} options={benchmarkOptions} value={getOptions(tranche.benchmarkId || [], benchmarkOptions)} />
        </Validation>
      </div>
    </div>
  );
};

Tranche.propTypes = {
  benchmarkOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  top: PropTypes.bool.isRequired,
  setTranche: PropTypes.func.isRequired,
  tranche: PropTypes.instanceOf(TrancheObject).isRequired,
};

Tranche.defaultProps = {
  disabled: false,
};

export default Tranche;
