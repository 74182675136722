import PropTypes from 'prop-types';
import React from 'react';

import FileButton from './FileButton';
import FileInfo from '../../models/FileInfo';

const TableLinks = ({ cell }) => {
  const buttons = cell.value.map((fileInfo, index) => {
    const key = `key_${index}`;
    return (<FileButton fileInfo={fileInfo} key={key} />);
  });

  return (
    <div className="link-wrapper" type="flat">
      {buttons}
    </div>
  );
};

TableLinks.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.instanceOf(FileInfo)).isRequired,
  }).isRequired,
};

export default TableLinks;
