import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './CardHeader.css';

const CardHeaderTable = ({ create, disabled, title }) => (
  <div className="card-header">
    <Typography type="h1">
      {title}
    </Typography>
    <Button disabled={disabled} onClick={create}>Create New</Button>
  </div>
);

CardHeaderTable.propTypes = {
  create: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CardHeaderTable.defaultProps = {
  disabled: false,
};

export default CardHeaderTable;
