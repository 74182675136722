import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Currency from '../../models/Currency';

import CurrencyTable from './CurrencyTable';
import CurrencyForm from './CurrencyForm';

const Currencies = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [currencies, setCurrencies] = React.useState();
  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  const service = new CrudService({ download, name: 'Currency', path: '/core/currencies' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedCurrencies = body.map((u) => new Currency(u));
        setCurrencies(downloadedCurrencies);
      } else {
        setError(true);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      await setView('FORM');
    },
    delete: async ({ original: currency }) => {
      const body = await service.delete(currency);
      if (body) {
        const filteredCurrencies = currencies.filter((u) => u.id !== currency.id);
        setCurrencies(filteredCurrencies);
      }
    },
    edit: async ({ original: currency }) => {
      setDetails(currency);
      await setView('FORM');
    },
    save: async (data) => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedCurrency = new Currency(body);
        const updatedCurrencies = CrudService.updateData(currencies, updatedCurrency);
        setCurrencies(updatedCurrencies);
        await setView('TABLE');
      }
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <CurrencyForm
          currency={details}
          save={handle.save}
        />
      );
    case 'TABLE':
      return (
        <CurrencyTable
          currencies={currencies}
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Currencies;
