import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import { validationChanged } from 'utils/change';
import { checkDisabled } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';

const TokenForm = ({ setTitle, setToken, switchForm }) => {
  const [data, setData] = React.useState({});
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    token: CustomValidators.Token,
  };

  const click = {
    confirm() {
      setToken(data.token);
      switchForm('PASSWORD', { text: 'Please choose your new password.', type: 'info' });
    },
  };

  const change = {
    input(event) {
      const { name, value } = event.target;

      const regex = /^\d{0,6}$/u;
      if (regex.test(value)) {
        setData({ ...data, [name]: value });
      }
    },
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    setTitle('Enter check your email for the token.');
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div>
      <div className="co-item">
        <Validation errorMessage={{ token: 'Incorrect token format' }} onValidationChanged={onValidationChanged('token')} validator={validators.token}>
          <TextField label="Token" name="token" onChange={change.token} value={data.token} />
        </Validation>
      </div>

      <div className="co-form-buttons co-item">
        <Button disabled={disabledButton} onClick={click.confirm}>Reset Password</Button>
      </div>
    </div>
  );
};

TokenForm.propTypes = {
  setTitle: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  switchForm: PropTypes.func.isRequired,
};

export default TokenForm;
