export const checkEditable = (object, user) => !('createdBy' in object)
    || (object.permissions.other.write
    || (user !== null
      && object.permissions.group.write && object.createdBy.company === user.company)
    || (user !== null
      && object.permissions.user.write && object.createdBy.id === user.id));

export const defaultPermissions = {
  user: { read: true, write: true },
  group: { read: true, write: false },
  other: { read: false, write: false },
};
