import Draft from './Draft';

class Workflow {
  constructor(data) {
    Object.assign(this, data);
    this.drafts = this.drafts.map((draft) => new Draft(draft));
  }

  latestDraft() {
    return this.drafts[this.drafts.length - 1];
  }

  updateComment(comment) {
    const foundDraft = this.drafts
      .find((d) => (d.comments.map((c) => c.id)).includes(comment.id));

    const updatedDraft = foundDraft.updateComment(comment);
    const draftIndex = this.drafts.indexOf(foundDraft);

    const newdrafts = [...this.drafts];
    newdrafts[draftIndex] = updatedDraft;

    return new Workflow({ ...this, drafts: newdrafts });
  }
}

export default Workflow;
