import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import { checkEditable } from 'components/metadata/utils';
import AppViewContext from 'contexts/AppViewContext';
import { errorMessage } from 'utils/initialValidation';
import { getOptions } from 'utils/change';

import Search from '../../../models/Search';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import FilterItem from '../../../models/FilterItem';

const SecForm = ({ search, setSearch }) => {
  const { user } = React.useContext(AppViewContext);

  const [disabled, setDisabled] = React.useState(false);

  const validators = {
    CIK: null,
    company: null,
    dateb: null,
    owner: null,
    type: null,
  };

  React.useEffect(() => {
    if (search.id) {
      const isEditable = checkEditable(search, user);
      setDisabled(!isEditable);
    }
  }, []);

  const change = {
    filter: (event) => {
      const { name, value } = event.target;
      const updatedSearch = search.updateFilter(name, value);
      setSearch(updatedSearch);
    },
    query: (event) => {
      const { name, value } = event.target;
      const updatedSearch = search.updateQuery(name, value);
      setSearch(updatedSearch);
    },
    querySelect: (name) => (event) => {
      const { value } = event;
      const updatedSearch = search.updateQuery(name, value);
      setSearch(updatedSearch);
    },
  };

  const options = [{ label: 'Include', value: 'include' }, { label: 'Exclude', value: 'exclude' }, { label: 'Only', value: 'only' }];

  const onValidationChanged = (name) => (error) => {
    console.log('Validation changed');
  };

  return (
    <div className="base-form">
      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('CIK')} validator={validators.CIK}>
        <TextField disabled={disabled} label="CIK" name="CIK" value={search.query.CIK || ''} onChange={change.query} tooltip="The CIK code, which is the SEC's classification for legal entities, similar to the global LEI codes." />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('company')} validator={validators.company}>
        <TextField disabled={disabled} label="Company" name="company" onChange={change.query} value={search.query.company || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('dateb')} validator={validators.dateb}>
        <TextField disabled={disabled} label="DateB" name="dateb" onChange={change.query} value={search.query.dateb || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('owner')} validator={validators.owner}>
        <Dropdown isDisabled={disabled} label="Owner" name="owner" onChange={change.querySelect('owner')} options={options} tooltip="Whether to include changes in ownership by insiders in the results. This will have no impact if you choose a Type." value={getOptions(search.query.owner, options)} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('type')} validator={validators.type}>
        <TextField disabled={disabled} label="Type" name="type" onChange={change.query} tooltip="Filing type, such as 424B2, 144 or 8K" value={search.query.type || ''} />
      </Validation>

      <RemoteFilterItem
        disabled={disabled}
        label="Tags"
        name="tags"
        onChange={change.filter}
        path="/nit/companies/tags/options/sec"
        value={search.filter.tags || new FilterItem()}
      />
    </div>
  );
};

SecForm.propTypes = {
  search: PropTypes.instanceOf(Search),
  setSearch: PropTypes.func.isRequired,
};

SecForm.defaultProps = {
  search: Search.init(),
};

export default SecForm;
