import { passwordTest } from './passwords';

const validate = (target, state) => {
  const {
    dataset, files, type, value,
  } = target;

  let newValue = type === 'file' ? files[0] : value;
  const {
    email, required, maxlength, min, max,
  } = dataset;

  if (type === 'text' && maxlength && newValue.length > maxlength) {
    return false;
  }

  const isEmpty = required && value.length === 0;
  const isInsufficient = type === 'password' && !passwordTest(value);
  const isEmail = email && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
  const isPast = type === 'datetime-local' && min === 'now' && value.substring(0, 1) !== '0' && new Date(value).getTime() < new Date().getTime();

  if (type === 'password' && state === 'initial') {
    newValue = '';
  }

  let text = '';
  if (isEmpty) text = 'Cannot be empty';
  if (isInsufficient) text = 'Invalid password';
  if (isEmail) text = 'Invalid email address';
  if (isPast) text = 'Cannot be in the past';
  return { inputState: isEmail || isPast || isEmpty || isInsufficient ? 'error' : 'modified', errorMessage: text, newValue };
};

export default validate;
