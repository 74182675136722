import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import { checkEditable } from 'components/metadata/utils';
import AppViewContext from 'contexts/AppViewContext';
import { getOptionsMulti } from 'utils/change';
import Search from '../../../models/Search';
import QualifiedSearch from '../../qualified-search/QualifiedSearch';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import { searchChange } from '../searchUtils';

import Option from '../../../models/Option';

const EuroclearForm = ({ search, setSearch }) => {
  const { user } = React.useContext(AppViewContext);

  const [disabled, setDisabled] = React.useState(false);
  const [currencyOptions, setCurrencyOptions] = React.useState([]);
  const [marketOptions, setMarketOptions] = React.useState([]);
  const [ratetypeOptions, setRatetypeOptions] = React.useState([]);
  const [securitytypeOptions, setSecuritytypeOptions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const loadedCurrencyOptions = await Option.load('/assets/nit/banksecurities.currency.json');
      setCurrencyOptions(loadedCurrencyOptions);
    })();
    (async () => {
      const loadedMarketOptions = await Option.load('/assets/nit/banksecurities.market.json');
      setMarketOptions(loadedMarketOptions);
    })();

    (async () => {
      const loadedRatetypeOptions = await Option.load('/assets/nit/banksecurities.ratetype.json');
      setRatetypeOptions(loadedRatetypeOptions);
    })();
    (async () => {
      const loadedSecuritytypeOptions = await Option.load('/assets/nit/banksecurities.securitytype.json');
      setSecuritytypeOptions(loadedSecuritytypeOptions);
    })();

    if (search.id) {
      const isEditable = checkEditable(search, user);
      setDisabled(!isEditable);
    }
  }, []);

  const change = searchChange(search, setSearch);

  const regex = {
    q: new RegExp(''),
    rate: new RegExp('^((0(\\.[0-9]{1,5})?)|[1-9][0-9]+(\\.[0-9]{1,5})?)$'),
  };

  return (
    <div className="base-form">
      <QualifiedSearch
        defaultErrorMessage="This doesn't work"
        disabled={disabled}
        name="q"
        label="Shortname"
        value={search.query.q}
        onChange={change.queryInput}
        regex={regex.q}
        tooltip="Search the shortname of the security. This typically starts with the legal name of the issuer. An example is 'NRW.BANK            0.20000 01/03/34'"
      />
      <Dropdown
        isDisabled={disabled}
        isMultiSelect
        label="Currency"
        name="currency"
        onChange={change.query('currency')}
        options={currencyOptions}
        value={getOptionsMulti(search.query.currency, currencyOptions)}
      />
      <QualifiedSearch
        defaultErrorMessage="This really doesn't work"
        disabled={disabled}
        name="rate"
        label="Rate"
        value={search.query.rate}
        onChange={change.queryInput}
        regex={regex.rate}
        tooltip="Search by rate. The search is by text"
      />
      <Dropdown
        isDisabled={disabled}
        isMultiSelect
        label="Market"
        name="market"
        onChange={change.query('market')}
        options={marketOptions}
        value={getOptionsMulti(search.query.market, marketOptions)}
      />
      <Dropdown
        isDisabled={disabled}
        isMultiSelect
        label="Rate Type"
        name="ratetype"
        onChange={change.query('ratetype')}
        options={ratetypeOptions}
        value={getOptionsMulti(search.query.ratetype, ratetypeOptions)}
      />
      <Dropdown
        isDisabled={disabled}
        isMultiSelect
        label="Security Type"
        name="securitytype"
        onChange={change.query('securitytype')}
        options={securitytypeOptions}
        value={getOptionsMulti(search.query.securitytype, securitytypeOptions)}
      />
      <RemoteFilterItem
        disabled={disabled}
        label="Tags"
        name="tags"
        onChange={change.filter}
        path="/nit/companies/tags/options/euroclear"
        value={search.filter.tags}
      />
      <RemoteFilterItem
        disabled={disabled}
        label="Countries"
        name="countries"
        onChange={change.filter}
        path="/nit/companies/euroclear/countries/options"
        value={search.filter.countries}
      />
      <RemoteFilterItem
        disabled={disabled}
        label="Categories"
        name="categories"
        onChange={change.filter}
        path="/nit/companies/euroclear/categories/options"
        value={search.filter.categories}
      />
      <RemoteFilterItem
        disabled={disabled}
        label="CFI Groups"
        name="cfiGroups"
        onChange={change.filter}
        path="/nit/security/cfi-groups/options"
        value={search.filter.cfiGroups}
      />
      <TimeRangeInput
        defaultPeriod="Y"
        disabled={disabled}
        label="Maturity Date"
        name="maturitydate"
        onChange={change.queryInput}
        value={search.query.maturitydate}
      />
      <TimeRangeInput
        defaultPeriod="D"
        disabled={disabled}
        label="Code Creation Date"
        name="codecreationdate"
        onChange={change.queryInput}
        value={search.query.codecreationdate}
      />
    </div>
  );
};

EuroclearForm.propTypes = {
  search: PropTypes.instanceOf(Search),
  setSearch: PropTypes.func.isRequired,
};

EuroclearForm.defaultProps = {
  search: Search.init(),
};

export default EuroclearForm;
