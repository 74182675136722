import PropTypes from 'prop-types';
import React from 'react';

import Link from '@symphony-ui/uitoolkit-components/components/link/Link';

const TableLink = ({ cell: { value } }) => (
  <Link href={`https://sec.gov/${value.href}`} url={`https://sec.gov/${value.href}`}>{value.description}</Link>
);

TableLink.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
};

export default TableLink;
