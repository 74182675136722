import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import { getOptions } from 'utils/change';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import './Companies.css';
import CustomTable from 'components/custom-table/CustomTable';
import sources from '../sources/sources';
import AnnaCompanyModal from '../components/companies/AnnaCompanyModal';
import CompanyTagsModal from '../components/companies/CompanyTagsModal';

const Companies = () => {
  const { download } = React.useContext(DownloadContext);

  const [actualCompanyTags, setActualCompanyTags] = React.useState(null);
  const [allCompanyTags, setAllCompanyTags] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [maxHeight, setMaxHeight] = React.useState(window.innerHeight - 280);
  const [source, setSource] = React.useState(localStorage.getItem('nit-source') || 'EUROCLEAR');
  const [tagOptions, setTagOptions] = React.useState([]);

  const addressTest = (address) => address?.address1?.includes('��') || address?.address2?.includes('��') || address?.city?.includes('��') || address?.country?.includes('��') || address?.postalCode?.includes('��') || address?.state?.includes('��');

  const hideModal = () => {
    setActualCompanyTags(null);
    setCompany(null);
  };

  const updateCompany = (updatedCompany) => {
    const allCompanies = [...companies];
    const index = allCompanies.findIndex((c) => c.id === updatedCompany.id);
    allCompanies[index] = updatedCompany;

    setCompanies(allCompanies);
  };

  const updateTags = (newCompanyTags) => {
    const allCompanyTagsCopy = [...allCompanyTags];
    const index = allCompanyTagsCopy.findIndex((c) => c.id === newCompanyTags.id);

    if (index >= 0) {
      allCompanyTagsCopy[index] = newCompanyTags;
    } else {
      allCompanyTagsCopy.push(newCompanyTags);
    }

    setAllCompanyTags(allCompanyTagsCopy);
  };

  const actionClick = {
    companyTags({ original: { companyTags, id } }) {
      setActualCompanyTags(companyTags
        ? { ...companyTags, source } : { companyId: id, source });
    },
    details({ original: rowCompany }) {
      setCompany(rowCompany);
    },
  };

  const actionsMenu = ({ original: c }) => {
    const actions = [{
      callback: actionClick.companyTags,
      label: 'Edit Company Tags',
      type: 'info',
    }];

    if ((c.nameLong?.includes('��')) || (c.reg && addressTest(c.reg)) || (c.hq && addressTest(c.hq))) {
      actions.push({
        callback: actionClick.details,
        label: 'Correct Diacriticals',
        type: 'info',
      });
    }
    return actions;
  };

  const resultMap = (c) => ({
    ...c,
    companyTags: allCompanyTags.find((t) => t.companyId === c.id),
  });

  const changeSource = (event) => {
    const { value } = event.target;
    localStorage.setItem('nit-source', value.value);
    setSource(value.value);
  };

  const allOptions = [
    { value: 'EUROCLEAR', label: 'Euroclear' },
    { value: 'ESMA', label: 'ESMA' },
    { value: 'ANNA', label: 'ANNA' },
    { value: 'SEC', label: 'SEC' }];

  React.useEffect(() => {
    window.onresize = () => setMaxHeight(window.innerHeight - 320);

    const newColumns = [...sources[source].companyColumns];
    newColumns.push({
      accessor: ({ companyTags }) => (companyTags && Array.isArray(companyTags.tags) ? companyTags.tags.join(', ') : ''),
      header: 'Tags',
      id: 'companyTags',
    });

    setColumns(newColumns);

    (async () => {
      const request = new ServerRequest('/nit/companies/tags');
      const tags = await download.json(request, 'Error downloading company tags');

      if (tags !== null) {
        const loadedTagOptions = [...new Set(tags
          .filter((s) => s.source === source)
          .flatMap((t) => t.tags))];
        setTagOptions(loadedTagOptions);

        setAllCompanyTags(tags);
      }
    })();

    (async () => {
      setLoading(true);
      const request = new ServerRequest(`/nit/${source.toLowerCase()}/companies`);
      const body = await download.json(request, 'Error downloading companies');
      setLoading(false);
      if (body !== null) {
        const { companySortField } = sources[source];

        const newCompanies = body.sort((a, b) => {
          if (a[companySortField] > b[companySortField]) return 1;
          if (a[companySortField] < b[companySortField]) return -1;
          return 0;
        });

        setCompanies(newCompanies);
      } else {
        setError(true);
      }
    })();
  }, [source]);

  return (
    <Card key="NIT">
      {company ? <AnnaCompanyModal company={company} hideModal={hideModal} updateCompany={updateCompany} /> : ''}
      {actualCompanyTags ? (
        <CompanyTagsModal
          companyTags={actualCompanyTags}
          hideModal={hideModal}
          updateTags={updateTags}
          tagOptions={tagOptions}
        />
      ) : ''}
      <div className="co-container co-item">
        <Dropdown
          className="co-dropdown"
          isDisabled={hasError}
          isTypeAheadEnabled={false}
          label="Source"
          onChange={changeSource}
          options={allOptions}
          value={getOptions(source, allOptions)}
        />
      </div>
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={companies.map(resultMap)}
        emptyMessage="There are no companies in the database yet."
        errorMessage="Unable to download companies. Please check connection."
        hasError={hasError}
        isLoading={isLoading}
        searchable
        maxHeight={maxHeight}
        manualPagination
      />
    </Card>
  );
};

export default Companies;
