import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';

import MetadataButtons from 'components/metadata/MetadataButtons';
import ServerRequest from 'models/ServerRequest';

import DownloadContext from 'contexts/DownloadContext';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import Order from '../../model/Order';

const OrderForm = ({ order, save }) => {
  const { download } = React.useContext(DownloadContext);

  const [data, setData] = React.useState(order);
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});
  const [options, setOptions] = React.useState([]);

  const validators = {

  };

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
    permissions(event) {
      const updated = new Order({ ...order, event });
      setData(updated);
    },
    select: (event) => {
      const regex = new RegExp('^(?<newIssueId>[0-9a-f]{24})\\|(?<trancheId>.*)$');
      const { newIssueId, trancheId } = regex.exec(event.value).groups;
      setData({ ...data, newIssueId, trancheId });
    },
  };

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/synd/new-issues/options');
      const body = await download.json(request);
      console.log('BODY: %o%', body);
      if (body !== null) {
        setOptions(body);
      }
    })();
  }, []);

  return (
    <Card>
      <CardHeaderForm title={data.id ? 'Edit Order' : 'Create Order'} />
      <div className="base-form" id="order-form">
        <TextField label="Amount" maxLength="40" name="amount" onChange={change.input} value={data.amount} placeholder="Required" />
        <TextField label="investorName" maxLength="40" name="investorName" onChange={change.input} value={data.investorName} placeholder="Required" />

        <Dropdown label="New Issue" name="tranche" onChange={change.select} options={options} value={`${data.newIssueId}|${data.trancheId}`} />
      </div>

      <div className="co-form-buttons-plus co-item">
        <Button disabled={disabledButton} onClick={save(data)}>{data.id ? 'Edit Order' : 'Create Order'}</Button>
        <MetadataButtons data={data} onChange={change.permissions} disabled={false} />
      </div>
    </Card>
  );
};

OrderForm.propTypes = {
  order: PropTypes.instanceOf(Order),
  save: PropTypes.func.isRequired,
};

OrderForm.defaultProps = {
  order: new Order(),
};

export default OrderForm;
