import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import { checkEditable } from 'components/metadata/utils';
import AppViewContext from 'contexts/AppViewContext';

import CustomTable from 'components/custom-table/CustomTable';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import { actionCell } from 'utils/table';
import sources, { databaseOptions } from '../../sources/sources';
import Option from '../../models/Option';
import Search from '../../models/Search';

const SearchTable = ({ handle, isLoading, searches }) => {
  const { user } = React.useContext(AppViewContext);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const currencyOptions = await Option.load('/assets/nit/banksecurities.currency.json');
      setOptions(currencyOptions);
    })();
  }, []);

  const actionsMenu = ({ original: search }) => [{
    label: checkEditable(search, user) ? 'Open' : 'View',
    callback: handle.edit,
    type: 'info',
  },
  {
    label: 'Delete',
    callback: handle.delete,
    type: 'error',
  }];

  const columns = React.useMemo(() => [
    actionCell,
    { accessor: 'name', header: 'Name' },
    { accessor: (row) => sources[row.database].getCurrency(row, options), header: 'Currency', id: 'currency' },
    { accessor: (row) => sources[row.database].getCreationDate(row), header: 'Code Creation Date', id: 'codecreationdate' },
    { accessor: (row) => sources[row.database].getMaturity(row), header: 'Maturity', id: 'maturitydate' },
    { accessor: (row) => databaseOptions.find((o) => o.value === row.database).label, header: 'Database', id: 'database' },

  ]);

  return (
    <Card>
      <CardHeaderForm newView="FORM" title="Searches" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={searches}
        emptyMessage="You have no searches yet"
        loading={isLoading}
      />
    </Card>
  );
};

SearchTable.propTypes = {
  handle: PropTypes.shape({
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool,
  searches: PropTypes.arrayOf(PropTypes.instanceOf(Search)),
};

SearchTable.defaultProps = {
  isLoading: false,
  searches: [],
};

export default SearchTable;
