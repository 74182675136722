import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import {
  change, getOptions, getOptionsMulti, onMenuClose,
} from 'utils/change';

import FilterItemObject from '../../models/FilterItem';

import './FilterItem.scss';

const FilterItem = ({
  disabled, label, name, options, value, onChange,
}) => {
  const [data, setData] = React.useState({
    ...value,
    exclude: value.exclude === false ? 'false' : 'true',
  });

  const localChange = change(data, setData);

  const flagOptions = [{ label: 'Exclude', value: 'true' }, { label: 'Include', value: 'false' }];

  React.useEffect(() => {
    const newObject = {
      target: {
        name,
        value: new FilterItemObject(data),
      },
    };
    onChange(newObject);
  }, [data]);

  return (
    <div className="tk-input-group tag-input">
      <div className="tk-input-group__header">
        <label className="tk-label" htmlFor="tag-group">{label}</label>
      </div>

      <div className="tk-input__container" id="tag-group">
        <Dropdown
          className="first-one"
          isDisabled={disabled}
          isTypeAheadEnabled={false}
          name="exclude"
          onChange={localChange.dropdown('exclude')}
          onMenuClose={onMenuClose}
          options={flagOptions}
          value={getOptions(data.exclude, flagOptions)}
        />
        <Dropdown
          className="second-one"
          isDisabled={disabled}
          isMultiSelect
          name="values"
          onChange={localChange.dropdown('values')}
          options={options}
          value={getOptionsMulti(data.values, options)}
        />
      </div>
    </div>
  );
};

FilterItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.instanceOf(FilterItemObject),
};

FilterItem.defaultProps = {
  disabled: false,
  options: [],
  value: new FilterItemObject({ exclude: true }),
};

export default FilterItem;
