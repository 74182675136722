import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import ServerRequest from 'models/ServerRequest';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';

import BaseCurrency from '../components/settings/BaseCurrency';
import Credentials from '../components/settings/Credentials';

import './NitSettings.css';

const NITSettings = () => {
  const empty = { password: '', username: '' };
  const [data, setData] = React.useState({ ANNA: empty, EUROCLEAR: empty });

  const { authorities: { NIT } } = React.useContext(AppViewContext);

  const { download } = React.useContext(DownloadContext);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/nit/credentials');
      const body = await download.json(request);
      if (body !== null) {
        setData({
          ANNA: body.ANNA || empty,
          EUROCLEAR: body.EUROCLEAR || empty,
        });
      }
    })();
  }, []);

  return (
    <div>
      <BaseCurrency />

      <Card className="co-card-stack" key="NIT-credentials">
        <div className="co-item">
          <Typography type="h1">Credentials</Typography>
        </div>
        {NIT.includes('DEMO') ? (
          <div className="co-item">
            <Typography type="body">
              You have demo access. You can use your own credentials ,
              or if you do not save these your access will fall back to demo credentials.
            </Typography>
          </div>
        ) : ''}
        <div className="co-item">
          <Credentials initial={data.EUROCLEAR} source="EUROCLEAR" title="Euroclear" />
          <Credentials initial={data.ANNA} source="ANAN" title="ANNA" />
        </div>
      </Card>

    </div>
  );
};

export default NITSettings;
