import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import apiSource from 'utils/system/apiSource';

import CustomValidators from 'components/CustomValidators';
import { passwordRequirements } from 'utils/passwords';
import { checkDisabled, initialValidation } from 'utils/initialValidation';
import { validationChanged } from 'utils/change';

const PasswordForm = ({
  setMessage, setTitle, switchForm, token,
}) => {
  const [data, setData] = React.useState({ });
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});

  const errorMessage = {
    password: 'Please enter password along guidelines above.',
    passwordRepeat: 'Passwords do not match',
  };

  const repeatPasswordValidator = () => (data.password !== data.passwordRepeat
    ? Promise.resolve({ passwordRepeat: true }) : null);

  const validators = {
    password: CustomValidators.Password,
    passwordRepeat: repeatPasswordValidator,
  };

  const click = {
    reset: async () => {
      const url = apiSource('/password-reset');
      const formData = new FormData();
      formData.append('token', token);
      formData.append('password', data.password);
      const init = {
        body: formData,
        method: 'POST',
      };
      let message = null;
      try {
        const response = await fetch(url, init);

        if (response.status === 204) {
          message = { type: 'success', text: 'Your password has changed, please log in.' };
        } else {
          const body = await response.json();
          message = { text: body.message, type: 'error' };
        }
      } catch (error) {
        message = { text: `Error connecting to the Server: ${error.message}`, type: 'error' };
      } finally {
        setMessage(message);
        switchForm('LOGIN');
      }
    },
  };

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    setTitle('Enter new Password');
    initialValidation(validators, data);
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div>
      <div className="co-item">
        <Card>
          <Typography type="h2">
            Password requirements:
          </Typography>
          <ul>
            {passwordRequirements.map((t) => (<li>{t}</li>))}
          </ul>
        </Card>
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('password')} validator={validators.password}>
          <TextField isMasked autoComplete="new-password" name="password" value={data.password || ''} label="New Password" onChange={change.input} />
        </Validation>

        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('passwordRepeat')} validator={validators.passwordRepeat}>
          <TextField autoComplete="new-password" name="passwordRepeat" value={data.passwordRepeat || ''} label="Repeat Password" onChange={change.input} />
        </Validation>
      </div>
      <div className="co-form-buttons">
        <Button disabled={disabledButton} onClick={click.reset}>Reset Password</Button>
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  switchForm: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default PasswordForm;
