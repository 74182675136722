import { defaultPermissions } from 'components/metadata/utils';
import Tranche from './Tranche';

class NewIssue {
  constructor(newIssue) {
    Object.assign(this, newIssue);

    if (!this.permissions) this.permissions = defaultPermissions;

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }

  addTranche() {
    console.log('ADDING TRANCHE');
    if (!('tranches' in this)) {
      this.tranches = [];
    }
    const newTranche = new Tranche();
    newTranche.key = this.tranches.length + 1;
    const tranches = [...this.tranches, newTranche];
    console.log('NEW TRANCHES: %o', tranches);
    return new NewIssue({ ...this, tranches });
  }

  update(name, value) {
    return new NewIssue({ ...this, [name]: value });
  }
}

export default NewIssue;
