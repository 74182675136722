class Option {
  constructor(option) {
    Object.assign(this, option);
  }

  /**
   * Load options; this can be either a string or a ServerRequest
   *
   * @param {string|ServerRequest} url
   */
  static async load(url) {
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const body = await response.json();
        const newOptions = body.results ? body.results
          .reduce((accumulator, currentValue) => {
            const existingValue = accumulator.find((a) => a.label === currentValue.label);
            if (existingValue) {
              existingValue.value = `${existingValue.value}:${currentValue.id}`;
            } else {
              accumulator.push({ value: currentValue.id, label: currentValue.label });
            }
            return accumulator;
          }, []) : body;
        return newOptions.map((option) => new Option(option));
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  static find(options, value) {
    return options ? options.find((o) => o.value === value).label : '';
  }
}

export default Option;
