import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import Release from 'models/Release';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import Observation from './Observation';

import './ReleaseForm.css';

import { change, getOptions } from 'utils/change';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import CustomValidators from 'components/CustomValidators';
import { checkDisabled, errorMessage, initialValidation } from '../../utils/initialValidation';

const ReleaseForm = ({ handle, release, sourceOptions }) => {
  const [data, setData] = React.useState(release);
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    name: Validators.Required,
    source: CustomValidators.RequiredOption,
    dataSet: Validators.Required,
  };

  React.useEffect(() => {
    (async () => {
      const newErrorObject = await initialValidation(validators, data);
      setErrorState(newErrorObject);
    })();
  }, []);

  const onChange = change(data, setData);
  // const onChange = {
  //   input(event) {
  //     const { name, value } = event.target;
  //     setData({ ...data, [name]: value });
  //   },
  // };

  const observations = data.observations ? data.observations.map((o) => {
    const index = data.observations.indexOf(o);
    return (<Observation value={o} key={`${index} - ${o.referencePeriod}`} hasLabel={index === 0} />);
  }) : '';

  const addObservation = () => {
    const oldObservations = data.observations || [];
    oldObservations.push({ referencePeriod: '', value: '', time: '' });
    setData({ ...data, observations: oldObservations });
  };

  const changevalidation = (name) => (isValid) => {
    setErrorState({ ...errorState, [name]: isValid });
  };

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div className="release-form">
      <Card className="co-card-stack">
        <CardHeaderForm title={data.id ? 'Edit Release' : 'Create Release'} />
        <div className="base-form">
          <Validation validator={validators.name} errorMessage={errorMessage} onValidationChanged={changevalidation('name')}>
            <TextField name="name" id="name" label="Name" onChange={onChange.input} value={data.name || ''} placeholder="required" maxLength="64" tooltip="Name of the release, such as 'Non-Farm Payrolls'" />
          </Validation>
          <Validation validator={validators.source} errorMessage={errorMessage} onValidationChanged={changevalidation('source')}>
            <Dropdown name="source" label="Source" onChange={onChange.dropdown} options={sourceOptions} value={getOptions(data.source, sourceOptions)} />
          </Validation>
          <Validation validator={validators.dataSet} errorMessage={errorMessage} onValidationChanged={changevalidation('dataSet')}>
            <TextField name="dataSet" label="Dataset" maxLength="10" onChange={onChange.input} value={data.dataSet || ''} placeholder="required" />
          </Validation>
        </div>
      </Card>
      <Card>
        <div className="co-dropdown-row co-item observation-row">
          <Typography type="h2">Observations</Typography>
          <Button iconButton onClick={addObservation}><Icon iconName="plus" /></Button>
        </div>
        {observations}
      </Card>
      <div className="co-form-buttons co-item">
        <Button disabled={disabledButton} onClick={handle.save(data)}>{data.id ? 'Update' : 'Create'}</Button>
      </div>
    </div>
  );
};

ReleaseForm.propTypes = {
  handle: PropTypes.shape({
    cancel: PropTypes.func,
    save: PropTypes.func,
  }).isRequired,
  release: PropTypes.instanceOf(Release),
  sourceOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ReleaseForm.defaultProps = {
  release: new Release(),
  sourceOptions: [],
};

export default ReleaseForm;
