import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import { change, getOptionsMulti, validationChanged } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';

import Company from '../../models/Company';

const CompanyForm = ({ company, save, types }) => {
  const [data, setData] = React.useState(company);
  const [errorState, setErrorState] = React.useState({});

  const onChange = change(data, setData);
  const onValidationChanged = validationChanged(errorState, setErrorState);

  const validators = {
    name: Validators.Required,
    types: CustomValidators.RequiredArray,
  };

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      save={save}
      name="Company"
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="company-form">
        <Validation validator={validators.name} errorMessage={errorMessage} onValidationChanged={onValidationChanged('name')}>
          <TextField label="Name" maxLength="60" name="name" onChange={onChange.input} value={data.name || ''} />
        </Validation>

        <Validation validator={validators.types} errorMessage={errorMessage} onValidationChanged={onValidationChanged('types')}>
          <Dropdown isMultiSelect label="Types" name="types" onChange={onChange.dropdown('types')} options={types} value={getOptionsMulti(data.types, types)} />
        </Validation>
      </div>

    </FormWrapper>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.instanceOf(Company),
  save: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({ })),
};

CompanyForm.defaultProps = {
  company: new Company(),
  types: [],
};

export default CompanyForm;
