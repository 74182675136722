import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '@symphony-ui/uitoolkit-components/components/date-picker/DatePicker';
import TimePicker from '@symphony-ui/uitoolkit-components/components/time-picker/TimePicker';

import './DateTime.css';

/**
 * The first time TimePicker opens, a change event is sent. To avoid an infinite loop, we check
 * if we are running it for the first time. This can be removed if the TimePicker has been
 * fixed. See:
 *
 *  https://github.com/SymphonyPlatformSolutions/symphony-bdk-ui-toolkit-components/tree/master/src/components/time-picker
 *
 * @param {*} param0
 * @returns
 */
const DateTime = ({
  label, name, onChange, value,
}) => {
  const [data, setData] = React.useState(
    {
      date: value,
      time: value instanceof Date ? value.toLocaleTimeString() : '',
    },
  );

  const [hasRun, setRun] = React.useState(false);

  const change = {
    datetime: (type) => (event) => {
      const { value: newValue } = event.target;
      setData({ ...data, [type]: newValue });
    },
  };

  React.useEffect(() => {
    const newValue = data.date && data.time
      ? new Date(
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        data.time.substring(0, 2),
        data.time.substring(3, 5),
        data.time.substring(6, 8),
      ) : '';
    if (hasRun) {
      onChange({
        target: { name, value: newValue },
      });
    } else {
      setRun(true);
    }
  }, [data]);

  return (
    <div className={`datetime-container custom-input-group ${label !== '' ? 'co-has-label' : ''}`}>
      <DatePicker date={data.date} format="dd/MM/yyyy" label={label} name="date" onChange={change.datetime('date')} value="" />
      <TimePicker label="" name="time" onChange={change.datetime('time')} value={data.time || ''} />
    </div>
  );
};

DateTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

DateTime.defaultProps = {
  label: '',
  value: null,
};

export default DateTime;
