import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './CardHeader.css';
import ViewContext from 'contexts/ViewContext';

const CardHeaderForm = ({ newView, title }) => {
  const { setView } = React.useContext(ViewContext);

  const onClick = async () => {
    setView(newView);
  };

  return (
    <div className="card-header">
      <Typography type="h1">
        {title}
      </Typography>
      <Button iconButton onClick={onClick} variant="tertiary"><Icon iconName="cross" /></Button>
    </div>
  );
};

CardHeaderForm.propTypes = {
  newView: PropTypes.string,
  title: PropTypes.string.isRequired,
};

CardHeaderForm.defaultProps = {
  newView: 'TABLE',
};

export default CardHeaderForm;
