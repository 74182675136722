import PropTypes from 'prop-types';
import React from 'react';

import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';

import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import PermissionGroup from './PermissionGroup';

const Permissions = ({ hideModal, onChange, value }) => {
  const [permissions, setPermissions] = React.useState(value);

  const setValue = (name) => (event) => {
    setPermissions({ ...permissions, [name]: event });
  };

  React.useEffect(() => {
    onChange(permissions);
  }, [permissions]);

  const onClose = () => {
    hideModal();
  };

  return (
    <Modal className="co-items" closeButton onClose={onClose} parentNode={document.body} show>
      <div>
        <Typography type="h1">Permissions</Typography>
      </div>

      <div className="co-permissions-group">
        <PermissionGroup label="User" value={permissions.user} setValue={setValue('user')} />
        <PermissionGroup label="Company" value={permissions.group} setValue={setValue('group')} />
        <PermissionGroup label="Other" value={permissions.other} setValue={setValue('other')} />
      </div>
    </Modal>
  );
};

Permissions.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    user: PropTypes.shape({ }),
    group: PropTypes.shape({ }),
    other: PropTypes.shape({ }),
  }).isRequired,
};

export default Permissions;
