import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '@symphony-ui/uitoolkit-components/components/date-picker/DatePicker';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import TimePicker from '@symphony-ui/uitoolkit-components/components/time-picker/TimePicker';

import validate from 'utils/validation';
import DateTime from 'components/date-time/DateTime';

const Observation = ({ hasLabel, value }) => {
  const [data, setData] = React.useState(value);
  const [date, setDate] = React.useState(null);

  React.useEffect(() => {
    //    2021-10-25T10:00:00
    const regex = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T(?<hour>\d{2}):(?<minute>\d{2}):(?<second>\d{2})$/u;

    const match = data.time.match(regex);
    if (!match) {
      console.log('NO MATCH');
      setDate(null);
    } else {
      setDate(new Date(Number.parseInt(match.groups.year, 10),
        Number.parseInt(match.groups.month, 10),
        Number.parseInt(match.groups.day, 10),
        Number.parseInt(match.groups.hour, 10),
        Number.parseInt(match.groups.minute, 10),
        Number.parseInt(match.groups.second, 10)));
      console.log('match groups: %o', match.groups);
    }
  }, []);

  React.useEffect(() => {
    console.log('Date: %o', date);
  }, [date]);

  const change = {
    date(event) {
      console.log('DATE CHANGE %o', event);
    },
    input(event) {
      const { name, value: newValue } = event.target;
      setData({ ...data, [name]: newValue });
    },
    time(event) {
      console.log('TIME CHANGED: %o', event);
    },
  };

  return (
    <div className="single-row co-item" id="release-form">
      <TextField name="referencePeriod" id="name" label={hasLabel ? 'Reference Period' : ''} onChange={change.input} value={data.referencePeriod} placeholder="required" data-required data-maxlength="24" />
      <DateTime label={hasLabel ? 'Time' : ''} name="time" onChange={change.input} value={date} />
      <TextField name="value" id="name" label={hasLabel ? 'Value' : ''} onChange={change.input} value={data.value || ''} placeholder="optional" data-required data-maxlength="24" />
    </div>
  );
};

Observation.propTypes = {
  hasLabel: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    time: PropTypes.string,
  }).isRequired,
};

export default Observation;
