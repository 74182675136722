import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import Ipo from 'models/Release';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';

import './IpoForm.css';

import { change, getOptions } from 'utils/change';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import CustomValidators from 'components/CustomValidators';
import { checkDisabled, errorMessage, initialValidation } from '../../utils/initialValidation';

const IpoForm = ({ handle, ipo, exchangeOptions }) => {
  const [data, setData] = React.useState(ipo);
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    exchange: CustomValidators.RequiredOption,
  };

  React.useEffect(() => {
    (async () => {
      const newErrorObject = await initialValidation(validators, data);
      setErrorState(newErrorObject);
    })();
  }, []);

  const onChange = change(data, setData);

  const changevalidation = (name) => (isValid) => {
    setErrorState({ ...errorState, [name]: isValid });
  };

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div className="ipo-form">
      <Card className="co-card-stack">
        <CardHeaderForm title={data.id ? 'Edit Ipo' : 'Create Ipo'} />
        <div className="base-form">
          <Validation validator={validators.exchange} errorMessage={errorMessage} onValidationChanged={changevalidation('exchange')}>
            <Dropdown name="exchange" label="Exchange" onChange={onChange.dropdown} options={exchangeOptions} value={getOptions(data.exchange, exchangeOptions)} />
          </Validation>
        </div>
      </Card>
      <div className="co-form-buttons co-item">
        <Button disabled={disabledButton} onClick={handle.save(data)}>{data.id ? 'Update' : 'Create'}</Button>
      </div>
    </div>
  );
};

IpoForm.propTypes = {
  handle: PropTypes.shape({
    cancel: PropTypes.func,
    save: PropTypes.func,
  }).isRequired,
  ipo: PropTypes.instanceOf(Ipo),
  exchangeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

IpoForm.defaultProps = {
  ipo: new Ipo(),
};

export default IpoForm;
