import Approval from './Approval';
import Comment from './Comment';

class Draft {
  constructor(draft) {
    Object.assign(this, draft);

    if (this.comments) {
      this.comments = this.comments.map((comment) => new Comment(comment));
    }
    if (this.approvals) {
      this.approvals = this.approvals.map((approval) => new Approval(approval));
    }
  }

  toApprovalRow(p) {
    const foundApproval = this.approvals.find((a) => a.party === p);
    return foundApproval ? foundApproval.toRowMap() : { party: p, user: '', time: 'Not yet' };
  }

  updateComment(comment) {
    const updatedComments = [...this.comments];
    const index = updatedComments.findIndex((c) => c.id === comment.id);
    updatedComments[index] = comment;
    return new Draft({ ...this, comments: updatedComments });
  }
}

export default Draft;
