import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ShareConfirmation = ({ show, confirm, numberOfPages }) => {
  const click = {
    cancel() {
      confirm(false);
    },
    go() {
      confirm(true);
    },
  };

  return (
    <Modal className="co-items" closeButton onClose={click.cancel} parentNode={document.body} show={show}>
      <div>
        <Typography type="h1">
          Each message from the NIT Bot on Symphony will
          contain up to 40 securities, so this will send
          {numberOfPages}
          messages.
        </Typography>
      </div>
      <div className="co-form-buttons">
        <Button onClick={click.go} variant="destructive">Yes, I am sure</Button>
        <Button onClick={click.cancel}>No please cancel</Button>
      </div>
    </Modal>

  );
};

ShareConfirmation.propTypes = {
  confirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  numberOfPages: PropTypes.number.isRequired,
};

export default ShareConfirmation;
