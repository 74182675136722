import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import TagInput from 'components/tag-input/TagInput';
import { getOptions } from 'utils/change';

import QualifiedSearchObject from '../../models/QualifiedSearch';
import './QualifiedSearch.css';

const QualifiedSearch = ({
  disabled, label, name, onChange, value,
}) => {
  const [data, setData] = React.useState(value);

  const change = {
    dropdown: (name2) => (event) => {
      const { value: newValue } = event.target;
      setData({ ...data, [name2]: newValue.value });
    },
    input(event) {
      const { name: newName, value: newValue } = event.target;
      setData({ ...data, [newName]: newValue });
    },
    select(event) {
      const updatedQualifiedSearch = data.updatedQualifier(event.value);
      setData(updatedQualifiedSearch);
    },
  };

  React.useEffect(() => {
    const updatedQualifiedSearch = {
      target: {
        name,
        value: new QualifiedSearchObject(data),
      },
    };
    onChange(updatedQualifiedSearch);
  }, [data]);

  return (
    <div className="tk-input-group tag-input">
      <div className="tk-input-group__header">
        <label className="tk-label" htmlFor="search-group">{label}</label>
      </div>

      <div className="tk-input__container" id="search-group">
        <Dropdown
          className="first-one"
          isDisabled={disabled}
          onChange={change.dropdown('qualifier')}
          options={QualifiedSearchObject.options}
          value={getOptions(data.qualifier, QualifiedSearchObject.options)}
        />
        <TagInput
          className="second-one"
          disabled={disabled}
          name="text"
          onChange={change.input}
          tags={data.text}
        />
      </div>

    </div>
  );
};

QualifiedSearch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(QualifiedSearchObject),
};

QualifiedSearch.defaultProps = {
  disabled: false,
  value: new QualifiedSearchObject(),
};

export default QualifiedSearch;
