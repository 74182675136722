import React from 'react';

import PropTypes from 'prop-types';
import PeriodInput from './period-input/PeriodInput';
import PeriodRangeObject from '../models/PeriodRange';

const PeriodRange = ({
  defaultPeriod, disabled, name, onChange, value,
}) => {
  const [data, setData] = React.useState(value);

  const change = {
    input(event) {
      const { name: periodName, value: newValue } = event.target;
      const updatedPeriodRange = { ...data, [periodName]: newValue };
      setData(updatedPeriodRange);
    },
  };

  React.useEffect(() => {
    const returnObject = {
      target: {
        name,
        value: new PeriodRangeObject(data),
      },
    };
    onChange(returnObject);
  }, [data]);

  return (
    <div
      className="co-range"
    >
      <PeriodInput
        defaultPeriod={defaultPeriod}
        disabled={disabled}
        name="start"
        period={data.start}
        onChange={change.input}
      />
      <PeriodInput
        defaultPeriod={defaultPeriod}
        disabled={disabled}
        name="end"
        period={data.end}
        onChange={change.input}
      />
    </div>
  );
};

PeriodRange.propTypes = {
  defaultPeriod: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(PeriodRangeObject),
};

PeriodRange.defaultProps = {
  disabled: false,
  value: new PeriodRangeObject(),
};

export default PeriodRange;
