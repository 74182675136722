import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import ServerRequest from 'models/ServerRequest';

import FileInput from 'components/file-input/FileInput';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import DownloadContext from 'contexts/DownloadContext';
import { change, getOptionsMulti, validationChanged } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import Workflow from '../../model/Workflow';

const WorkflowForm = ({ workflow, handle }) => {
  const { download } = React.useContext(DownloadContext);
  const [data, setData] = React.useState(workflow);
  const [errorState, setErrorState] = React.useState({});

  const [partiesData, setPartiesData] = React.useState([]);

  const onChange = change(data, setData);
  const onValidationChanged = validationChanged(errorState, setErrorState);

  const validators = {
    termsheet: CustomValidators.RequiredFile,
    partiesBloomberg: CustomValidators.RequiredArray,
    partiesIsin: CustomValidators.RequiredArray,
    partiesDocumentation: CustomValidators.RequiredArray,
    partiesListing: CustomValidators.RequiredArray,
    partiesBooking: CustomValidators.RequiredArray,

  };

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/users/dropdown');
      const dropdowns = await download.json(request);
      if (dropdowns !== null) {
        setPartiesData(dropdowns);
      }
    })();
  }, []);

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      name="Workflow"
      save={handle.save}
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="workflow-form">
        <Validation validator={validators.termsheet} errorMessage={errorMessage} onValidationChanged={onValidationChanged('termsheet')}>
          <FileInput label="Term Sheet" name="termsheet" onChange={onChange.input} />
        </Validation>

        <Typography type="h2">Select those responsible for</Typography>

        <Validation validator={validators.partiesBloomberg} errorMessage={errorMessage} onValidationChanged={onValidationChanged('partiesBloomberg')}>
          <Dropdown label="Bloomberg Set Up" options={partiesData} onChange={onChange.dropdown('partiesBloomberg')} isMultiSelect value={getOptionsMulti(data.partiesBloomberg, partiesData)} />
        </Validation>

        <Validation validator={validators.partiesIsin} errorMessage={errorMessage} onValidationChanged={onValidationChanged('partiesIsin')}>
          <Dropdown label="ISIN Code" options={partiesData} onChange={onChange.dropdown('partiesIsin')} isMultiSelect value={getOptionsMulti(data.partiesIsin, partiesData)} />
        </Validation>

        <Validation validator={validators.partiesDocumentation} errorMessage={errorMessage} onValidationChanged={onValidationChanged('partiesDocumentation')}>
          <Dropdown
            label="Documentation"
            options={partiesData}
            onChange={onChange.dropdown('partiesDocumentation')}
            isMultiSelect
            value={getOptionsMulti(data.partiesDocumentation, partiesData)}
          />
        </Validation>

        <Validation validator={validators.partiesListing} errorMessage={errorMessage} onValidationChanged={onValidationChanged('partiesListing')}>
          <Dropdown label="Listing" options={partiesData} onChange={onChange.dropdown('partiesListing')} isMultiSelect value={getOptionsMulti(data.partiesListing, partiesData)} />
        </Validation>

        <Validation validator={validators.partiesBooking} errorMessage={errorMessage} onValidationChanged={onValidationChanged('partiesBooking')}>
          <Dropdown label="Trade Booking" options={partiesData} onChange={onChange.dropdown('partiesBooking')} isMultiSelect value={getOptionsMulti(data.partiesBooking, partiesData)} />
        </Validation>
      </div>

    </FormWrapper>
  );
};

WorkflowForm.propTypes = {
  handle: PropTypes.shape({}).isRequired,
  workflow: PropTypes.instanceOf(Workflow),
};

WorkflowForm.defaultProps = {
  workflow: new Workflow(),
};

export default WorkflowForm;
