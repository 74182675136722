import FileInfo from './FileInfo';

class Comment {
  constructor(comment) {
    Object.assign(this, comment);
    if (this.files) {
      this.files = this.files.map((file) => (typeof file === 'string' ? file : new FileInfo(file)));
    }
  }

  toTableData() {
    return {
      ...this,
      allTags: this.tags.join(', '),
      time: new Date(this.createdDate).toLocaleString(),
    };
  }
}

export default Comment;
