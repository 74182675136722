import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import MetadataButtons from 'components/metadata/MetadataButtons';
import { getOptions, validationChanged } from 'utils/change';
import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import NewIssue from '../../model/NewIssue';
import Tranche from './Tranche';
import TrancheObject from '../../model/Tranche';

const NewIssueForm = ({
  benchmarkOptions, currencyOptions, handle, issuerOptions, newIssue,
}) => {
  const [data, setData] = React.useState(newIssue);
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState(true);

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData(data.update(name, value));
    },
    dropdown: (name) => (event) => {
      const { value } = event.target.value;
      setData(data.update(name, value));
    },
    permissions(event) {
      const updated = new NewIssue({ ...data, event });
      setData(updated);
    },
  };
  const click = {
    addTranche() {
      setData(data.addTranche());
    },
  };

  const setTranche = (key) => (value) => {
    const trancheIndex = data.tranches.findIndex((t) => t.key === key);
    const newTranches = [...data.tranches];
    newTranches[trancheIndex] = new TrancheObject(value);
    setData(data.update('tranches', newTranches));
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  const validators = {
    issuer: CustomValidators.RequiredArray,
    name: Validators.Required,
  };

  React.useEffect(() => {
    (async () => {
      const newErrorObject = await initialValidation(validators, data);
      setErrorState(newErrorObject);
    })();
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <Card>
      <CardHeaderForm title={data.id ? 'Update New issue' : 'Create New Issue'} />
      <div className="base-form" id="new-issue-form">
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('issuer')} validator={validators.issuer}>
          <Dropdown name="issuer" label="Issuer" onChange={change.dropdown('issuerId')} options={issuerOptions} value={getOptions(data.issuerId, issuerOptions)} />
        </Validation>

        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('name')} validator={validators.name}>
          <TextField name="name" label="Name" onChange={change.input} value={data.name || ''} placeholder="Required" maxLength="40" />
        </Validation>
      </div>

      <div className="co-item">
        <div className="co-container">
          <Typography type="h2">Tranches</Typography>
          <Button className="co-button" iconButton onClick={click.addTranche}><Icon iconName="plus" /></Button>
        </div>

        {data.tranches && data.tranches.length ? data.tranches.map((tranche, index) => (
          <Tranche
            key={tranche.key}
            top={index === 0}
            tranche={tranche}
            setTranche={setTranche(tranche.key)}
            benchmarkOptions={benchmarkOptions}
            currencyOptions={currencyOptions}
          />
        )) : (<Typography type="h2">No Tranches Yet</Typography>
        )}
      </div>

      <div className="co-form-buttons-plus co-item">
        <Button disabled={disabledButton} onClick={handle.save(data)}>Save</Button>
        <MetadataButtons data={data} onChange={change.permissions} disabled={false} />
      </div>

    </Card>
  );
};

NewIssueForm.propTypes = {
  benchmarkOptions: PropTypes.arrayOf(PropTypes.shape({})),
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handle: PropTypes.shape({
    cancel: PropTypes.func,
    save: PropTypes.func,
  }).isRequired,
  issuerOptions: PropTypes.arrayOf(PropTypes.shape({})),
  newIssue: PropTypes.instanceOf(NewIssue),
};

NewIssueForm.defaultProps = {
  benchmarkOptions: [],
  currencyOptions: [],
  issuerOptions: [],
  newIssue: new NewIssue(),
};

export default NewIssueForm;
