import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import NewIssue from '../../model/NewIssue';
import NewIssueTable from './NewIssueTable';
import NewIssueForm from './NewIssueForm';
import NewIssueManager from './NewIssueManager';

const NewIssues = () => {
  const { view, setView } = React.useContext(ViewContext);

  const [newIssue, setNewIssue] = React.useState();
  const [newIssues, setNewIssues] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [states, setStates] = React.useState();
  const [benchmarkOptions, setBenchmarkOptions] = React.useState();
  const [currencyOptions, setCurrencyOptions] = React.useState();
  const [issuerOptions, setIssuerOptions] = React.useState();

  const { download } = React.useContext(DownloadContext);
  const service = new CrudService({ download, name: 'New Issue', path: '/synd/new-issues' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);

      if (body !== null) {
        const downloadedNewIssues = body.map((n) => new NewIssue(n));
        setNewIssues(downloadedNewIssues);
      } else {
        setError(true);
      }
    })();

    (async () => {
      const request = new ServerRequest('/core/companies/options/issuers');
      const body = await download.json(request, 'Unable to get the issuer options');
      if (body !== null) { setIssuerOptions(body); }
    })();

    (async () => {
      const request = new ServerRequest('/core/currencies/options');
      const body = await download.json(request, 'Unable to get the currency options');
      if (body !== null) { setCurrencyOptions(body); }
    })();

    (async () => {
      const request = new ServerRequest('/core/benchmarks/options');
      const body = await download.json(request, 'Unable to get the benchmark options');
      if (body !== null) { setBenchmarkOptions(body); }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/synd/new-issues/state/dropdown');
      const body = await download.json(request, 'Unable to get the state names');
      if (body !== null) {
        setStates(body);
      }
    })();
  }, []);

  const nextState = async (data) => {
    const request = new ServerRequest('/synd/new-issues/state', { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PATCH' });
    const body = await download.json(request, 'Unable to change state');

    if (body !== null) {
      const updatedNewIssue = new NewIssue(body);
      const updatedNewIssues = CrudService.updateData(newIssues, updatedNewIssue);
      setNewIssues(updatedNewIssues);
      setNewIssue(updatedNewIssue);
    }
  };

  const handle = {
    create: async () => {
      setNewIssue(new NewIssue());
      await setView('FORM');
    },
    nextStateMenu: async (data) => {
      await nextState(data.row.original);
    },
    nextState: async (data) => {
      await nextState(data);
    },
    save: (data) => async () => {
      const body = await (data.id ? service.update(data) : service.create(data));
      if (body !== null) {
        const updatedNewIssue = new NewIssue(body);
        const updatedNewIssues = CrudService.updateData(newIssues, updatedNewIssue);
        setNewIssues(updatedNewIssues);
        await setView('TABLE');
      }
    },
    delete: async ({ original: newIssueData }) => {
      const body = await service.delete(newIssueData);
      if (body) {
        const filteredNewIssues = newIssues.filter((n) => n.id !== newIssueData.id);
        setNewIssues(filteredNewIssues);
      }
    },
    edit: async () => {
      await setView('FORM');
    },
    manage: async ({ original: newIssueData }) => {
      setNewIssue(newIssueData);
      await setView('MANAGE');
    },

  };

  switch (view) {
    case 'FORM':
      return (
        <NewIssueForm
          benchmarkOptions={benchmarkOptions}
          currencyOptions={currencyOptions}
          handle={handle}
          issuerOptions={issuerOptions}
          newIssue={newIssue}
          states={states}
        />
      );
    case 'MANAGE':
      return (
        <NewIssueManager
          handle={handle}
          issuerOptions={issuerOptions}
          newIssue={newIssue}
          states={states}
        />

      );
    case 'TABLE':
      return (
        <NewIssueTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          issuerOptions={issuerOptions}
          newIssues={newIssues}
          states={states}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default NewIssues;
