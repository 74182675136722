import React from 'react';

const Demo = () => (
  <div>
    <video width="640" height="480" controls>
      <track src="/assets/media/CEDAR subtitles.vtt" kind="captions" />
      <source
        src="/assets/media/simplescreenrecorder-2020-10-22_16.31.21.mkv"
        type="video/webm"
      />

    </video>
  </div>
);

export default Demo;
