import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import SecuritiesTable from '../components/results/SecuritiesTable';

const OverviewCategory = ({ securities, title }) => {
  if (securities.length === 0) {
    return '';
  }

  return (
    <Card>
      <Typography type="h1">{title}</Typography>

      <SecuritiesTable
        emptyMessage="empty"
        isLoading={false}
        filteredData={securities}
        maxHeight={0}
      />
    </Card>
  );
};

OverviewCategory.propTypes = {
  title: PropTypes.string.isRequired,
  securities: PropTypes.arrayOf(PropTypes.shape({

  })).isRequired,
};

export default OverviewCategory;
