import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import TermSheet from '../../model/TermSheet';

import TermSheetTable from './TermSheetTable';
import TermSheetForm from './TermSheetForm';

const TermSheets = () => {
  const { view, setView } = React.useContext(ViewContext);
  const { download } = React.useContext(DownloadContext);

  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [termSheets, setTermSheets] = React.useState([]);

  const [currencyOptions, setCurrencyOptions] = React.useState();
  const [interestBasisOptions, setInterestBasisOptions] = React.useState();
  const [issuerOptions, setIssuerOptions] = React.useState();

  const service = new CrudService({ download, name: 'Term Sheet', path: '/mtn/term-sheets' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedTermSheets = body.map((u) => new TermSheet(u));
        setTermSheets(downloadedTermSheets);
      } else {
        setError(true);
      }
    })();

    (async () => {
      const currencyRequest = new ServerRequest('/mtn/currencies/options');
      const options = await download.json(currencyRequest);
      if (options !== null) {
        setCurrencyOptions(options);
      }
    })();

    (async () => {
      const interestBasisRequest = new ServerRequest('/mtn/interest-basis/options');
      const options = await download.json(interestBasisRequest);
      if (options !== null) {
        setInterestBasisOptions(options);
      }
    })();

    (async () => {
      const issuerRequest = new ServerRequest('/mtn/issuers/options');
      const options = await download.json(issuerRequest);
      if (options !== null) {
        setIssuerOptions(options);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setDetails(new TermSheet());
      await setView('FORM');
    },
    save: async (data) => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedTermSheet = new TermSheet(body);
        const updatedTermSheets = CrudService.updateData(termSheets, updatedTermSheet);
        setTermSheets(updatedTermSheets);
        await setView('TABLE');
      }
    },
    delete: async ({ original: termSheet }) => {
      const body = await service.delete(termSheet);
      if (body) {
        const filteredTermSheets = termSheets.filter((u) => u.id !== termSheet.id);
        setTermSheets(filteredTermSheets);
      }
    },
    edit: async ({ original: termSheet }) => {
      setDetails(termSheet);
      await setView('FORM');
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <TermSheetForm
          currencyOptions={currencyOptions}
          interestBasisOptions={interestBasisOptions}
          issuerOptions={issuerOptions}
          termSheet={details}
          save={handle.save}
        />
      );
    case 'TABLE':
      return (
        <TermSheetTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          termSheets={termSheets}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default TermSheets;
