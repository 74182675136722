import apiSource from 'utils/system/apiSource';

class ServerRequest extends Request {
  constructor(path, init) {
    const { jwt } = window;
    const input = path instanceof URL ? path : apiSource(path);

    const updatedInit = init ? { ...init } : {};
    if (jwt) {
      const newHeaders = { ...updatedInit.headers, Authentication: jwt };
      updatedInit.headers = newHeaders;
    } else {
      updatedInit.credentials = 'include';
    }
    super(input, updatedInit);
  }
}

export default ServerRequest;
