import { actionCell } from 'utils/table';
import TableLink from '../components/TableLink';

const sec = {
  objectify(security) {
    return security;
  },
  columns() {
    return [
      actionCell,
      { accessor: 'issuername', className: 'co-table-text co-table-text-l', header: 'Issuer Name' },
      {
        accessor: 'links[0]', Cell: TableLink, className: 'co-table-text co-table-text-xl', header: 'Link to Document',
      },
      { accessor: 'issuertype', className: 'co-table-text co-table-text-xl', header: 'Issuer Type' },
      { accessor: 'category', header: 'Form' },
      { accessor: 'accessionNumber', header: 'Code' },
      { accessor: 'tags', header: 'Tags' },
    ];
  },
  companyColumns: [
    actionCell,
    { accessor: 'cik', header: 'CIK' },
    {
      accessor: 'name', className: 'co-table-text co-table-text-l', header: 'Name',
    },
  ],
  companyMatcher: (security) => (c) => {
    const regex = /^(?<type>[A-Z0-9]+) - (?<name>.*) \((?<cik>[0-9]{10})\) \(Filer\)$/;
    const match = security.title.match(regex);
    const addZeroes = (code) => '0'.repeat(10 - code.length) + code;

    return match
      ? addZeroes(c.company.cik) === match.groups.cik
      : false;
  },

  companySortField: 'name',
  fields: {
    amount: null, cfi: null, currency: null, issuername: 'name',
  },
  getName: (security) => security.name,
  getCurrency: (security) => security.query.issueCurrency,
  getCreationDate: (security) => (security.query.update ? security.query.update.toString() : ''),
  getMaturity: (security) => (security.query.maturitydate ? security.query.maturitydate.toString() : ''),
  markupMap(companies) {
    if (companies.length === 0) {
      return (s) => s;
    }
    return (s) => {
      const regex = /^(?<type>[A-Z0-9]+) - (?<name>.*) \((?<cik>[0-9]{10})\) \(Filer\)$/;
      const match = s.title.match(regex);
      const addZeroes = (code) => '0'.repeat(10 - code.length) + code;

      const company = match
        ? companies.find((c) => addZeroes(c.company.cik) === match.groups.cik)
        : null;
      return { ...s, issuername: (company ? company.company.name : ''), issuertype: company ? company.company.sicDescription : '' };
    };
  },
};

export default sec;
