import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import ServerRequest from 'models/ServerRequest';
import { change, getOptionsMulti, validationChanged } from 'utils/change';
import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';

import Filter from '../../models/Filter';
import Security from '../../models/Security';
import ShareConfirmation from '../../pages/ShareConfirmation';
import exportToExcel from '../../service/exportToExcel';

const ResultsShare = ({
  filter, result, securities, setShow, show, source,
}) => {
  const [errorState, setErrorState] = React.useState({});
  const [loading, setLoading] = React.useState({ excel: false, symphony: false });
  const [options, setOptions] = React.useState([]);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [value, setValue] = React.useState({});

  const [disabledButton, setButtonDisabled] = React.useState(true);

  const validators = {
    streamIds: CustomValidators.RequiredArray,
  };

  const onChange = change(value, setValue);

  const onValidationChanged = validationChanged(errorState, setErrorState);

  const numberOfPages = React.useMemo(() => Math.round((securities.length - 1) / 40 + 1));

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/nit/streams/options');
      const response = await fetch(request);
      const body = await response.json();
      setOptions(body);
    })();

    (async () => {
      const initialErrorState = await initialValidation(validators, value);
      setErrorState(initialErrorState);
    })();
  }, []);

  const shareOnSymphony = async () => {
    console.log('RESULT: %o streamIds %o', result, value.streamIds);
    const [resultSource, id] = result.split('|');
    const formData = new FormData();
    formData.append('streamIds', value.streamIds);
    formData.append('id', id);

    const jsonOption = { type: 'application/json' };
    formData.append('filter', new Blob(
      [JSON.stringify(filter)],
      jsonOption,
    ));
    const request = new ServerRequest(`/nit/${resultSource.toLowerCase()}/share`, { body: formData, method: 'POST' });

    const response = await fetch(request);
    if (response.status === 204) {
      setShow(false);
    } else {
      console.error('THERE HAS BEEN AN ERROR!!!');
    }
  };

  const click = {
    close() {
      setShow(false);
    },
    confirm(event) {
      console.log('YOU CLICKED %o', event);
      setShowConfirmation(false);
      if (event === true) {
        shareOnSymphony();
      }
    },
    excel: async () => {
      setLoading({ ...loading, excel: true });
      await exportToExcel(securities, source);
      setLoading({ ...loading, excel: false });
    },
    symphony: async () => {
      if (numberOfPages > 1) {
        setShowConfirmation(true);
      } else {
        setLoading({ ...loading, symphony: true });
        await shareOnSymphony();
        setLoading({ ...loading, symphony: false });
      }
    },
  };

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div>
      <ShareConfirmation
        confirm={click.confirm}
        numberOfPages={numberOfPages}
        show={showConfirmation}
      />
      <Modal className="co-items" show={show} closeButton onClose={click.close} parentNode={document.body}>
        <div>
          <Typography type="h1">Share on Symphony or Excel</Typography>
        </div>
        <div>
          <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('streamIds')} validator={validators.streamIds}>
            <Dropdown isMultiSelect label="Select Rooms and IMs to share on Symphony" onChange={onChange.dropdown('streamIds')} options={options} value={getOptionsMulti(value.streamIds, options)} />
          </Validation>
        </div>
        <div className="co-form-buttons">
          <Button
            disabled={disabledButton}
            loading={loading.symphony}
            onClick={click.symphony}
          >
            Share on Symphony
          </Button>
          <Button
            disabled={source === null}
            loading={loading.excel}
            onClick={click.excel}
          >
            Open as Excel

          </Button>
        </div>
      </Modal>
    </div>
  );
};

ResultsShare.propTypes = {
  filter: PropTypes.instanceOf(Filter).isRequired,
  result: PropTypes.string,
  securities: PropTypes.arrayOf(PropTypes.instanceOf(Security)).isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  source: PropTypes.string,
};

ResultsShare.defaultProps = {
  result: '',
  source: null,
};

export default ResultsShare;
