import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CardHeaderTable from 'components/card-header/CardHeaderTable';

import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import Currency from '../../models/Currency';

const CurrencyTable = ({
  currencies, handle, hasError, isLoading,
}) => {
  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'value',
      className: 'co-centered',
      header: 'Currency',
    },
  ]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Currencies" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={currencies}
        emptyMessage="You have no currencies yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

CurrencyTable.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.instanceOf(Currency)),
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CurrencyTable.defaultProps = {
  currencies: [],
  hasError: false,
  isLoading: false,
};

export default CurrencyTable;
