import React from 'react';

import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';
import Release from '../../models/Release';

import ReleaseTable from './ReleaseTable';
import ReleaseForm from './ReleaseForm';

const Releases = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [releases, setReleases] = React.useState();
  const [source, setSource] = React.useState(null);
  const [sourceOptions, setSourceOptions] = React.useState();

  const service = new CrudService({ download, name: 'Release', path: '/bb/release' });

  React.useEffect(() => {
    download.options('/bb/release/options', 'Error getting source options', setSourceOptions);
  }, []);

  React.useEffect(() => {
    if (source !== null) {
      (async () => {
        const url = new ServerURL('/bb/release');
        url.searchParams.append('source', source);
        const request = new ServerRequest(url);

        const body = await download.json(request, 'unable to download');
        setLoading(false);
        if (body !== null) {
          const downloadedReleases = body.map((u) => new Release(u));
          setReleases(downloadedReleases);
        } else {
          setError(true);
        }
      })();
    }
  }, [source]);

  const handle = {
    create: async () => {
      setDetails(new Release());
      return setView('FORM');
    },
    save: (data) => async () => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedRelease = new Release(body);
        const updatedCurrencies = CrudService.updateData(releases, updatedRelease);
        setReleases(updatedCurrencies);
        await setView('TABLE');
      }
    },
    delete: async ({ original: release }) => {
      const body = await service.delete(release);
      if (body) {
        const filteredReleases = releases.filter((u) => u.id !== release.id);
        setReleases(filteredReleases);
      }
    },
    edit({ original: release }) {
      setDetails(release);
      setView('FORM');
    },

  };

  switch (view) {
    case 'FORM':
      return (
        <ReleaseForm
          handle={handle}
          release={details}
          sourceOptions={sourceOptions}
        />
      );
    case 'TABLE':
      return (
        <ReleaseTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          releases={releases}
          setSource={setSource}
          sourceOptions={sourceOptions}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Releases;
