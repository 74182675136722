import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';

import markupSecurities from '../utils/markupSecurities';
import currencyDownload from '../utils/currencyDownload';
import OverviewCategory from './OverviewCategory';

const Overview = () => {
  const { download } = React.useContext(DownloadContext);

  const [categoryList, setCategoryList] = React.useState([]);
  const [currencies, setCurrencies] = React.useState({});
  const [downloadedSecurities, setDownloadedSecurities] = React.useState([]);

  const categories = [
    {
      title: 'Public deals',
      filter: (s) => (Object.keys(currencies).length
        ? (Number.parseFloat(s.totalissuedamount.replaceAll(',', '')) / currencies[s.currencylabel]) > 200000000 : false),
    }, {
      title: 'Long dated euros',
      filter: (s) => s.currencylabel === 'EUR' && (new Date(s.maturitydate).getTime() - Date.now()) / 1000 / 3600 / 24 / 365 > 20,
    }, {
      title: 'Short dated FRNs',
      filter: (s) => s.rateType === 'Floating',
    },
  ];

  const absFilter = (s) => {
    if (s.tags && s.tags.contains('CLO')) {
      return true;
    }
    if (s.cfi && (s.cfi.groups === 'Asset-Backed Securities' || s.cfi.groups === 'Mortgage-Backed Securities')) {
      return true;
    }
    if (s.shortname.includes('2021-') || s.shortname.includes(' CLO ')) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    // download all new securities from today

    const request = new ServerRequest('/nit/security/today');

    (async () => {
      const body = await download.json(request, 'Error downloading today\'s securities');
      if (body !== null) setDownloadedSecurities(body.filter((s) => !absFilter(s)));
    })();

    (async () => {
      const downloadedCurrencies = await currencyDownload();
      setCurrencies(downloadedCurrencies);
    })();
  }, []);

  React.useEffect(() => {
    const list = categories.map((c) => {
      const filteredSecurities = downloadedSecurities.filter(c.filter);
      if (!filteredSecurities.length) {
        return '';
      }
      const source = 'EUROCLEAR';
      return (
        <OverviewCategory
          key={c.title}
          title={c.title}
          securities={markupSecurities(source, filteredSecurities, [], currencies)}
        />
      );
    });
    setCategoryList(list);
  }, [downloadedSecurities, currencies]);

  return (
    <div>
      <Typography type="h1">Summary of today&apos;s New Issues</Typography>
      {categoryList}
      <Card className="co-card-stack">
        <div className="co-item">
          <Typography type="h1">Total Volume</Typography>
        </div>
      </Card>
    </div>
  );
};

export default Overview;
