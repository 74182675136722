import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import './ImageCard.css';

const ImageCard = ({
  children, text, id, onClick,
}) => {
  React.useEffect(() => {
    const element = document.getElementById(id);
    if (element !== null) {
      document.getElementById(id).onclick = onClick;
    }
  }, []);

  return (
    <Card className="image-card" id={id}>
      <div className="image-box">
        {children}
        <div className="title-box">
          <Typography className="image-text" type="h1">{text}</Typography>
        </div>
      </div>
    </Card>
  );
};

ImageCard.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ImageCard;
