import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import DownloadContext from 'contexts/DownloadContext';

import ServerRequest from 'models/ServerRequest';

import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';
import DateTime from 'components/date-time/DateTime';
import FileInput from 'components/file-input/FileInput';
import CustomValidators from 'components/CustomValidators';
import { getOptionsMulti } from 'utils/change';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';

const Create = ({ handle }) => {
  const { download } = React.useContext(DownloadContext);

  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [data, setData] = React.useState({
    version: 'initial',
    parties: [],
    deadline: new Date(new Date().getFullYear(), new Date().getMonth(),
      new Date().getDate() + 7, 17, 0, 0),
  });
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [options, setOptions] = React.useState({ parties: [] });
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    deadline: Validators.Required,
    file: CustomValidators.RequiredFile,
    name: Validators.Required,
    parties: CustomValidators.RequiredArray,
    version: Validators.Required,
  };

  React.useEffect(() => {
    (async () => {
      const newErrorState = await initialValidation(validators, data);
      setErrorState(newErrorState);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/users/dropdown');
      const body = await download.json(request, 'Error downloading users.');
      if (body !== null) {
        setOptions({ parties: body });
      }
    })();
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  const getFormData = () => {
    const formData = new FormData();
    formData.append('file', data.file);
    const workflow = {
      drafts: [{ deadline: data.deadline, version: data.version }],
      name: data.name,
    };
    const jsonOption = { type: 'application/json' };

    formData.append('workflow', new Blob(
      [JSON.stringify(workflow)],
      jsonOption,
    ));
    formData.append('ids', new Blob([JSON.stringify(data.parties)], jsonOption));
    return formData;
  };

  const change = {
    dropdown: (name) => (event) => {
      const { value } = event.target;
      const newValue = value ? value.map((v) => v.value) : null;
      setData({ ...data, [name]: newValue });
    },
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const click = {
    save: async () => {
      setButtonLoading(true);
      handle.save(getFormData());
    },
  };

  const changevalidation = (name) => (isValid) => {
    setErrorState({ ...errorState, [name]: isValid });
  };

  return (
    <Card>
      <CardHeaderForm title="Create" />

      <div className="base-form">
        <Validation validator={validators.name} errorMessage={errorMessage} onValidationChanged={changevalidation('name')}>
          <TextField label="Name" maxLength="40" name="name" onChange={change.input} tooltip="Give a name to your workflow. This will be used to name the Symphony chat rooms for the workflow." value={data.name || ''} />
        </Validation>

        <Validation validator={validators.version} errorMessage={errorMessage} onValidationChanged={changevalidation('version')}>
          <TextField label="Version" maxLength="20" name="version" onChange={change.input} value={data.version || ''} />
        </Validation>

        <Validation validator={validators.deadline} errorMessage={errorMessage} onValidationChanged={changevalidation('deadline')}>
          <DateTime label="Deadline" name="deadline" onChange={change.input} value={data.deadline} />
        </Validation>

        <Validation validator={validators.file} errorMessage={errorMessage} onValidationChanged={changevalidation('file')}>
          <FileInput label="File" name="file" onChange={change.input} />
        </Validation>

        <Validation validator={validators.parties} errorMessage={errorMessage} onValidationChanged={changevalidation('parties')}>
          <Dropdown isMultiSelect label="Parties" name="parties" onChange={change.dropdown('parties')} options={options.parties} value={getOptionsMulti(data.parties, options.parties)} />
        </Validation>
      </div>
      <div className="form-buttons">
        <Button disabled={disabledButton} loading={buttonLoading} onClick={click.save}>
          Create Workflow
        </Button>
      </div>
    </Card>
  );
};

Create.propTypes = {
  handle: PropTypes.shape({
    save: PropTypes.func,
  }).isRequired,
};

export default Create;
