import cfiData from './ cfiData';

export default cfiData.categories.map((category) => {
  const options = category.groups.map((g) => ({
    label: `${category.name} - ${g.name}`,
    value: category.code + g.code,
  }));

  options.unshift({
    label: `${category.name} - Any`,
    value: category.code,
  });

  return {
    label: category.name,
    value: `${category.code}?`,
    options,
  };
});
