import cfiData from './ cfiData';

const utils = {
  allAtributes(attrCodes, attributes) {
    return attributes.map((a, index) => {
      if (attrCodes[index] === 'X') {
        return 'X';
      }
      const object = a.values.find((attr) => attr.code === attrCodes[index]);
      if (typeof object === 'object' && 'name' in object) {
        return object.name;
      }
      return '???';
    });
  },

  attribute(field, attrCodes, attributes) {
    const index = this.getAttributeIndex(field);
    const object = attributes[index].values
      .find(utils.code(attrCodes[index]));
    if (attrCodes[index] === 'X') {
      return 'X';
    }
    if (typeof object === 'object' && 'name' in object) {
      return object.name;
    }
    return '???';
  },
  code: (toFind) => (attr) => attr.code === toFind,
  createReturn(category, rightCategory, text) {
    if (typeof category !== 'object') {
      if (rightCategory) {
        return text;
      }
      return '-';
    } if (rightCategory) {
      return category.name;
    }
    return null;
  },
  getAttributeIndex: (field) => {
    const regex = /^attribute(?<index>\d)$/u;
    return field.match(regex).groups.index - 1;
  },
  parse(cfi, field) {
    if (cfi === '      ' || cfi === 'XXXXX ') {
      return '';
    }
    const
      [catCode, groupCode, ...attrCodes] = cfi.split('');
    const category = cfiData.categories.find(utils.code(catCode));

    if (field === 'category' || typeof category === 'undefined') {
      return this.createReturn(
        category,
        field === 'category',
        `Unknown category in ${cfi}`,
      );
    }

    let group = {};
    group = category.groups.find(this.code(groupCode));

    if (field === 'group' || typeof group === 'undefined') {
      return this.createReturn(
        group,
        field === 'group',
        `Unknown group in ${cfi}`,
      );
    }
    return this.attribute(
      field,
      attrCodes,
      group.attributes,
    );
  },
};

export default (cfi) => {
  const cfiObject = {
    category: '',
    group: '',
    attribute1: '',
    attribute2: '',
    attribute3: '',
    attribute4: '',
  };
  if (cfi === '      ' || cfi === 'XXXXX ' || cfi === null) {
    return cfiObject;
  }

  const [catCode, groupCode, ...attrCodes] = cfi.split('');
  //  console.log(`CFI: ${cfi}`);
  const category = cfiData.categories.find((attr) => attr.code === catCode);
  if (typeof category === 'undefined') {
    return {
      ...cfiObject,
      category: `Unknown Cat: ${catCode}`,
    };
  }
  cfiObject.category = category.name;

  const group = category.groups.find((attr) => attr.code === groupCode);
  if (group === null) return { ...cfiObject, group: `Unknown Group: ${groupCode}` };
  cfiObject.group = group.name;

  //  const attrArray = utils.allAttributes(attrCodes, group.attributes);
  const [attribute1, attribute2, attribute3, attribute4] = group.attributes.map((a, index) => {
    if (attrCodes[index] === 'X') return 'X';

    const object = a.values.find((attr) => attr.code === attrCodes[index]);
    if (typeof object === 'object' && 'name' in object) return object.name;

    return '???';
  });

  return {
    ...cfiObject,
    attribute1,
    attribute2,
    attribute3,
    attribute4,
  };
};

// export default (field) => (row) => {
//   if (typeof row.cfi === 'object') {
//     if (field === 'category' || field === 'group') {
//       return row.cfi[field];
//     }
//     const attribute = row.cfi.attributes[utils.getAttributeIndex(field)];
//     if (typeof attribute === 'object') {
//       return attribute.value;
//     }
//     return '';
//   }

//   let cfiField = null;
//   if ('cficode' in row) cfiField = 'cficode';
//   if ('gnr_cfi_code' in row) cfiField = 'gnr_cfi_code';

//   return cfiField ? utils.parse(row[cfiField], field) : '';
// };
