class Currency {
  constructor(currency) {
    Object.assign(this, currency);

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }
}

export default Currency;
