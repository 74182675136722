import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Checkbox from '@symphony-ui/uitoolkit-components/components/selection/Checkbox';
import TextArea from '@symphony-ui/uitoolkit-components/components/input/TextArea';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import apiSource from 'utils/system/apiSource';

import ServerRequest from 'models/ServerRequest';
import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import { validationChanged } from 'utils/change';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const findAreaCode = () => {
  if ('languages' in navigator && navigator.languages) {
    const firstLocale = navigator.languages[0];
    const map = {
      'en-GB': '+44',
      'fr-FR': '+33',
      'nl-NL': '+31',
      'de-DE': '+49',
    };
    return map[firstLocale] || '+1';
  }
  return '+1';
};

const Contact = ({ hideModal }) => {
  const [agreement, setAgreement] = React.useState('false');
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [external, setExternal] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  const [data, setData] = React.useState({
    areaCode: findAreaCode(),
  });

  const validators = {
    firstName: Validators.Required,
    lastName: Validators.Required,
    email: CustomValidators.Email,
    areaCode: null,
    phoneNumber: null,
    company: Validators.Required,
    message: Validators.Required,
    agreement: Validators.Required,
  };

  React.useEffect(() => {
    (async () => {
      const newErrorState = await initialValidation(validators, data);
      newErrorState.agreement = false;
      setErrorState(newErrorState);
    })();
  }, []);

  React.useEffect(() => {
    const getUser = async () => {
      const request = new ServerRequest('/user/info');
      try {
        const response = await fetch(request);
        if (response.status === 200) {
          const body = await response.json();
          setData(Object.merge(data, body));
          setExternal(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUser();
  }, []);

  const click = {
    sendContact: async () => {
      setButtonDisabled(true);

      const formData = new FormData();

      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('areaCode', data.areaCode);
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('email', data.email);
      formData.append('company', data.company);
      formData.append('message', data.message);

      const url = apiSource('/request-demo');
      const init = { body: formData, method: 'POST' };

      try {
        await fetch(url, init);
        hideModal();
      } catch (error) {
        console.error(error);
      }
    },
  };

  // TODO #9 #8 Validation of inputs
  const change = {
    agreement(event) {
      const { value } = event.target;
      setErrorState({ ...errorState, agreement: value !== 'true' });
      setAgreement(value);
    },
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <Modal show closeButton onClose={hideModal} parentNode={document.body}>
      <Typography type="h1">Request a Demo</Typography>
      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('firstName')} validator={validators.firstName}>
        <TextField disabled={external} label="First Name" name="firstName" onChange={change.input} value={data.firstName || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('lastName')} validator={validators.lastName}>
        <TextField disabled={external} label="Last Name" name="lastName" onChange={change.input} value={data.lastName || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('email')} validator={validators.email}>
        <TextField label="Email" name="email" onChange={change.input} value={data.email || ''} />
      </Validation>

      <div disabled={external}>
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('areaCode')} validator={validators.areaCode}>
          <TextField label="Country Code" name="areaCode" onChange={change.input} value={data.areaCode || ''} />
        </Validation>

        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('phoneNumber')} validator={validators.phoneNumber}>
          <TextField label="Phone Number" name="phoneNumber" onChange={change.input} value={data.phoneNumber || ''} />
        </Validation>
      </div>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('company')} validator={validators.company}>
        <TextField disabled={external} label="Company" name="company" onChange={change.input} value={data.company || ''} />
      </Validation>

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('message')} validator={validators.message}>
        <TextArea label="Your message" onChange={change.input} name="message" style={{ height: '5rem' }} value={data.message || ''} />
      </Validation>

      <Checkbox name="agreement" value={agreement} onChange={change.agreement} label="I agree to my email being stored and used to receive communications from Vanadis Ltd." />

      <div className="co-form-buttons co-item">
        <Button disabled={disabledButton} onClick={click.sendContact}>Contact</Button>
      </div>
    </Modal>
  );
};

Contact.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default Contact;
