import React from 'react';
import PageWrapper from 'pages/PageWrapper';
import { iconUrl } from '../utils/iconUrls';
import Demo from './Demo';
import Workflows from './Workflows';

const CedarApp = () => (
  <PageWrapper
    appName="CEDAR"
    logoLocation={iconUrl(240)}
    pages={[{
      auth: 'ARRANGER', defaultView: 'TABLE', element: (<Workflows />), label: 'Workflows',
    }, {
      auth: 'DEMO', element: (<Demo />), label: 'Demo',
    }]}
  />
);

export default CedarApp;
