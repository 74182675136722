import anna from './anna';
import esma from './esma';
import euroclear from './euroclear';
import ipo from './ipo';
import sec from './sec';

export const databaseOptions = [
  { label: 'Euroclear', value: 'EUROCLEAR' },
  { label: 'ESMA', value: 'ESMA' },
  { label: 'ANNA', value: 'ANNA' },
  { label: 'SEC', value: 'SEC' },
  { label: 'IPO', value: 'IPO' },
];

const sources = {
  EUROCLEAR: euroclear, ESMA: esma, ANNA: anna, SEC: sec, IPO: ipo,
};

export default sources;
