import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import { checkEditable } from 'components/metadata/utils';
import AppViewContext from 'contexts/AppViewContext';
import { errorMessage } from 'utils/initialValidation';
import { getOptions } from 'utils/change';

import Search from '../../../models/Search';

const IpoForm = ({ search, setSearch }) => {
  const { user } = React.useContext(AppViewContext);

  const [disabled, setDisabled] = React.useState(false);

  const validators = {
    exchange: null,
  };

  React.useEffect(() => {
    if (search.id) {
      const isEditable = checkEditable(search, user);
      setDisabled(!isEditable);
    }
  }, []);

  const change = {
    filter: (event) => {
      const { name, value } = event.target;
      const updatedSearch = search.updateFilter(name, value);
      setSearch(updatedSearch);
    },
    query: (event) => {
      const { name, value } = event.target;
      const updatedSearch = search.updateQuery(name, value);
      setSearch(updatedSearch);
    },
    querySelect: (name) => (event) => {
      const { value } = event;
      const updatedSearch = search.updateQuery(name, value);
      setSearch(updatedSearch);
    },
  };

  const options = [{ label: 'Euronext', value: 'EURONEXT' }, { label: 'NYSE', value: 'NYSE' }, { label: 'LSE', value: 'LSE' }, { label: 'Xetra/DAX', value: 'XETRA' }];

  const onValidationChanged = (name) => (error) => {
    console.log('Validation changed');
  };

  return (
    <div className="base-form">

      <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('exchange')} validator={validators.exchange}>
        <Dropdown isDisabled={disabled} label="Exchange" name="exchange" onChange={change.querySelect('exchange')} options={options} tooltip="Whether to include changes in ownership by insiders in the results. This will have no impact if you choose a Type." value={getOptions(search.query.exchange, options)} />
      </Validation>

    </div>
  );
};

IpoForm.propTypes = {
  search: PropTypes.instanceOf(Search),
  setSearch: PropTypes.func.isRequired,
};

IpoForm.defaultProps = {
  search: Search.init(),
};

export default IpoForm;
