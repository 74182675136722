/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

import PropTypes from 'prop-types';
import React from 'react';

import { Banner, Modal, Typography } from '@symphony-ui/uitoolkit-components';

import LoginForm from './LoginForm';
import ResetForm from './ResetForm';
import TokenForm from './TokenForm';
import PasswordForm from './PasswordForm';
import './Login.css';

const Login = ({ close }) => {
  const [form, setForm] = React.useState('LOGIN');
  const [show, setShow] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [message, setMessage] = React.useState(null);
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {
    setShow(message !== null);
  });
  const handleButton = () => {
    console.log('button clicked');

    setShow(false);
  };

  const switchForm = (newForm) => {
    setForm(newForm);
  };

  return (
    <Modal show closeButton onClose={close} parentNode={document.body}>
      <Typography type="h1">{title}</Typography>
      {show
        ? (
          <Banner
            content={message !== null ? message.text : ''}
            hasButton
            onClose={handleButton}
            show={show}
            variant={message !== null ? message.type : 'info'}
          />
        )
        : ''}

      {(() => {
        switch (form) {
          case 'LOGIN':
            return (
              <LoginForm
                close={close}
                setMessage={setMessage}
                setTitle={setTitle}
                switchForm={switchForm}
              />
            );
          case 'RESET':
            return (
              <ResetForm
                setMessage={setMessage}
                setTitle={setTitle}
                switchForm={switchForm}
              />
            );
          case 'TOKEN':
            return (
              <TokenForm
                setMessage={setMessage}
                setTitle={setTitle}
                setToken={setToken}
                switchForm={switchForm}
              />
            );
          case 'PASSWORD':
            return (
              <PasswordForm
                setMessage={setMessage}
                setTitle={setTitle}
                switchForm={switchForm}
                token={token}
              />
            );
          default:
            throw new Error('help');
        }
      })()}
    </Modal>
  );
};

Login.propTypes = {
  close: PropTypes.func.isRequired,
};

export default Login;
