import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Radio from '@symphony-ui/uitoolkit-components/components/selection/Radio';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const ChangeTheme = () => {
  const initialTheme = React.useMemo(() => (localStorage.getItem('theme')
    ? JSON.parse(localStorage.getItem('theme'))
    : { themeV2: { name: 'light', size: 'normal' } }));

  const [siteTheme, setSiteTheme] = React.useState(initialTheme);

  React.useEffect(() => {
    if (JSON.stringify(siteTheme) !== localStorage.getItem('theme')) {
      localStorage.setItem('theme', JSON.stringify(siteTheme));
      const newEvent = new CustomEvent('themeChangeV2');
      document.dispatchEvent(newEvent);
    }
  }, [siteTheme]);

  const change = {
    theme: (type, value) => () => {
      const origThemeV2 = siteTheme.themeV2;
      origThemeV2[type] = value;
      setSiteTheme({ ...siteTheme, themeV2: origThemeV2 });
    },
  };

  return (
    <Card className="co-card-stack">
      <div className="co-item">
        <Typography type="h1">Theme</Typography>
      </div>
      <div className="co-info co-item">
        <Radio
          checked={siteTheme.themeV2.name === 'light'}
          id="1"
          label="Light"
          value={siteTheme.themeV2.name === 'light' ? 'true' : 'false'}
          name="themename"
          onChange={change.theme('name', 'light')}
        />
        <Radio
          checked={siteTheme.themeV2.name === 'dark'}
          id="2"
          label="Dark"
          value={siteTheme.themeV2.name === 'dark' ? 'true' : 'false'}
          name="themename"
          onChange={change.theme('name', 'dark')}
        />
      </div>

      <div className="co-info co-item">
        <Radio
          checked={siteTheme.themeV2.size === 'xsmall'}
          id="4"
          label="Extra Small"
          value={siteTheme.themeV2.size === 'xsmall' ? 'true' : 'false'}
          name="themesize"
          onChange={change.theme('size', 'xsmall')}
        />
        <Radio
          checked={siteTheme.themeV2.size === 'small'}
          id="3"
          label="Small"
          value={siteTheme.themeV2.size === 'small' ? 'true' : 'false'}
          name="themesize"
          onChange={change.theme('size', 'small')}
        />
        <Radio
          checked={siteTheme.themeV2.size === 'normal'}
          id="5"
          label="Normal"
          value={siteTheme.themeV2.size === 'normal' ? 'true' : 'false'}
          name="themesize"
          onChange={change.theme('size', 'normal')}
        />
        <Radio
          checked={siteTheme.themeV2.size === 'large'}
          id="6"
          label="Large"
          value={siteTheme.themeV2.size === 'large' ? 'true' : 'false'}
          name="themesize"
          onChange={change.theme('size', 'large')}
        />
      </div>
    </Card>
  );
};

export default ChangeTheme;
