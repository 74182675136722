class Workflow {
  constructor(workflow) {
    Object.assign(this, workflow);

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }
}

export default Workflow;
