import PropTypes from 'prop-types';
import React from 'react';

import {
  Button, Card, Dropdown, Icon, Typography,
} from '@symphony-ui/uitoolkit-components';

import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';

import { actionCell } from 'utils/table';
import { change, getOptions } from 'utils/change';

import Ipo from 'models/Ipo';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';

import columns from './exchanges';
import './IpoTable.css';

const IpoTable = ({
  handle, hasError, isLoading, ipos, exchangeOptions, setExchange,
}) => {
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [data, setData] = React.useState({ exchange: null });

  const { download } = React.useContext(DownloadContext);

  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const exchangeColumns = React.useMemo(() => {
    console.log('Data: %o', data, ipos);

    const c = (data.exchange !== null
      ? [...columns[data.exchange], actionCell] : []);
    console.log('COLUMNS: %o', c);
    return c;
  });

  const onChange = change(data, setData);

  React.useEffect(() => {
    setButtonDisabled(!('exchange' in data) || data.exchange === null);
    setExchange(data.exchange);
  }, [data]);

  const click = {
    download: async () => {
      const url = new ServerURL('/bb/ipo/import');
      url.searchParams.append('exchange', data.exchange);
      const request = new ServerRequest(url, { method: 'POST' });
      const body = await download.noContent(request, 'Error updating');
      if (body) {
        console.log('Hurray it all worked');
      }
    },
  };
  return (
    <Card>
      <div className="card-header">
        <Typography type="h1">
          Releases
        </Typography>
        <div className="co-dropdown-row">
          <Button className="co-button" disabled={hasError} onClick={handle.create}>Create New</Button>
          <Dropdown id="exchange-dropdown" isDisabled={hasError} name="exchange" label="Download Ipos from Exchange" onChange={onChange.dropdown('exchange')} options={exchangeOptions} value={getOptions(data.exchange, exchangeOptions)} />
          <Button className="co-button" disabled={buttonDisabled || hasError} iconButton onClick={click.download}><Icon iconName="download" /></Button>
        </div>
      </div>

      <CustomTable
        actionsMenu={actionsMenu}
        columns={exchangeColumns}
        data={ipos || []}
        emptyMessage="You have no ipos yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

IpoTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  ipos: PropTypes.arrayOf(PropTypes.instanceOf(Ipo)),
  exchangeOptions: PropTypes.arrayOf(PropTypes.shape({})),
  setExchange: PropTypes.func.isRequired,
};

IpoTable.defaultProps = {
  exchangeOptions: [],
  hasError: false,
  isLoading: false,
  ipos: [],
};

export default IpoTable;
