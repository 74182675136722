import {
  realNumberSort, dateCell, numberCell, eqvAmountCell, actionCell,
} from '../../core/utils/table';

const parseDate = (date) => {
  const regex = /(?<month>[0-9]{2})\/(?<day>[0-9]{2})\/(?<year>[0-9]{4})$/;
  const match = date.match(regex);
  return match ? new Date(match.groups.year, match.groups.month, match.groups.day) : date;
};

const parseNumber = (number) => (number ? Number(number) : number);

const anna = {
  objectify(security) {
    return {
      ...security,
      entryDate: parseDate(security.entryDate),
      interestRate: parseNumber(security.interestRate),
      maturityDate: parseDate(security.maturityDate),
      modifiedDate: parseDate(security.modifiedDate),
      nominalValue: parseNumber(security.nominalValue),
    };
  },
  columns(currencies) {
    return [
      actionCell,
      { accessor: 'issuerNameLong', className: 'co-table-text co-table-text-l', header: 'Name' },
      { accessor: 'issueCurrency', className: 'co-centered', header: 'Currency' },
      numberCell('nominalValue', 'Amount'),
      eqvAmountCell('issueCurrency', 'nominalValue', currencies),
      dateCell('maturityDate', 'Maturity Date'),
      dateCell('entryDate', 'Entry Date'),
      {
        accessor: 'interestRate', className: 'co-table-rate', header: 'Interest Rate', sortType: realNumberSort,
      },
      { accessor: 'interestType', className: 'co-centered', header: 'Interest Type' },
      { accessor: 'instrumentCategory', className: 'co-centered', header: 'Security Type' },
      { accessor: 'issuerRegCity', header: 'City' },
      { accessor: 'issuerRegCountry', className: 'co-centered', header: 'Country' },
      { accessor: 'cfi.category', header: 'Category' },
      { accessor: 'cfi.group', className: 'co-table-text co-table-text-l', header: 'Group' },
      { accessor: 'cfi.attribute1', header: 'Attribute 1' },
      { accessor: 'cfi.attribute2', header: 'Attribute 2' },
      { accessor: 'cfi.attribute3', header: 'Attribute 3' },
      { accessor: 'cfi.attribute4', header: 'Attribute 4' },
      { accessor: 'tags', header: 'Tags' },

    ];
  },
  companyColumns: [
    actionCell,
    { accessor: 'lei', className: 'co-table-lei', header: 'LEI' },
    { accessor: 'nameLong', className: 'co-table-text co-table-text-xl', header: 'Name' },
    { accessor: 'reg.address1', header: 'Reg Address 1' },
    { accessor: 'reg.address2', header: 'Reg Address 2' },
    { accessor: 'reg.city', header: 'Reg City' },
    { accessor: 'reg.country', className: 'co-centered', header: 'Reg Country' },
    { accessor: 'reg.postalCode', header: 'Reg Postal Code' },
    { accessor: 'reg.state', header: 'Reg State' },
    { accessor: 'hq.address1', header: 'HQ Address 1' },
    { accessor: 'hq.address2', header: 'HQ Address 2' },
    { accessor: 'hq.city', header: 'HQ City' },
    { accessor: 'hq.country', className: 'co-centered', header: 'HQ Country' },
    { accessor: 'hq.postalCode', header: 'HQ Postal Code' },
    { accessor: 'hq.state', header: 'HQ State' },
    { accessor: 'nameShort', header: 'Name (short)' },

  ],
  // TODO #280 Improve companyMatcher
  companyMatcher: (security) => (c) => c.company.nameLong === security.issuerNameLong
  && c.company.reg.city === security.issuerRegCity
  && c.company.reg.country === security.issuerRegCountry
  && c.company.reg.postalCode === security.issuerRegPostalCode
  && c.company.reg.state === security.issuerRegState,
  companySortField: 'nameLong',
  fields: {
    amount: 'nominalValue',
    cfi: 'cfiCode',
    currency: 'issueCurrency',
    issuername: 'issuerNameLong',
  },
  getName: (security) => security.name,
  getCurrency: (security) => security.query.issueCurrency,
  getCreationDate: (security) => (security.query.update ? security.query.update.toString() : ''),
  getMaturity: (security) => (security.query.maturitydate ? security.query.maturitydate.toString() : ''),
  properties: [
    { label: 'Issuer', field: 'issuerNameLong', width: 36 },
    { label: 'Currency', field: 'issueCurrency' },
    { label: 'Amount', field: 'nominalValue' },
    { label: 'Maturity', field: 'maturityDate' },
    { label: 'Category', field: 'cfi.category' },
    { label: 'Group', field: 'cfi.group', width: 36 },
    { label: 'Attribute1', field: 'cfi.attribute1', width: 20 },
    { label: 'Attribute2', field: 'cfi.attribute2', width: 20 },
    { label: 'Attribute3', field: 'cfi.attribute3', width: 20 },
    { label: 'Attribute4', field: 'cfi.attribute4', width: 20 },
    { label: 'CDS Name LEI', field: 'cdsNameLei' },
    { label: 'Entry Date', field: 'entryDate' },
    { label: 'Exercise Price', field: 'exercisePrice' },
    { label: 'Exercise Price Currency', field: 'exercisePriceCurrency' },
    { label: 'FISN', field: 'fisn' },
    { label: 'Instrument Category', field: 'instrumentCategory' },
    { label: 'Interest First Payment Date', field: 'interestFirstPaymentDate' },
    { label: 'Interest Frequency', field: 'interestFrequency' },
    { label: 'Interest Rate', field: 'interestRate' },
    { label: 'Interest Type', field: 'interestType' },
    { label: 'ISIN', field: 'isin' },
    { label: 'Issue Description', field: 'issueDescription' },
    { label: 'HQ City', field: 'issuerHqCity' },
    { label: 'HQ Country', field: 'issuerHqCountry' },
    { label: 'HQ Postal Code', field: 'issuerHqPostalCode' },
    { label: 'LEI', field: 'issuerLei' },
    { label: 'Issuer Name Short', field: 'issuerNameShort' },
    { label: 'Reg Address 1', field: 'issuerRegAddress1' },
    { label: 'Reg Address 2', field: 'issuerRegAddress2' },
    { label: 'Reg City', field: 'issuerRegCity' },
    { label: 'Reg Country', field: 'issuerRegCountry' },
    { label: 'Reg Postal COde', field: 'issuerRegPostalCode' },
    { label: 'Reg State', field: 'issuerRegState' },
    { label: 'Maturity Code', field: 'maturityCode' },
    { label: 'MIC', field: 'mic' },
    { label: 'Modified Date', field: 'modifiedDate' },
    { label: 'Operator', field: 'operator' },
    { label: 'Status', field: 'status' },

  ],
};

export default anna;
