import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import ViewContext from 'contexts/ViewContext';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import { getOptions } from 'utils/change';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import CommentsTable from '../components/details/CommentsTable';
import ApprovalsTable from '../components/details/ApprovalsTable';
import Workflow from '../models/Workflow';

import './Details.css';

const Details = ({ details, handle }) => {
  const { setView } = React.useContext(ViewContext);

  const [options, setOptions] = React.useState([]);
  const [draftIndex, setDraftIndex] = React.useState(null);
  const tagOptions = [...new Set(details.drafts.flatMap((d) => d.comments).flatMap((c) => c.tags))];

  const onChange = (event) => {
    const newValue = event.target.value.value;
    setDraftIndex(newValue);
  };

  React.useEffect(() => {
    const list = details.drafts.map((value, index) => ({ label: value.version, value: index }));
    setOptions(list);
    setDraftIndex(list.length - 1);
  }, [details]);

  return (
    <Card className="co-details">
      <CardHeaderForm title={details.name} />
      <div className="co-item">
        <Dropdown
          label="Version"
          name="period"
          onChange={onChange}
          options={options}
          value={getOptions(draftIndex, options)}
        />
      </div>

      <div className="co-item">
        <Typography type="h2">Approvals</Typography>
        <ApprovalsTable
          approvals={draftIndex !== null ? details.drafts[draftIndex].approvals : []}
          handle={handle}
          isLatest={draftIndex === options.length - 1}
          parties={details.parties}
        />
      </div>

      <div className="co-item">
        <Typography type="h2">Comments</Typography>
        <CommentsTable
          comments={draftIndex !== null ? details.drafts[draftIndex].comments : []}
          tagOptions={tagOptions}
          updateTags={handle.updateTags}
        />
      </div>

      <div className="co-info co-item">
        <div>
          <Typography type="body" variant="bold">Created by:</Typography>
          <Typography type="body">
            {details.createdBy !== null ? details.createdBy.displayName : 'n/a'}
          </Typography>
        </div>
        <div>
          <Typography type="body" variant="bold">Created date: </Typography>
          <Typography type="body">
            {details.createdDate !== null ? new Date(details.createdDate).toLocaleString() : 'n/a'}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

Details.propTypes = {
  details: PropTypes.instanceOf(Workflow).isRequired,
  handle: PropTypes.shape({
    amend: PropTypes.func,
    updateTags: PropTypes.func,
  }).isRequired,
};

export default Details;
