export const initialValidation = async (validators, data) => {
  const promises = Object.keys(validators).map((name) => (async () => {
    const value = data[name] || '';
    const validator = validators[name];
    let hasError;

    if (validator === null) {
      hasError = false;
    } else if (validator instanceof Array) {
      const validationResults = await Promise.all(validator.map((v) => v(value)));
      hasError = validationResults.some((r) => r !== null);
    } else {
      console.log('Validating: %o = %o', value);
      const validationResults = await validator(value);
      console.log('VALIDATION RESULTS FOR %o: %o = %o', name, value, validationResults);
      hasError = validationResults !== null;
    }
    return { name, hasError };
  })());

  const validationArray = await Promise.all(promises);
  const newErrorObject = {};
  validationArray.forEach((validationObject) => {
    console.log('GOT A NEW VALIDATION OBJECT: %o', validationObject);
    newErrorObject[validationObject.name] = !validationObject.hasError;
  });
  return newErrorObject;
};

export const errorMessage = {
  emailAddress: 'Please enter a valid email address',
  minlength: 'Input is too short',
  number: 'Please input a number',
  passwordLength: 'between 8 and 16 characters',
  passwordLetters: 'one lower case and one upper case character',
  passwordNumbers: 'at least one number',
  pattern: 'This is not the right pattern',
  required: 'This input is required',
};

export const checkDisabled = (setButtonDisabled, errorState) => {
  const disabled = Object.values(errorState).some((v) => v === false);
  setButtonDisabled(disabled);
};

export const hasError = (errorState) => Object.values(errorState).some((v) => v === false);
