import PropTypes from 'prop-types';
import React from 'react';

import {
  Button, Card, Dropdown, Icon, Typography,
} from '@symphony-ui/uitoolkit-components';

import CustomTable from 'components/custom-table/CustomTable';
import DownloadContext from 'contexts/DownloadContext';
import Release from 'models/Release';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import { change, getOptions } from 'utils/change';
import { actionCell } from 'utils/table';

import releaseSources from './releaseSources';
import './ReleaseTable.css';

const ReleaseTable = ({
  handle, hasError, isLoading, releases, sourceOptions, setSource,
}) => {
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [data, setData] = React.useState({ source: null });

  const { download } = React.useContext(DownloadContext);

  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const columns = React.useMemo(() => (data.source !== null
    ? [...releaseSources[data.source], actionCell] : []));

  const onChange = change(data, setData);

  React.useEffect(() => {
    setButtonDisabled(!('source' in data) || data.source === null);
    setSource(data.source);
  }, [data]);

  const click = {
    download: async () => {
      const url = new ServerURL('/bb/release/import');
      url.searchParams.append('source', data.source);
      const request = new ServerRequest(url, { method: 'POST' });
      const body = await download.noContent(request, 'Error updating');
      if (body) {
        console.log('Hurray it all worked');
      }
    },
  };

  return (
    <Card>
      <div className="card-header">
        <Typography type="h1">
          Releases
        </Typography>
        <div className="co-dropdown-row">
          <Button className="co-button" disabled={hasError} onClick={handle.create}>Create New</Button>
          <Dropdown isDisabled={hasError} id="source-dropdown" name="source" label="Download Releases from Source" onChange={onChange.dropdown('source')} options={sourceOptions} value={getOptions(data.source, sourceOptions)} />
          <Button className="co-button" disabled={buttonDisabled} iconButton onClick={click.download}><Icon iconName="download" /></Button>
        </div>
      </div>

      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={releases}
        emptyMessage="You have no releases yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

ReleaseTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  releases: PropTypes.arrayOf(PropTypes.instanceOf(Release)),
  setSource: PropTypes.func.isRequired,
  sourceOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ReleaseTable.defaultProps = {
  hasError: false,
  isLoading: false,
  releases: [],
  sourceOptions: [],
};

export default ReleaseTable;
