class Ipo {
  constructor(ipo) {
    Object.assign(this, ipo);

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }
}

export default Ipo;
