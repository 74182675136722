import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import TagInput from 'components/tag-input/TagInput';
import Comment from '../../models/Comment';

const TagModal = ({
  comment, hideModal, tagOptions, updateTags,
}) => {
  const [data, setData] = React.useState(comment);

  const clickButton = async () => {
    await updateTags(comment);
    hideModal();
  };

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  return (
    <Modal className="co-items" show closeButton onClose={hideModal} parentNode={document.body}>
      <div>
        <Typography type="h1">Tag Comment</Typography>
      </div>
      <div>
        <TagInput
          label=""
          name="text"
          onChange={change.input}
          tagOptions={tagOptions}
          tags={data.tags}
        />
      </div>
      <div>
        <Button onClick={clickButton}>Save</Button>
      </div>
    </Modal>
  );
};

TagModal.propTypes = {
  comment: PropTypes.instanceOf(Comment).isRequired,
  hideModal: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateTags: PropTypes.func.isRequired,
};

export default TagModal;
