import Search from '../../models/Search';

export const searchChange = (search, setSearch) => ({
  input: (name) => (event) => {
    const updatedSearch = search.updateInput(name, event.target.value);
    setSearch(updatedSearch);
  },
  filter: (event) => {
    const { name, value } = event.target;
    const updatedSearch = search.updateFilter(name, value);
    setSearch(updatedSearch);
  },
  permissions(event) {
    setSearch(new Search({ ...search, permissions: event }));
  },
  queryInput: (event) => {
    const { name, value } = event.target;
    const updatedSearch = search.updateQuery(name, value);
    setSearch(updatedSearch);
  },
  query: (name) => (event) => {
    const { value } = event.target;
    let newValue;
    if (value === null) {
      newValue = null;
    } else if (Array.isArray(value)) {
      newValue = value.map((v) => v.value);
    } else if ('value' in value) {
      newValue = value.value;
    }
    const updatedSearch = search.updateQuery(name, newValue);
    setSearch(updatedSearch);
  },
});

export const b = '';
