import sources from '../sources/sources';
import cfiParser from './cfiParser';

const companyTags = (source, companies, security) => {
  const company = companies.find(sources[source].companyMatcher(security));
  return company ? company.tags : [];
};

const sort = (source) => (s0, s1) => {
  if (s0[sources[source].fields.issuername] < s1[sources[source].fields.issuername]) {
    return -1;
  } if (s0[sources[source].fields.issuername] > s1[sources[source].fields.issuername]) {
    return 1;
  }
  return 0;
};

const markupSecurities = (source, securities, companies) => securities
  .sort(sort(source))
  .map((s) => ({
    ...s,
    cfi: sources[source].fields.cfi ? cfiParser(s[sources[source].fields.cfi]) : null,
    tags: companies.length ? companyTags(source, companies, s) : [],
  }))
  .map(sources[source].markupMap ? sources[source].markupMap(companies) : (s) => (s));

export default markupSecurities;
