/* eslint-disable sort-vars */
/* eslint-disable max-lines */

const

  /* Collective Investment Vehicles Attributes */
  civAssets = {
    name: 'Assets',
    values: [
      {
        code: 'R',
        name: 'Real Estate',
      },
      {
        code: 'B',
        name: 'Debt Instruments',
      },
      {
        code: 'E',
        name: 'Equities',
      },
      {
        code: 'V',
        name: 'Convertible Securities',
      },
      {
        code: 'L',
        name: 'Mixed',
      },
      {
        code: 'C',
        name: 'Commodities',
      },
      {
        code: 'D',
        name: 'Derivatives',
      },
      {
        code: 'F',
        name: 'Referential Instruments',
      },
      {
        code: 'K',
        name: 'Credits',
      },
      {
        code: 'M',
        name: 'Others',
      },
    ],
  };
const civClosedOpenEnd = {
  name: 'Closed/Open-End',
  values: [
    {
      code: 'C',
      name: 'Closed-End',
    },
    {
      code: 'O',
      name: 'Open-End',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const civDistributionPolicy = {
  name: 'Distribution Policy',
  values: [
    {
      code: 'I',
      name: 'Income Funds',
    },
    {
      code: 'G',
      name: 'Accumulation Funds',
    },
    {
      code: 'J',
      name: 'Mixed Funds',
    },
  ],
};
const civInvestmentStrategy = {
  name: 'Investment Strategy',
  values: [
    {
      code: 'D',
      name: 'Directional',
    },
    {
      code: 'R',
      name: 'Relative Value',
    },
    {
      code: 'S',
      name: 'Security Selection',
    },
    {
      code: 'E',
      name: 'Event-Driven',
    },
    {
      code: 'A',
      name: 'Arbitrage',
    },
    {
      code: 'N',
      name: 'Multi-Strategy',
    },
    {
      code: 'L',
      name: 'Asset Based Lending',
    },
    {
      code: 'M',
      name: 'Others',
    },
  ],
};
const civNA = {
  name: 'NA',
  values: [
    {
      code: 'X',
      name: 'Not Applicable/Undefined',
    },
  ],
};
const civSecurityType = {
  name: 'Security Type',
  values: [
    {
      code: 'S',
      name: 'Shares',
    },
    {
      code: 'U',
      name: 'Units',
    },
  ],
};
const civSecurityTypeInvestorsRestrictions = {
  name: 'Security Type and Investors Restrictions',
  values: [
    {
      code: 'S',
      name: 'Shares',
    },
    {
      code: 'Q',
      name: 'Shares for QI',
    },
    {
      code: 'U',
      name: 'Units',
    },
    {
      code: 'Y',
      name: 'Units for QI',
    },
  ],
};
const civStrategyStyle = {
  name: 'Strategy/Style',
  values: [
    {
      code: 'B',
      name: 'Balanced/Conservative',
    },
    {
      code: 'G',
      name: 'Growth',
    },
    {
      code: 'L',
      name: 'Life Style',
    },
    {
      code: 'M',
      name: 'Others',
    },
  ],
};
const civType = {
  name: 'Type',
  values: [
    {
      code: 'R',
      name: 'Defined Benefit',
    },
    {
      code: 'B',
      name: 'Defined Contribution',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    },
  ],
};
const civTypeOfFunds = {
  name: 'Type of Funds',
  values: [
    {
      code: 'I',
      name: 'Standard (Vanilla)',
    },
    {
      code: 'H',
      name: 'Hedge Funds',
    },
    {
      code: 'B',
      name: 'REIT',
    },
    {
      code: 'E',
      name: 'ETF',
    },
    {
      code: 'P',
      name: 'Private Equity Funds',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    },
  ],
};

/* Debt Attributes */
const debtForm = {
  name: 'Form',
  values: [
    {
      code: 'B',
      name: 'Bearer',
    },
    {
      code: 'R',
      name: 'Registered',
    },
    {
      code: 'N',
      name: 'Bearer/Registered',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtGuaranteeOrRanking = {
  name: 'Guarantee or Ranking',
  values: [
    {
      code: 'T',
      name: 'Government/State Guarantee',
    },
    {
      code: 'G',
      name: 'Joint Guarantee',
    },
    {
      code: 'S',
      name: 'Secured',
    },
    {
      code: 'U',
      name: 'Unsecured/Unguaranteed',
    },
    {
      code: 'P',
      name: 'Negative Pledge',
    },
    {
      code: 'N',
      name: 'Senior',
    },
    {
      code: 'O',
      name: 'Senior Subordinated',
    },
    {
      code: 'Q',
      name: 'Junior',
    },
    {
      code: 'J',
      name: 'Junior Subordinated',
    },
    {
      code: 'C',
      name: 'Supranational',
    },
  ],
};
const debtInstrumentDependency = {
  name: 'Instrument Dependency',
  values: [
    {
      code: 'B',
      name: 'Bonds',
    },
    {
      code: 'C',
      name: 'Convertible Bonds',
    },
    {
      code: 'W',
      name: 'Bonds with Warrants Attached',
    },
    {
      code: 'T',
      name: 'Medium-Term Notes',
    },
    {
      code: 'Y',
      name: 'Money Market Instruments',
    },
    {
      code: 'G',
      name: 'Mortgage-Backed Securities',
    },
    {
      code: 'A',
      name: 'Asset-Backed Securities',
    },
    {
      code: 'N',
      name: 'Municipal Bonds',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtNA = civNA;
const debtRedemptionReimbursement = {
  name: 'Redemption/Reimbursement',
  values: [
    {
      code: 'F',
      name: 'Fixed Maturity',
    },
    {
      code: 'G',
      name: 'Fixed Maturity with Call Feature',
    },
    {
      code: 'C',
      name: 'Fixed Maturity with Put Feature',
    },
    {
      code: 'D',
      name: 'Fixed Maturity with Put and Call',
    },
    {
      code: 'A',
      name: 'Amortization Plan',
    },
    {
      code: 'B',
      name: 'Amortization Plan with Call Feature',
    },
    {
      code: 'T',
      name: 'Amortization Plan with Put Feature',
    },
    {
      code: 'L',
      name: 'Amortization Plan with Put and Call',
    },
    {
      code: 'P',
      name: 'Perpetual',
    },
    {
      code: 'Q',
      name: 'Perpetual with Call Feature',
    },
    {
      code: 'R',
      name: 'Perpetual with Put Feature',
    },
  ],
};
const debtRedemptionReimbursementExt = {
  name: 'Redemption/Reimbursement',
  values: [
    {
      code: 'F',
      name: 'Fixed Maturity',
    },
    {
      code: 'G',
      name: 'Fixed Maturity with Call Feature',
    },
    {
      code: 'C',
      name: 'Fixed Maturity with Put Feature',
    },
    {
      code: 'D',
      name: 'Fixed Maturity with Put and Call',
    },
    {
      code: 'A',
      name: 'Amortization Plan',
    },
    {
      code: 'B',
      name: 'Amortization Plan with Call Feature',
    },
    {
      code: 'T',
      name: 'Amortization Plan with Put Feature',
    },
    {
      code: 'L',
      name: 'Amortization Plan with Put and Call',
    },
    {
      code: 'P',
      name: 'Perpetual',
    },
    {
      code: 'Q',
      name: 'Perpetual with Call Feature',
    },
    {
      code: 'R',
      name: 'Perpetual with Put Feature',
    },
    {
      code: 'E',
      name: 'Extendible',
    },
  ],
};
const debtTypeOfInterest = {
  name: 'Type of Interest',
  values: [
    {
      code: 'F',
      name: 'Fixed Rate',
    },
    {
      code: 'Z',
      name: 'Zero Rate/Discounted',
    },
    {
      code: 'V',
      name: 'Variable',
    },
  ],
};
const debtTypeOfInterestAlt1 = {
  name: 'Type of Interest',
  values: [
    {
      code: 'F',
      name: 'Fixed Rate',
    },
    {
      code: 'Z',
      name: 'Zero Rate/Discounted',
    },
    {
      code: 'V',
      name: 'Variable',
    },
    {
      code: 'K',
      name: 'Payment in Kind',
    },
  ],
};
const debtTypeOfInterestAlt2 = {
  name: 'Type of Interest',
  values: [
    {
      code: 'F',
      name: 'Fixed Rate',
    },
    {
      code: 'Z',
      name: 'Zero Rate/Discounted',
    },
    {
      code: 'V',
      name: 'Variable',
    },
    {
      code: 'C',
      name: 'Cash Payment',
    },
  ],
};
const debtTypeOfInterestOrCashPayment = {
  name: 'Type of Interest or Cash Payment',
  values: [
    {
      code: 'F',
      name: 'Fixed Rate',
    },
    {
      code: 'Z',
      name: 'Zero Rate/Discounted',
    },
    {
      code: 'V',
      name: 'Variable',
    },
    {
      code: 'C',
      name: 'Cash Payment',
    },
    {
      code: 'K',
      name: 'Payment in Kind',
    },
  ],
};
const debtTypeWithCapitalProtection = {
  name: 'Type',
  values: [
    {
      code: 'A',
      name: 'Capital Protection Certificate with Participation',
    },
    {
      code: 'B',
      name: 'Capital Protection Convertible Certificate',
    },
    {
      code: 'C',
      name: 'Barrier Capital Protection Certificate',
    },
    {
      code: 'D',
      name: 'Capital Protection Certificate with Coupons',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtTypeWithoutCapitalProtection = {
  name: 'Type',
  values: [
    {
      code: 'A',
      name: 'Discount Certificate',
    },
    {
      code: 'B',
      name: 'Barrier Discount Certificate',
    },
    {
      code: 'C',
      name: 'Reverse Convertible',
    },
    {
      code: 'D',
      name: 'Barrier Reverse Convertible',
    },
    {
      code: 'E',
      name: 'Express Certificate',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtTypeMisc = {
  name: 'Type',
  values: [
    {
      code: 'B',
      name: 'Bank Loan',
    },
    {
      code: 'P',
      name: 'Promissory Note',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtDistribution = {
  name: 'Distribution',
  values: [
    {
      code: 'F',
      name: 'Fixed Interest Payments',
    },
    {
      code: 'D',
      name: 'Dividend Payments',
    },
    {
      code: 'V',
      name: 'Variable Interest Payments',
    },
    {
      code: 'Y',
      name: 'No Payments',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtRepaymentWithCapitalProtection = {
  name: 'Repayment',
  values: [
    {
      code: 'F',
      name: 'Fixed Cash Repayment (Only Protected Capital Level)',
    },
    {
      code: 'V',
      name: 'Variable Cash Repayment',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtRepaymentWithoutCapitalProtection = {
  name: 'Repayment',
  values: [
    {
      code: 'R',
      name: 'Repayment in Cash',
    },
    {
      code: 'S',
      name: 'Repayment in Assets',
    },
    {
      code: 'C',
      name: 'Repayment in Assets and Cash',
    },
    {
      code: 'T',
      name: 'Repayment in Assets or Cash',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const debtUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'B',
      name: 'Baskets',
    },
    {
      code: 'S',
      name: 'Equities',
    },
    {
      code: 'D',
      name: 'Debt Instruments',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies (Specified Exchange Rate)',
    },
    {
      code: 'I',
      name: 'Indices (The Performance of an Index)',
    },
    {
      code: 'N',
      name: 'Interest Rates',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};

/* Equities Attributes */

const equitiesDistribution = {
  name: 'Distribution',
  values: [
    {
      code: 'D',
      name: 'Dividend Payments',
    },
    {
      code: 'Y',
      name: 'No Payments',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const equitiesForm = debtForm;
const equitiesIncome = {
  name: 'Income',
  values: [
    {
      code: 'F',
      name: 'Fixed Rate Income',
    },
    {
      code: 'C',
      name: 'Cumulative, Fixed Rate Income',
    },
    {
      code: 'P',
      name: 'Participating Income',
    },
    {
      code: 'Q',
      name: 'Cumulative, Participating Income',
    },
    {
      code: 'A',
      name: 'Adjustable/Variable Rate Income',
    },
    {
      code: 'N',
      name: 'Normal Rate Income',
    },
    {
      code: 'U',
      name: 'Auction Rate Income',
    },
    {
      code: 'D',
      name: 'Dividends',
    },
  ],
};
const equitiesInstrumentDependency = {
  name: 'Instrument Dependency',
  values: [
    {
      code: 'S',
      name: 'Common/Ordinary Shares',
    },
    {
      code: 'P',
      name: 'Preferred/Preference Shares',
    },
    {
      code: 'C',
      name: 'Common/Ordinary Convertible Shares',
    },
    {
      code: 'F',
      name: 'Preferred/Preference Convertible Shares',
    },
    {
      code: 'L',
      name: 'Limited Partnership Units',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const equitiesNA = debtNA;
const equitiesOwnership = {
  name: 'Ownership/Transfer/Sales Restrictions',
  values: [
    {
      code: 'T',
      name: 'Restrictions',
    },
    {
      code: 'U',
      name: 'Free(Unrestricted)',
    },
  ],
};
const equitiesPaymentStatus = {
  name: 'Payment Status',
  values: [
    {
      code: 'O',
      name: 'Nil Paid',
    },
    {
      code: 'P',
      name: 'Partly Paid',
    },
    {
      code: 'F',
      name: 'Fully Paid',
    },
  ],
};
const equitiesRedemption = {
  name: 'Redemption',
  values: [
    {
      code: 'R',
      name: 'Redeemable',
    },
    {
      code: 'E',
      name: 'Extendible',
    },
    {
      code: 'T',
      name: 'Redeemable/Extendible',
    },
    {
      code: 'G',
      name: 'Exchangeable',
    },
    {
      code: 'A',
      name: 'Redeemable/Exchangeable/Extendible',
    },
    {
      code: 'C',
      name: 'Redeemable/Exchangeable',
    },
    {
      code: 'N',
      name: 'Perpetual',
    },
  ],
};
const equitiesRedemptionAssets = {
  name: 'Redemption/Conversion of the Underlying Assets',
  values: [
    {
      code: 'R',
      name: 'Redeemable',
    },
    {
      code: 'N',
      name: 'Perpetual',
    },
    {
      code: 'B',
      name: 'Convertible',
    },
    {
      code: 'D',
      name: 'Convertible/Redeemable',
    },
    {
      code: 'X',
      name: 'Not Applicable/Undefined',
    },
  ],
};
const equitiesRepayment = {
  name: 'Repayment',
  values: [
    {
      code: 'F',
      name: 'Cash Repayment',
    },
    {
      code: 'V',
      name: 'Physical Repayment',
    },
    {
      code: 'E',
      name: 'Elect at Settlement',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const equitiesType = {
  name: 'Type',
  values: [
    {
      code: 'A',
      name: 'Tracker Certificate',
    },
    {
      code: 'B',
      name: 'Outperforming Certificate',
    },
    {
      code: 'C',
      name: 'Bonus Certificate',
    },
    {
      code: 'D',
      name: 'Outperformance Bonus Certificate',
    },
    {
      code: 'E',
      name: 'Twin-Win-Certificate',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const equitiesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'B',
      name: 'Baskets',
    },
    {
      code: 'S',
      name: 'Equities',
    },
    {
      code: 'D',
      name: 'Debt Instruments',
    },
    {
      code: 'G',
      name: 'Derivatives',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'I',
      name: 'Indices',
    },
    {
      code: 'N',
      name: 'Interest Rates',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const equitiesVotingRights = {
  name: 'Voting Rights',
  values: [
    {
      code: 'V',
      name: 'Voting',
    },
    {
      code: 'N',
      name: 'Non Voting',
    },
    {
      code: 'R',
      name: 'Restricted Voting',
    },
    {
      code: 'E',
      name: 'Enhanced Voting',
    },
  ],
};

/* Entitlements (Rights) */
const entitlementsNA = equitiesNA;
const entitlementsForm = equitiesForm;
const entitlementsAssets = {
  name: 'Assets',
  values: [
    {
      code: 'S',
      name: 'Common/Ordinary Shares',
    },
    {
      code: 'P',
      name: 'Preferred/Preference Shares',
    },
    {
      code: 'C',
      name: 'Common/Ordinary Convertible Shares',
    },
    {
      code: 'F',
      name: 'Preferred/Preference Convertible Shares',
    },
    {
      code: 'B',
      name: 'Bonds',
    },
    {
      code: 'I',
      name: 'Combined Instruments',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    },
  ],
};
const entitlementsUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'B',
      name: 'Baskets',
    },
    {
      code: 'S',
      name: 'Equities',
    },
    {
      code: 'D',
      name: 'Debt Instruments/Interest Rates',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'I',
      name: 'Indices',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const entitlementsType = {
  name: 'Type',
  values: [
    {
      code: 'T',
      name: 'Traditional Warrants',
    },
    {
      code: 'N',
      name: 'Naked Warrants',
    },
    {
      code: 'C',
      name: 'Covered Warrants',
    },
  ],
};
const entitlementsCallPut = {
  name: 'Call/Put',
  values: [
    {
      code: 'C',
      name: 'Call',
    },
    {
      code: 'P',
      name: 'Put',
    },
    {
      code: 'B',
      name: 'Call and Put',
    },
  ],
};
const entitlementsExerciseOptionStyle = {
  name: 'Exercise Option Style',
  values: [
    {
      code: 'E',
      name: 'European',
    },
    {
      code: 'A',
      name: 'American',
    },
    {
      code: 'B',
      name: 'Bermudan',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const entitlementsBarrierDependencyType = {
  name: 'Barrier Dependency Type',
  values: [
    {
      code: 'T',
      name: 'Barrier Underlying Based',
    },
    {
      code: 'N',
      name: 'Barrier Instrument Based',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const entitlementsLongShort = {
  name: 'Long/Short',
  values: [
    {
      code: 'C',
      name: 'Long',
    },
    {
      code: 'P',
      name: 'Short',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const entitlementsInstrumentDependency = {
  name: 'Instrument Dependency',
  values: [
    {
      code: 'A',
      name: 'Allotment (Bonus) Rights',
    },
    {
      code: 'S',
      name: 'Subscription Rights',
    },
    {
      code: 'P',
      name: 'Purchase Rights',
    },
    {
      code: 'W',
      name: 'Warrants',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};

/* Listed Options */
const listedNA = equitiesNA;
const listedExerciseOptionStyle = {
  name: 'Exercise Option Style',
  values: [
    {
      code: 'E',
      name: 'European',
    },
    {
      code: 'A',
      name: 'American',
    },
    {
      code: 'B',
      name: 'Bermudan',
    },
  ],
};
const listedUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'B',
      name: 'Baskets',
    },
    {
      code: 'S',
      name: 'Stock-Equities',
    },
    {
      code: 'D',
      name: 'Debt Instruments',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'I',
      name: 'Indices',
    },
    {
      code: 'O',
      name: 'Options',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'W',
      name: 'Swaps',
    },
    {
      code: 'N',
      name: 'Interest Rates',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const listedDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'N',
      name: 'Non-Deliverable',
    },
    {
      code: 'E',
      name: 'Elect at Exercise',
    },
  ],
};
const listedStandardizedNonStandardized = {
  name: 'Standardized/Non-Standardized',
  values: [
    {
      code: 'S',
      name: 'Standardized',
    },
    {
      code: 'N',
      name: 'Non-Standardized',
    },
  ],
};

/* Futures */
const futuresNA = equitiesNA;
const futuresStandardizedNonStandardized = listedStandardizedNonStandardized;
const futuresFinancialUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'B',
      name: 'Baskets',
    },
    {
      code: 'S',
      name: 'Stock-Equities',
    },
    {
      code: 'D',
      name: 'Debt Instruments',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'I',
      name: 'Indices',
    },
    {
      code: 'O',
      name: 'Options',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'W',
      name: 'Swaps',
    },
    {
      code: 'N',
      name: 'Interest Rates',
    },
    {
      code: 'V',
      name: 'Stock Dividend',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const futuresDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'N',
      name: 'Non-Deliverable',
    },
  ],
};
const futuresCommondotiesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'E',
      name: 'Extraction Resources',
    },
    {
      code: 'A',
      name: 'Agriculture',
    },
    {
      code: 'I',
      name: 'Industrial Products',
    },
    {
      code: 'S',
      name: 'Services',
    },
    {
      code: 'N',
      name: 'Environmental',
    },
    {
      code: 'P',
      name: 'Polypropylene Products',
    },
    {
      code: 'H',
      name: 'Generated Resources',
    },
    {
      code: 'M',
      name: 'Others',
    },
  ],
};

/* Referential Instruments */
const referentialNA = equitiesNA;
const referentialTypeCurrency = {
  name: 'Type',
  values: [
    {
      code: 'N',
      name: 'National Currency',
    },
    {
      code: 'L',
      name: 'Legacy Currency',
    },
    {
      code: 'C',
      name: 'Bullion Coins',
    },
    {
      code: 'M',
      name: 'Others',
    },
  ],
};
const referentialTypeCommodities = {
  name: 'Type',
  values: [
    {
      code: 'E',
      name: 'Extraction Resources',
    },
    {
      code: 'A',
      name: 'Agriculture',
    },
    {
      code: 'I',
      name: 'Industrial Products',
    },
    {
      code: 'S',
      name: 'Services',
    },
    {
      code: 'N',
      name: 'Environmental',
    },
    {
      code: 'P',
      name: 'Polypropylene Products',
    },
    {
      code: 'H',
      name: 'Generated Resources',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialTypeInterest = {
  name: 'Type',
  values: [
    {
      code: 'N',
      name: 'Nominal',
    },
    {
      code: 'V',
      name: 'Variable',
    },
    {
      code: 'F',
      name: 'Fixed',
    },
    {
      code: 'R',
      name: 'Real',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialFrequency = {
  name: 'Frequency',
  values: [
    {
      code: 'D',
      name: 'Daily',
    },
    {
      code: 'W',
      name: 'Weekly',
    },
    {
      code: 'M',
      name: 'Monthly',
    },
    {
      code: 'Q',
      name: 'Quarterly',
    },
    {
      code: 'S',
      name: 'Semi-Annually',
    },
    {
      code: 'A',
      name: 'Annually',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialAssetClasses = {
  name: 'Asset Classes',
  values: [
    {
      code: 'E',
      name: 'Equities',
    },
    {
      code: 'D',
      name: 'Debt',
    },
    {
      code: 'F',
      name: 'Collective Investment Vehicles',
    },
    {
      code: 'R',
      name: 'Real Estate',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialWeightingTypes = {
  name: 'Weighting Types',
  values: [
    {
      code: 'P',
      name: 'Price Weighted',
    },
    {
      code: 'C',
      name: 'Capitalization Weighted',
    },
    {
      code: 'E',
      name: 'Equal Weighted',
    },
    {
      code: 'F',
      name: 'Modified Market Capitalization Weighted',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialIndexReturnTypes = {
  name: 'Index Return Types',
  values: [
    {
      code: 'P',
      name: 'Price Return',
    },
    {
      code: 'N',
      name: 'Net Total Return',
    },
    {
      code: 'G',
      name: 'Gross Total Return',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialComposition = {
  name: 'Composition',
  values: [
    {
      code: 'E',
      name: 'Equities',
    },
    {
      code: 'D',
      name: 'Debt',
    },
    {
      code: 'F',
      name: 'Collective Investment Vehicles',
    },
    {
      code: 'I',
      name: 'Indices',
    },
    {
      code: 'T',
      name: 'Commodities',
    },
    {
      code: 'C',
      name: 'Currencies',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const referentialTypeEquity = {
  name: 'Type of Equity',
  values: [
    {
      code: 'S',
      name: 'Common Ordinary Shares',
    },
    {
      code: 'P',
      name: 'Preferred/Preference Shares',
    },
    {
      code: 'C',
      name: 'Common Ordinary Convertible Shares',
    },
    {
      code: 'F',
      name: 'Preferred/Preference Convertible Shares',
    },
    {
      code: 'L',
      name: 'Limited Partnership Units',
    },
    {
      code: 'K',
      name: 'Collective Investment Vehicles',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};

/* Other (Miscellaneous) */
const otherForm = equitiesForm;
const otherNA = equitiesNA;
const otherComponents = {
  name: 'Components',
  values: [
    {
      code: 'S',
      name: 'Combination of Shares',
    },
    {
      code: 'B',
      name: 'Combination of Bonds',
    },
    {
      code: 'H',
      name: 'Share and Bond',
    },
    {
      code: 'A',
      name: 'Share and Warrant',
    },
    {
      code: 'W',
      name: 'Warrant and Warrant',
    },
    {
      code: 'U',
      name: 'Fund Unit and Other Components',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};
const otherOwnershipTransferSalesRestrictions = {
  name: 'Ownership/Transfer/Sales Restrictions',
  values: [
    {
      code: 'T',
      name: 'Restriction',
    },
    {
      code: 'U',
      name: 'Free(Unrestricted)',
    },
  ],
};
const otherFurtherGrouping = {
  name: 'Further Grouping',
  values: [
    {
      code: 'R',
      name: 'Real Estate Deeds',
    },
    {
      code: 'I',
      name: 'Insurance Policies',
    },
    {
      code: 'E',
      name: 'Escrow Receipts',
    },
    {
      code: 'T',
      name: 'Trade Finance Instruments',
    },
    {
      code: 'N',
      name: 'Carbon Credit',
    },
    {
      code: 'P',
      name: 'Precious Metal Receipts',
    },
    {
      code: 'S',
      name: 'Other OTC Derivative Products',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    },
  ],
};

/* Attributes Swaps */

const swapsRatesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'A',
      name: 'Basis Swap',
    },
    {
      code: 'C',
      name: 'Fixed-Floating',
    },
    {
      code: 'D',
      name: 'Fixed-Fixed',
    },
    {
      code: 'G',
      name: 'Inflation Rate Index',
    },
    {
      code: 'H',
      name: 'Overnight Index Swap (OIS)',
    },
    {
      code: 'Z',
      name: 'Zero Coupon',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    }],
};

const swapsNotional = {
  name: 'Notional',
  values: [
    {
      code: 'C',
      name: 'Constant',
    },
    {
      code: 'D',
      name: 'Accreting',
    },
    {
      code: 'I',
      name: 'Amortizing',
    },
    {
      code: 'Y',
      name: 'Custom',
    }],
};

const swapsSingleOrMultiCurrency = {
  name: 'Single or Multi-Currency',
  values: [
    {
      code: 'S',
      name: 'Single-Currency',
    },
    {
      code: 'C',
      name: 'Cross-Currency',
    }],
};

const swapsRatesDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    }],
};

const swapsCommoditiesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'J',
      name: 'Energy',
    },
    {
      code: 'K',
      name: 'Metals',
    },
    {
      code: 'A',
      name: 'Agriculture',
    },
    {
      code: 'N',
      name: 'Environmental',
    },
    {
      code: 'G',
      name: 'Freight',
    },
    {
      code: 'P',
      name: 'Polypropylene Products',
    },
    {
      code: 'S',
      name: 'Fertilizer',
    },
    {
      code: 'T',
      name: 'Paper',
    },
    {
      code: 'I',
      name: 'Index',
    },
    {
      code: 'Q',
      name: 'Multi-Commodity',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsReturnOrPayoutTrigger = {
  name: 'Return or Payout Trigger',
  values: [
    {
      code: 'P',
      name: 'Price',
    },
    {
      code: 'D',
      name: 'Dividend',
    },
    {
      code: 'V',
      name: 'Variance',
    },
    {
      code: 'L',
      name: 'Volatility',
    },
    {
      code: 'T',
      name: 'Total Return',
    },
    {
      code: 'C',
      name: 'Contract for Difference',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'E',
      name: 'Elect at Settlement',
    }],
};

const swapsEquityUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'S',
      name: 'Single Stock',
    },
    {
      code: 'I',
      name: 'Index',
    },
    {
      code: 'B',
      name: 'Basket',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsCreditUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'S',
      name: 'Single Name',
    },
    {
      code: 'V',
      name: 'Index Tranche',
    },
    {
      code: 'I',
      name: 'Index',
    },
    {
      code: 'B',
      name: 'Basket',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsCreditReturnOrPayoutTrigger = {
  name: 'Return or Payout Trigger',
  values: [
    {
      code: 'C',
      name: 'Credit Default',
    },
    {
      code: 'T',
      name: 'Total Return',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsUnderlyingIssuerType = {
  name: 'Underlying Issuer Type',
  values: [
    {
      code: 'C',
      name: 'Corporate',
    },
    {
      code: 'S',
      name: 'Sovereign',
    },
    {
      code: 'L',
      name: 'Local',
    }],
};

const swapsCreditDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'A',
      name: 'Auction',
    }],
};

const swapsFXUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'A',
      name: 'Spot-Forward Swap',
    },
    {
      code: 'C',
      name: 'Forward-Forward Swap',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsFXDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'N',
      name: 'Non-Deliverable',
    }],
};

const swapsOtherUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'P',
      name: 'Commercial Property (or Property Derivative)',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const swapsOtherDelivery = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    }],
};

const nonListedRatesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'A',
      name: 'Basis Swap',
    },
    {
      code: 'C',
      name: 'Fixed-Floating',
    },
    {
      code: 'D',
      name: 'Fixed-Fixed',
    },
    {
      code: 'G',
      name: 'Inflation Rate Index',
    },
    {
      code: 'H',
      name: 'Overnight Index Swap (OIS)',
    },
    {
      code: 'O',
      name: 'Options',
    },
    {
      code: 'R',
      name: 'Forwards',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'M',
      name: 'Others(Miscellaneous)',
    }],
};

const nonListedOptionStyle = {
  name: 'Option Style and Type',
  values: [
    {
      code: 'A',
      name: 'European Call',
    },
    {
      code: 'B',
      name: 'American Call',
    },
    {
      code: 'C',
      name: 'Bermudan Call',
    },
    {
      code: 'D',
      name: 'European Put',
    },
    {
      code: 'E',
      name: 'American Put',
    },
    {
      code: 'F',
      name: 'Bermudan Put',
    },
    {
      code: 'G',
      name: 'European Chooser',
    },
    {
      code: 'H',
      name: 'American Chooser',
    },
    {
      code: 'I',
      name: 'Bermudan Chooser',
    }],
};

const nonListedValuationMethod = {
  name: 'Valuation Method or Trigger',
  values: [
    {
      code: 'V',
      name: 'Vanilla',
    },
    {
      code: 'A',
      name: 'Asian',
    },
    {
      code: 'D',
      name: 'Digital (Binary)',
    },
    {
      code: 'B',
      name: 'Barrier',
    },
    {
      code: 'G',
      name: 'Digital Barrier',
    },
    {
      code: 'L',
      name: 'Lookback',
    },
    {
      code: 'P',
      name: 'Other Path Dependent',
    },
    {
      code: 'M',
      name: 'Other (Miscellaneous)',
    }],
};

const nonListedDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'E',
      name: 'Elect at Exercise',
    }],
};

const nonListedCommoditiesUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'J',
      name: 'Energy',
    },
    {
      code: 'K',
      name: 'Metals',
    },
    {
      code: 'A',
      name: 'Agriculture',
    },
    {
      code: 'N',
      name: 'Environmental',
    },
    {
      code: 'G',
      name: 'Freight',
    },
    {
      code: 'P',
      name: 'Polypropylene Products',
    },
    {
      code: 'S',
      name: 'Fertilizer',
    },
    {
      code: 'T',
      name: 'Paper',
    },
    {
      code: 'I',
      name: 'Index',
    },
    {
      code: 'Q',
      name: 'Multi-Commodity',
    },
    {
      code: 'O',
      name: 'Options',
    },
    {
      code: 'R',
      name: 'Forwards',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'W',
      name: 'Swaps',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const nonListedEquityUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'S',
      name: 'Single Stock',
    },
    {
      code: 'I',
      name: 'Index',
    },
    {
      code: 'B',
      name: 'Basket',
    },
    {
      code: 'O',
      name: 'Options',
    },
    {
      code: 'R',
      name: 'Forwards',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const nonListedCreditUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'U',
      name: 'CDS on a Single Name',
    },
    {
      code: 'V',
      name: 'CDS on an Index Tranche',
    },
    {
      code: 'I',
      name: 'CDS on an Index',
    },
    {
      code: 'W',
      name: 'Swaps',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const nonListedFXUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'R',
      name: 'Forwards',
    },
    {
      code: 'F',
      name: 'Futures',
    },
    {
      code: 'T',
      name: 'Spot-Forward Swap',
    },
    {
      code: 'V',
      name: 'Volatility',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

const nonListedFXDelivery = {
  name: 'Delivery',
  values: [
    {
      code: 'C',
      name: 'Cash',
    },
    {
      code: 'P',
      name: 'Physical',
    },
    {
      code: 'E',
      name: 'Elect at Exercise',
    },
    {
      code: 'N',
      name: 'Non-Deliverable',
    }],
};

const nonListedOtherUnderlyingAssets = {
  name: 'Underlying Assets',
  values: [
    {
      code: 'P',
      name: 'Commercial Property (or Property Derivatives',
    },
    {
      code: 'M',
      name: 'Others (Miscellaneous)',
    }],
};

/* Attributes Non Listed */

/* Collective Investment Vehicles Categories */
const categoryCollectiveInvestment = {
  name: 'Collective Investment Vehicles',
  code: 'C',
  groups: [
    {
      name: 'Standard (Vanilla) Investment Funds/Mutual Funds',
      code: 'I',
      attributes: [
        civClosedOpenEnd,
        civDistributionPolicy,
        civAssets,
        civSecurityTypeInvestorsRestrictions,
      ],
    },
    {
      name: 'Hedge Funds',
      code: 'H',
      attributes: [
        civInvestmentStrategy,
        civNA,
        civNA,
        civNA,
      ],
    },
    {
      name: 'Real Estate Investment Trusts (REIT)',
      code: 'B',
      attributes: [
        civClosedOpenEnd,
        civDistributionPolicy,
        civNA,
        civSecurityTypeInvestorsRestrictions,
      ],
    },
    {
      name: 'Exchange Traded Funds (ETF)',
      code: 'E',
      attributes: [
        civClosedOpenEnd,
        civDistributionPolicy,
        civAssets,
        civSecurityType,
      ],
    },
    {
      name: 'Pension Funds',
      code: 'S',
      attributes: [
        civClosedOpenEnd,
        civStrategyStyle,
        civType,
        civSecurityType,
      ],
    },
    {
      name: 'Funds of Funds',
      code: 'F',
      attributes: [
        civClosedOpenEnd,
        civDistributionPolicy,
        civTypeOfFunds,
        civSecurityTypeInvestorsRestrictions,
      ],
    },
    {
      name: 'Private Equity Funds',
      code: 'P',
      attributes: [
        civClosedOpenEnd,
        civDistributionPolicy,
        civAssets,
        civSecurityTypeInvestorsRestrictions,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        civNA,
        civNA,
        civNA,
        civSecurityTypeInvestorsRestrictions,
      ],
    },
  ],
};

/* Debt Categories */
const categoryDebt = {
  code: 'D',
  name: 'Debt',
  groups: [
    {
      name: 'Bonds',
      code: 'B',
      attributes: [
        debtTypeOfInterestOrCashPayment,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursement,
        debtForm,
      ],
    },
    {
      name: 'Convertible Bonds',
      code: 'C',
      attributes: [
        debtTypeOfInterestAlt1,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Bonds with Warrants',
      code: 'W',
      attributes: [
        debtTypeOfInterestAlt1,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Medium-Term Notes',
      code: 'T',
      attributes: [
        debtTypeOfInterestAlt1,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Money Market Instruments',
      code: 'Y',
      attributes: [
        debtTypeOfInterestAlt1,
        debtGuaranteeOrRanking,
        debtNA,
        debtForm,
      ],
    },
    {
      name: 'Structured Instruments(Capital Protection)',
      code: 'S',
      attributes: [
        debtTypeWithCapitalProtection,
        debtDistribution,
        debtRepaymentWithCapitalProtection,
        debtUnderlyingAssets,
      ],
    },
    {
      name: 'Structured Instruments (w/o Capital Protection)',
      code: 'E',
      attributes: [
        debtTypeWithoutCapitalProtection,
        debtDistribution,
        debtRepaymentWithoutCapitalProtection,
        debtUnderlyingAssets,
      ],
    },
    {
      name: 'Mortgage-Backed Securities',
      code: 'G',
      attributes: [
        debtTypeOfInterest,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Asset-Backed Securities',
      code: 'A',
      attributes: [
        debtTypeOfInterest,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Municipal Bonds',
      code: 'N',
      attributes: [
        debtTypeOfInterest,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
        debtForm,
      ],
    },
    {
      name: 'Depository Receipts on Debt Instruments',
      code: 'D',
      attributes: [
        debtInstrumentDependency,
        debtTypeOfInterestAlt2,
        debtGuaranteeOrRanking,
        debtRedemptionReimbursementExt,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        debtTypeMisc,
        debtNA,
        debtNA,
        debtForm,
      ],
    },
  ],
};

/* Entitlements Categories */
const categoryEntitlements = {
  name: 'Entitlements(Rights)',
  code: 'R',
  groups: [
    {
      name: 'Allotment (Bonus) Rights',
      code: 'A',
      attributes: [
        entitlementsNA,
        entitlementsNA,
        entitlementsNA,
        entitlementsForm,
      ],
    },
    {
      name: 'Subscription Rights',
      code: 'S',
      attributes: [
        entitlementsAssets,
        entitlementsNA,
        entitlementsNA,
        entitlementsForm,
      ],
    },
    {
      name: 'Purchase Rights',
      code: 'P',
      attributes: [
        entitlementsAssets,
        entitlementsNA,
        entitlementsNA,
        entitlementsForm,
      ],
    },
    {
      name: 'Warrants',
      code: 'W',
      attributes: [
        entitlementsUnderlyingAssets,
        entitlementsType,
        entitlementsCallPut,
        entitlementsExerciseOptionStyle,
      ],
    },
    {
      name: 'Mini-Future Certificate/Constant Leverage Certificate',
      code: 'F',
      attributes: [
        entitlementsUnderlyingAssets,
        entitlementsBarrierDependencyType,
        entitlementsLongShort,
        entitlementsExerciseOptionStyle,
      ],
    },
    {
      name: 'Depository Receipts on Entitlements',
      code: 'D',
      attributes: [
        entitlementsInstrumentDependency,
        entitlementsNA,
        entitlementsNA,
        entitlementsForm,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        entitlementsNA,
        entitlementsNA,
        entitlementsNA,
        entitlementsNA,
      ],
    },
  ],
};

/* Equities Categories */
const categoryEquities = {
  name: 'Equities',
  code: 'E',
  groups: [
    {
      name: 'Common/Ordinary Shares',
      code: 'S',
      attributes: [
        equitiesVotingRights,
        equitiesOwnership,
        equitiesPaymentStatus,
        equitiesForm,
      ],
    },
    {
      name: 'Preferred/Preference Shares',
      code: 'P',
      attributes: [
        equitiesVotingRights,
        equitiesRedemption,
        equitiesIncome,
        equitiesForm,
      ],
    },
    {
      name: 'Common/Ordinary Convertible Shares',
      code: 'C',
      attributes: [
        equitiesVotingRights,
        equitiesOwnership,
        equitiesPaymentStatus,
        equitiesForm,
      ],
    },
    {
      name: 'Preferred/Preference Convertible Shares',
      code: 'F',
      attributes: [
        equitiesVotingRights,
        equitiesRedemption,
        equitiesIncome,
        equitiesForm,
      ],
    },
    {
      name: 'Limited Partnership Units',
      code: 'L',
      attributes: [
        equitiesVotingRights,
        equitiesOwnership,
        equitiesPaymentStatus,
        equitiesForm,
      ],
    },
    {
      name: 'Depository Receipts on Equities',
      code: 'D',
      attributes: [
        equitiesInstrumentDependency,
        equitiesRedemptionAssets,
        equitiesIncome,
        equitiesForm,
      ],
    },
    {
      name: 'Structured Instruments (Participation)',
      code: 'Y',
      attributes: [
        equitiesType,
        equitiesDistribution,
        equitiesRepayment,
        equitiesUnderlyingAssets,
      ],
    },
    {
      name: 'Others (Miscellaneous)',
      code: 'M',
      attributes: [
        equitiesNA,
        equitiesNA,
        equitiesNA,
        equitiesForm,
      ],
    },
  ],
};

/* Categories */
const categoryFutures = {
  name: 'Futures',
  code: 'F',
  groups: [
    {
      name: 'Financial Futures',
      code: 'F',
      attributes: [
        futuresFinancialUnderlyingAssets,
        futuresDelivery,
        futuresStandardizedNonStandardized,
        futuresNA,
      ],
    },
    {
      name: 'Commodities Futures',
      code: 'C',
      attributes: [
        futuresCommondotiesUnderlyingAssets,
        futuresDelivery,
        futuresStandardizedNonStandardized,
        futuresNA,
      ],
    },
  ],
};

/* Listed Options Categories */
const categoryListedOptions = {
  name: 'Listed Options',
  code: 'O',
  groups: [
    {
      name: 'Call Options',
      code: 'C',
      attributes: [
        listedExerciseOptionStyle,
        listedUnderlyingAssets,
        listedDelivery,
        listedStandardizedNonStandardized,
      ],
    },
    {
      name: 'Put Options',
      code: 'P',
      attributes: [
        listedExerciseOptionStyle,
        listedUnderlyingAssets,
        listedDelivery,
        listedStandardizedNonStandardized,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        listedNA,
        listedNA,
        listedNA,
        listedNA,
      ],
    },
  ],
};

/* Other Categories */
const categoryOther = {
  name: 'Other(Miscellaneous)',
  code: 'M',
  groups: [
    {
      name: 'Combined Instruments',
      code: 'C',
      attributes: [
        otherComponents,
        otherOwnershipTransferSalesRestrictions,
        otherNA,
        otherForm,
      ],
    },
    {
      name: 'Other Assets',
      code: 'M',
      attributes: [
        otherFurtherGrouping,
        otherNA,
        otherNA,
        otherNA,
      ],
    },
  ],
};

/* Categories */
const categoryReferentialInstruments = {
  name: 'Referential Instruments',
  code: 'T',
  groups: [
    {
      name: 'Currencies',
      code: 'C',
      attributes: [
        referentialTypeCurrency,
        referentialNA,
        referentialNA,
        referentialNA,
      ],
    },
    {
      name: 'Commodities',
      code: 'T',
      attributes: [
        referentialTypeCommodities,
        referentialNA,
        referentialNA,
        referentialNA,
      ],
    },
    {
      name: 'Interest Rates',
      code: 'R',
      attributes: [
        referentialTypeInterest,
        referentialFrequency,
        referentialNA,
        referentialNA,
      ],
    },
    {
      name: 'Indices',
      code: 'I',
      attributes: [
        referentialAssetClasses,
        referentialWeightingTypes,
        referentialIndexReturnTypes,
        referentialNA,
      ],
    },
    {
      name: 'Baskets',
      code: 'B',
      attributes: [
        referentialComposition,
        referentialNA,
        referentialNA,
        referentialNA,
      ],
    },
    {
      name: 'Stock Dividends',
      code: 'D',
      attributes: [
        referentialTypeEquity,
        referentialNA,
        referentialNA,
        referentialNA,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        referentialNA,
        referentialNA,
        referentialNA,
        referentialNA,
      ],
    },
  ],
};

/* Category Swaps */

const categorySwaps = {
  name: 'Swaps',
  code: 'S',
  groups: [
    {
      name: 'Rates',
      code: 'R',
      attributes: [
        swapsRatesUnderlyingAssets,
        swapsNotional,
        swapsSingleOrMultiCurrency,
        swapsRatesDelivery,
      ],
    },
    {
      name: 'Commodities',
      code: 'T',
      attributes: [
        swapsCommoditiesUnderlyingAssets,
        swapsReturnOrPayoutTrigger,
        referentialNA,
        swapsDelivery,
      ],
    },
    {
      name: 'Equity',
      code: 'E',
      attributes: [
        swapsEquityUnderlyingAssets,
        swapsReturnOrPayoutTrigger,
        referentialNA,
        swapsDelivery,
      ],
    },
    {
      name: 'Credit',
      code: 'C',
      attributes: [
        swapsCreditUnderlyingAssets,
        swapsCreditReturnOrPayoutTrigger,
        swapsUnderlyingIssuerType,
        swapsCreditDelivery,
      ],
    },
    {
      name: 'Foreign Exchange',
      code: 'F',
      attributes: [
        swapsFXUnderlyingAssets,
        referentialNA,
        referentialNA,
        swapsFXDelivery,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        swapsOtherUnderlyingAssets,
        referentialNA,
        referentialNA,
        swapsOtherDelivery,
      ],
    },
  ],
};

/* Category Non-Listed and Other Options */

const categoryNonListed = {
  name: 'Non-Listed and Complex Listed Options',
  code: 'H',
  groups: [
    {
      name: 'Rates',
      code: 'R',
      attributes: [
        nonListedRatesUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedDelivery,
      ],
    },
    {
      name: 'Commodities',
      code: 'T',
      attributes: [
        nonListedCommoditiesUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedDelivery,
      ],
    },
    {
      name: 'Equity',
      code: 'E',
      attributes: [
        nonListedEquityUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedDelivery,
      ],
    },
    {
      name: 'Credit',
      code: 'C',
      attributes: [
        nonListedCreditUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedDelivery,
      ],
    },
    {
      name: 'Foreign Exchange',
      code: 'F',
      attributes: [
        nonListedFXUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedFXDelivery,
      ],
    },
    {
      name: 'Others(Miscellaneous)',
      code: 'M',
      attributes: [
        nonListedOtherUnderlyingAssets,
        nonListedOptionStyle,
        nonListedValuationMethod,
        nonListedFXDelivery,
      ],
    },
  ],
};

const cfiData = {
  categories: [
    categoryEquities,
    categoryDebt,
    categoryCollectiveInvestment,
    categoryEntitlements,
    categoryListedOptions,
    categoryFutures,
    categoryReferentialInstruments,
    categoryOther,
    categorySwaps,
    categoryNonListed,
  ],
};

export default cfiData;
