import PropTypes from 'prop-types';
import React from 'react';

import FilterItem from '../components/filter-item/FilterItem';
import FilterObject from '../models/Filter';
import Option from '../models/Option';

const Filter = ({
  filter, options, setFilter,
}) => {
  const [data, setData] = React.useState(filter);

  const change = {
    input: (event) => {
      const { name, value } = event.target;
      const updatedFilter = { ...data, [name]: value };
      setData(updatedFilter);
    },
  };

  React.useEffect(() => {
    const updatedFilter = new FilterObject(data);
    setFilter(updatedFilter);
  }, [data]);

  return (
    <div id="filter-box" className="base-form">
      <FilterItem
        label="Tags"
        name="tags"
        onChange={change.input}
        options={options.tags}
        value={data.tags}
      />
      <FilterItem
        label="Countries"
        name="countries"
        onChange={change.input}
        options={options.countries}
        value={data.countries}
      />
      <FilterItem
        label="Categories"
        name="categories"
        onChange={change.input}
        options={options.categories}
        value={data.categories}
      />
      <FilterItem
        label="CFI Groups"
        name="cfiGroups"
        onChange={change.input}
        options={options.cfiGroups}
        value={data.cfiGroups}
      />
    </div>
  );
};

Filter.propTypes = {
  filter: PropTypes.instanceOf(FilterObject).isRequired,
  setFilter: PropTypes.func.isRequired,
  options: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
    cfiGroups: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
    countries: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
    tags: PropTypes.arrayOf(PropTypes.instanceOf(Option)),

  }).isRequired,
};

export default Filter;
