import React from 'react';

import PageWrapper from 'pages/PageWrapper';
import TermSheets from './term-sheets/TermSheets';
import Workflows from './workflows/Workflows';
import { localIconUrl } from '../../nit/utils/iconUrls';

const MTNApp = () => (
  <PageWrapper
    appName="MTN"
    logoLocation={localIconUrl(240)}
    pages={[{
      auth: 'ADMIN', defaultView: 'TABLE', element: (<Workflows />), label: 'Workflows',
    }, {
      auth: 'ADMIN', defaultView: 'TABLE', element: (<TermSheets />), label: 'Term Sheets',
    },
    ]}
  />
);

export default MTNApp;
