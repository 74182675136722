import React from 'react';

import ViewContext from 'contexts/ViewContext';
import User from 'models/User';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import UserTable from './UserTable';
import UserForm from './UserForm';
import CrudService from '../../services/CrudService';

const Users = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState();

  const service = new CrudService({ download, name: 'User', path: '/admin/users' });

  React.useEffect(() => {
    const downloadData = async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedUsers = body.map((u) => new User(u));
        setUsers(downloadedUsers);
        setLoading(false);
      } else {
        setError(true);
      }
    };

    downloadData();
  }, []);

  const serialiseAuthorities = (authorityMap) => {
    const authorities = Object.entries(authorityMap).map(([key, value]) => `${key}|${value}`);
    console.log('AUTHORITIES: %o', authorities);
    return authorities;
  };

  const handle = {
    create: async () => {
      setDetails();
      await setView('FORM');
    },
    save: async (data) => {
      console.log('SAVING DATA: %o', data);

      const authorityMap = {};
      data.authorities.forEach((a) => {
        const [module, auth] = a.split('|');
        authorityMap[module] = authorityMap[module] || [];
        authorityMap[module].push(auth);
      });
      console.log('AUTHORITY MAP %o', authorityMap);
      const userNew = { ...data, authorityMap };
      delete userNew.authorities;
      const body = await (data.id ? service.update(userNew) : service.create(userNew));

      if (body !== null) {
        const updatedUser = new User(body);
        const updatedUsers = CrudService.updateData(users, updatedUser);
        setUsers(updatedUsers);
        await setView('TABLE');
      }
    },
    delete: async ({ original: user }) => {
      const body = await service.delete(user);
      if (body) {
        const filteredUsers = users.filter((u) => u.id !== user.id);
        setUsers(filteredUsers);
      }
    },
    edit: async ({ original: user }) => {
      const updatedUser = new User({
        ...user,
        authorities: serialiseAuthorities(user.authorityMap),
      });
      setDetails(updatedUser);
      console.log('SETTING DETAILS FOR USER %o', updatedUser);

      await setView('FORM');
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <UserForm
          save={handle.save}
          user={details}
        />
      );
    case 'TABLE':
      return (
        <UserTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          users={users}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Users;
