import React from 'react';
import PageWrapper from 'pages/PageWrapper';
import Orders from './orders/Orders';
import NewIssues from './newIssues/NewIssues';

const SyndicateApp = () => (
  <PageWrapper
    appName="SYND"
    logoLocation="/assets/NIT_240x240.png"
    pages={
        [{
          auth: 'ADMIN', defaultView: 'TABLE', element: (<NewIssues />), label: 'New Issues',
        }, {
          auth: 'ADMIN', defaultView: 'TABLE', element: (<Orders />), label: 'Orders',
        }]
      }
  />
);

export default SyndicateApp;
