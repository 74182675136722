import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '@symphony-ui/uitoolkit-components/components/date-picker/DatePicker';

import DateRangeObject from '../models/DateRange';

const DateRange = ({
  disabled, name, onChange, value,
}) => {
  const [data, setData] = React.useState({
    start: value.start ? new Date(value.start) : null,
    end: value.end ? new Date(value.end) : null,
  });

  const change = (dateName) => (event) => {
    const { value: newValue } = event.target;
    setData({ ...data, [dateName]: newValue });
  };

  React.useEffect(() => {
    const newValue = new DateRangeObject();
    if (data.start) newValue.start = data.start.toISOString().substring(0, 10);
    if (data.end) newValue.end = data.end.toISOString().substring(0, 10);
    const newObject = {
      target: {
        name,
        value: newValue,
      },
    };
    onChange(newObject);
  }, [data]);

  return (
    <div className="co-range">
      <DatePicker className="date" date={data.start} disabled={disabled} format="dd/MM/yyyy" name="start" onChange={change('start')} />
      <DatePicker className="date" date={data.start} disabled={disabled} format="dd/MM/yyyy" name="end" onChange={change('end')} />
    </div>
  );
};

DateRange.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(DateRangeObject),
};

DateRange.defaultProps = {
  disabled: false,
  value: new DateRangeObject(),
};

export default DateRange;
