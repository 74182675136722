import FilterItem from './FilterItem';

class Filter {
  constructor(filter) {
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        this[key] = new FilterItem(value);
      });
    }
  }

  update(name) {
    return (event) => {
      const newFilter = new Filter({ ...this, [name]: event });
      return newFilter;
    };
  }

  updateTags(event) {
    const newFilter = new Filter({ ...this });
    newFilter.tags.values = (event || []).map((t) => t.value);
    return newFilter;
  }

  create() {
    const test = (contains, name) => (this[name].exclude && !contains)
                        || (!this[name].exclude && contains);
    return (s) => {
      const tagsTest = s.tags && this.tags && this.tags.values.length ? test(
        s.tags.some((t) => this.tags.values.includes(t)),
        'tags',
      ) : true;

      const countryTest = this.countries && this.countries.values.length ? test(
        this.countries.values.includes(s.issuercountryname),
        'countries',
      ) : true;

      const categoryTest = this.categories && this.categories.values.length ? test(
        this.categories.values.includes(s.issuercategory),
        'categories',
      ) : true;

      const cfiGroupTest = this.cfiGroups && this.cfiGroups.values.length ? test(
        this.cfiGroups.values.includes(s.cfi.group),
        'cfiGroups',
      ) : true;
      return tagsTest && countryTest && categoryTest && cfiGroupTest;
    };
  }
}
export default Filter;
