import PropTypes from 'prop-types';
import React from 'react';

import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import Comment from '../../models/Comment';
import TableLinks from './TableLinks';
import TagModal from './TagModal';

const CommentsTable = ({ comments, tagOptions, updateTags }) => {
  const [modalContent, setModalContent] = React.useState('');

  const commentColumns = [
    actionCell,
    {
      accessor: 'createdBy.company',
      className: 'co-table-text co-table-text-l',
      header: 'Party',
    }, {
      accessor: 'createdBy.displayName',
      className: 'co-table-text',
      header: 'User',
    }, {
      accessor: 'text',
      className: 'co-table-text',
      header: 'Comment',
    },
    { accessor: 'allTags', header: 'Tags' },
    {
      header: 'Files',
      accessor: 'files',
      tooltip: 'Please click to open.',
      Cell: TableLinks,
    }, {
      accessor: 'time',
      header: 'Time',
    },

  ];

  const addTag = (row) => {
    const comment = new Comment(row.original);

    const hideModal = () => {
      setModalContent('');
    };

    setModalContent(
      <TagModal
        comment={comment}
        hideModal={hideModal}
        tagOptions={tagOptions}
        updateTags={updateTags}
      />,
    );
  };

  const tableData = comments.map((c) => c.toTableData());

  const actionsMenu = [
    {
      callback: addTag,
      label: 'Add Tag',
      type: 'info',
    },
  ];

  return (
    <div>
      {modalContent}
      <CustomTable
        actionsMenu={actionsMenu}
        className="standard"
        columns={commentColumns}
        data={tableData}
        emptyMessage="No comments yet"
      />
    </div>
  );
};

CommentsTable.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.instanceOf(Comment)),
  tagOptions: PropTypes.arrayOf(PropTypes.string),
  updateTags: PropTypes.func.isRequired,
};

CommentsTable.defaultProps = {
  comments: [],
  tagOptions: [],
};

export default CommentsTable;
