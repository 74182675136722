import PropTypes from 'prop-types';
import React from 'react';
import Button from '@symphony-ui/uitoolkit-components/components/button/Button';

import MessageContext from 'contexts/MessageContext';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import FileInfo from '../../models/FileInfo';

const FileButton = ({ fileInfo }) => {
  const { setError, setMessage } = React.useContext(MessageContext);

  const onClick = async () => {
    const url = new ServerURL('/document/load');
    url.searchParams.append('contentType', btoa(fileInfo.contentType));
    url.searchParams.append('filename', btoa(fileInfo.filename));

    const { fileId } = fileInfo;

    Object.entries(fileId).forEach(([key, value]) => {
      url.searchParams.append(key, btoa(value));
    });
    const request = new ServerRequest(url);

    try {
      const response = await fetch(request);

      if (response.status === 200) {
        const blob = await response.blob();

        const newBlob = new Blob([blob], { type: fileInfo.contentType });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileInfo.filename;
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setMessage({ text: `Error downloading document. (${response.status})`, type: 'error' });
      }
    } catch (error) {
      setError(error);
    }
  };

  return (<Button onClick={onClick} variant="tertiary">{fileInfo.filename}</Button>);
};

FileButton.propTypes = {
  fileInfo: PropTypes.instanceOf(FileInfo).isRequired,
};

export default FileButton;
