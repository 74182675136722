import ServerRequest from 'models/ServerRequest';

class Download {
  constructor({
    setError, setMessage, setOffline,
  }) {
    this.setError = setError;
    this.setMessage = setMessage;
    this.setOffline = setOffline;
  }

  noContent = async (request, errorMessage) => {
    try {
      const response = await fetch(request);

      if (response.status === 204) {
        return true;
      }
      const body = await response.json();
      this.setMessage({ text: `${errorMessage}: (${body.type} - ${body.message} - ${response.status})`, type: 'error' });
      return false;
    } catch (error) {
      this.setError(error);
      return false;
    }
  }

  json = async (request, errorMessage) => {
    try {
      const response = await fetch(request);
      const body = await response.json();
      if (response.status === 200) {
        return body;
      }
      this.setMessage({ text: `${errorMessage}: (${body.type} - ${body.message} - ${response.status})`, type: 'error' });
      return null;
    } catch (error) {
      this.setError(error);
      return null;
    }
  }

  options = async (path, errorMessage, optionsSetter) => {
    const request = new ServerRequest(path);
    const body = await this.json(request, errorMessage);
    if (body !== null) {
      optionsSetter(body);
    }
  };
}

export default Download;
