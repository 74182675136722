/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Checkbox from '@symphony-ui/uitoolkit-components/components/selection/Checkbox';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';

import AppViewContext from 'contexts/AppViewContext';
import apiSource from 'utils/system/apiSource';
/**
 * Note: no need for 'init' as this is only used for the web site.
 * @param {*} props
 */
const LoginForm = ({
  close, setMessage, setTitle, switchForm,
}) => {
  const { setChecked } = React.useContext(AppViewContext);
  const [data, setData] = React.useState({ rememberMe: true });

  React.useEffect(() => {
    setTitle('Sign In');
  }, []);

  const click = {
    login: async () => {
      const formData = new FormData();
      formData.append('username', data.username);
      formData.append('password', data.password);
      formData.append('remember-me', data.rememberMe);

      const url = apiSource('/login');

      const init = { body: formData, credentials: 'include', method: 'POST' };
      try {
        const response = await fetch(url, init);
        console.log(response);

        if (response.status === 204) {
          close();
          setChecked(false);
        } if (response.status === 401) {
          const text = await response.text();
          setMessage({ text: `${text} - please contact administrator.`, type: 'error' });
        }
      } catch (error) {
        setMessage({ text: `Error connecting to the Server: ${error.message}`, type: 'error' });
      }
    },
    rememberMe() {
      const newValue = !data.rememberMe;
      setData({ ...data, rememberMe: newValue });
    },
    reset() {
      setMessage(null);
      switchForm('RESET');
    },
  };

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  return (
    <div>
      <div className="co-item">
        <TextField autoComplete="username" name="username" value={data.username || ''} label="User Name" required onChange={change.input} />
        <TextField autoComplete="password" isMasked name="password" value={data.password || ''} label="Password" required onChange={change.input} />
        <Checkbox name="rememberMe" value="" status={data.rememberMe ? 'checked' : 'unchecked'} onClick={click.rememberMe} label="Remember Me" />
      </div>

      <div className="co-form-buttons co-item">
        <Button onClick={click.login}>Log In</Button>
        <Button onClick={click.reset} variant="tertiary">Reset Password</Button>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  close: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  switchForm: PropTypes.func.isRequired,
};

export default LoginForm;
