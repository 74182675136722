class Period {
  constructor(period) {
    Object.assign(this, period);

    if (typeof this.periodMultiplier === 'string') {
      this.periodMultiplier = Number.parseInt(this.periodMultiplier, 10);
    }
  }

  static init(defaultPeriod) {
    const period = new Period();
    period.period = defaultPeriod;
    return period;
  }

  isEmpty() {
    return !('periodMultiplier' in this) || this.periodMultiplier === null;
  }

  isValid() {
    const valid = 'periodMultiplier' in this && this.periodMultiplier !== '' && this.periodMultiplier !== '-';
    return valid;
  }

  static multiplierRegex = /^(?<periodMultiplier>(0|-|-?[1-9][0-9]{0,1})?)$/u;

  static multiplierRegexString = '(?<periodMultiplier>(0|-|-?[1-9][0-9]{0,1})?)';

  static regexString = '(?<period>[DWMY])';

  static from(value) {
    const regex = new RegExp(`^${this.multiplierRegexString}${this.regexString}$`, 'u');
    //    const regex = /^(?<periodMultiplier>(0|-|-?[1-9][0-9]{0,1})?)(?<period>[DWMY])$/u;
    const match = value.match(regex);

    return match ? new Period(match.groups) : null;
  }

  updatePeriod(value) {
    return new Period({ ...this, period: value });
  }

  updatePeriodMultiplier(value) {
    const match = value.match(new RegExp(`^${this.multiplierRegexString}$`, 'u'));

    return match ? new Period({ ...this, periodMultiplier: match.groups.periodMultiplier }) : this;
  }

  setDefault(defaultPeriod) {
    if (!('period' in this)) {
      this.period = defaultPeriod;
    }
    return this;
  }

  toString() {
    if (this.periodMultiplier === -1 && this.period === 'D') {
      return 'yesterday';
    }
    if (this.periodMultiplier === 0 && this.period === 'D') {
      return 'today';
    }

    return `${this.periodMultiplier} ${this.period}`;
  }
}

export default Period;
