class Tranche {
  constructor(tranche) {
    Object.assign(this, tranche);

    Object.keys(this).forEach((key) => {
      if (this[key] === null) {
        delete this[key];
      }
    });
  }
}

export default Tranche;
