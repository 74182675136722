import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';

import AppViewContext from 'contexts/AppViewContext';
import Contact from './Contact';
import Login from './Login';

import './RightPanel.scss';

const RightPanel = () => {
  const { offline } = React.useContext(AppViewContext);

  const [showContact, setShowContact] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  const click = {
    contact() {
      setShowContact(true);
    },
    login() {
      setShowLogin(true);
    },
  };

  return (
    <div id="right-panel">
      {showContact
        ? <Contact /> : ''}
      {showLogin
        ? <Login close={() => setShowLogin(false)} /> : ''}

      <Button disabled={offline} onClick={click.contact}>REQUEST A DEMO</Button>

      {!window.jwt ? (
        <Button disabled={offline} onClick={click.login}>SIGN IN</Button>
      ) : ''}
    </div>
  );
};

export default RightPanel;
