import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import { getOptions, getOptionsMulti, validationChanged } from 'utils/change';
import { errorMessage } from 'utils/initialValidation';
import TimeRange from '../../../models/TimeRange';
import Search from '../../../models/Search';
import RemoteFilterItem from '../../filter-item/RemoteFilterItem';
import TimeRangeInput from '../../time-range-input/TimeRangeInput';
import options from './anna';

import './AnnaForm.css';
import { searchChange } from '../searchUtils';

const AnnaForm = ({ search, setSearch }) => {
  const [data, setData] = React.useState(search);
  const [state, setState] = React.useState(search.query && search.query.isinValue && search.query.isinValue !== '' ? 'isin' : 'other');

  const [errorState, setErrorState] = React.useState({});

  const validators = {
    isinValue: Validators.Pattern('^[A-Z0-9\\*]{12}$'),
    annaStatus: null,
    entityName: null,
    instrumentCategory: null,
  };

  const testInput = (field) => {
    if (typeof data.query === 'undefined' || !(field in data.query) || data.query[field] === null) {
      return false;
    }
    // TODO #278 ANNA Form bug null value
    return data.query[field].length > 0;
  };

  const testMulti = (field) => {
    if (typeof data.query === 'undefined' || !(field in data.query) || data.query[field] === null) {
      return false;
    }
    return data.query[field].length > 0
    && !data.query[field].includes(options[field][0].value);
  };

  const testDate = (field) => {
    if (typeof data.query === 'undefined' || !(field in data.query)) {
      return false;
    }
    const d = data.query[field];
    if (d === null || !(d instanceof TimeRange)) {
      return false;
    }
    return !d.isEmpty();
  };

  const testFilter = (field) => {
    if (typeof data.filter === 'undefined' || !(field in data.filter) || data.filter[field] === null) {
      return false;
    }
    return data.filter[field].values.length > 0;
  };

  const issueCurrencyOptions = React.useMemo(() => options
    .issueCurrency.map((o) => ({ ...o, label: o.value })));

  const countryOptions = React.useMemo(() => options
    .country.map((o) => {
      const label = o.label === ' All' ? ' All' : o.label.substring(4);
      return { ...o, label };
    }));

  const instrumentCategoryOptions = React.useMemo(() => options
    .instrumentCategory.map((o) => {
      const label = o.label === ' All' ? ' All' : o.label.substring(0, o.label.length - 5);
      return { ...o, label };
    }));

  React.useEffect(() => {
    if (testInput('isinValue')) {
      setState('isin');
    } else {
      const inputs = ['entityName', 'issueDescription'];
      const multis = ['annaStatus', 'country', 'instrumentCategory', 'issueCurrency'];
      const dates = ['maturity', 'update'];
      const filter = ['tags'];
      setState(multis.some(testMulti) || inputs.some(testInput) || dates.some(testDate) || filter.some(testFilter) ? 'other' : 'none');
    }
  }, [data]);

  const change = searchChange(data, setData);

  // TODO #325 #324 Fix validation ANNA Form
  const onValidationChanged = validationChanged(errorState, setErrorState);

  const list = [
    <Dropdown
      className="select-addon select-contains"
      isTypeAheadEnabled={false}
      key="entity-name-option2"
      label=""
      onChange={change.query('entityNameOpton2')}
      options={options.entityNameOpton2}
      value={getOptions(data.query.entityNameOpton2, options.entityNameOpton2)}
    />,
    <Dropdown
      className="select-addon select-length"
      isTypeAheadEnabled={false}
      key="entity-name-option1"
      label=""
      onChange={change.query('entityNameOpton1')}
      options={options.entityNameOpton1}
      value={getOptions(data.query.entityNameOpton1, options.entityNameOpton1)}
    />,
  ];
  React.useEffect(() => {
    const singleFields = ['entityNameOpton1', 'entityNameOpton2', 'isinType', 'issueDesOptions'];
    const multiFields = ['annaStatus', 'country', 'instrumentCategory', 'issueCurrency'];
    let changed = false;
    const searchCopy = new Search({ ...data });
    singleFields.forEach((field) => {
      if (!data.query[field]) {
        searchCopy.query[field] = options[field][0].value;
        changed = true;
      }
    });
    multiFields.forEach((field) => {
      const allValues = options[field].map((o) => o.value);
      const firstValue = allValues.shift();

      // if it is empty, fill it with the first value
      if (!data.query[field] || data.query[field].length === 0) {
        searchCopy.query[field] = [firstValue];
        changed = true;
      }

      // if it includes both the first and others, change id
      if (searchCopy.query[field].some((v) => allValues.includes(v))
      && searchCopy.query[field].includes(firstValue)) {
        searchCopy.query[field] = searchCopy.query[field].filter((v) => v !== firstValue);
        changed = true;
      }
    });

    if (changed) {
      setData(searchCopy);
    } else {
      setSearch(data);
    }
  }, [data]);

  return (
    <div className="base-form">
      {state !== 'other'
        ? (
          <Validation errorMessage={{ pattern: 'ISIN should have 12 characters' }} onValidationChanged={onValidationChanged('isinValue')} validator={validators.isinValue}>
            <TextField
              className="input-with-addon addon-group-reverse"
              label="ISIN"
              name="isinValue"
              onChange={change.queryInput}
              rightDecorators={(
                <Dropdown
                  className="select-addon select-isin-type"
                  isTypeAheadEnabled={false}
                  label=""
                  onChange={change.query('isinType')}
                  options={options.isinType}
                  value={getOptions(data.query.isinType, options.isinType)}
                />
              )}
              tooltip={'Search by ISIN, either of the security itself, or of securities with that ISIN as underlying.&nbsp;&nbsp;Note: you can use * as wildcard.'}
              value={data.query.isinValue || ''}
            />
          </Validation>
        ) : ''}

      {state !== 'isin' ? (
        <div className="other-form-first">
          <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('annaStatus')} validator={validators.annaStatus}>
            <Dropdown isMultiSelect label="Status" onChange={change.query('annaStatus')} options={options.annaStatus} value={getOptionsMulti(data.query.annaStatus, options.annaStatus)} />
          </Validation>
        </div>
      ) : ''}

      {state !== 'isin' ? (
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('country')} validator={validators.country}>
          <Dropdown isMultiSelect label="Country" onChange={change.query('country')} options={countryOptions} value={getOptionsMulti(data.query.country, countryOptions)} />
        </Validation>
      ) : ''}

      {state !== 'isin' ? (
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('entityName')} validator={validators.entityName}>
          <TextField
            className="input-with-addon addon-group-reverse"
            label="Entity Name"
            name="entityName"
            onChange={change.queryInput}
            rightDecorators={list}
            value={data.query.entityName || ''}
          />
        </Validation>
      ) : ''}

      {state !== 'isin' ? (
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('instrumentCategory')} validator={validators.instrumentCategory}>
          <Dropdown isMultiSelect label="Category" onChange={change.query('instrumentCategory')} options={instrumentCategoryOptions} value={getOptionsMulti(data.query.instrumentCategory, instrumentCategoryOptions)} />
        </Validation>
      ) : ''}

      {state !== 'isin' ? (
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('issueCurrency')} validator={validators.issueCurrency}>
          <Dropdown isMultiSelect label="Currency" onChange={change.query('issueCurrency')} options={issueCurrencyOptions} value={getOptionsMulti(data.query.issueCurrency, issueCurrencyOptions)} />
        </Validation>
      ) : ''}

      {state !== 'isin' ? (
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('issueDescription')} validator={validators.issueDescription}>
          <TextField
            className="input-with-addon addon-group-reverse"
            label="Issue Description"
            name="issueDescription"
            onChange={change.queryInput}
            rightDecorators={(
              <Dropdown
                className="select-addon select-contains"
                isTypeAheadEnabled={false}
                label=""
                onChange={change.query('issueDesOptions')}
                options={options.issueDesOptions}
                value={getOptions(data.query.issueDesOptions, options.issueDesOptions)}
              />
    )}
            value={data.query.issueDescription || ''}
          />
        </Validation>
      ) : ''}

      {state !== 'isin' ? (
        <TimeRangeInput
          defaultPeriod="Y"
          label="Maturity"
          name="maturity"
          onChange={change.queryInput}
          value={data.query.maturity}
        />
      ) : '' }

      {state !== 'isin' ? (
        <TimeRangeInput
          defaultPeriod="D"
          label="Last Update"
          name="update"
          onChange={change.queryInput}
          value={data.query.update}
        />
      ) : ''}
      {state !== 'isin' ? (
        <RemoteFilterItem
          disabled={false}
          label="Tags"
          name="tags"
          onChange={change.filter}
          path="/nit/companies/tags/options/anna"
          value={data.filter.tags}
        />
      ) : ''}

    </div>
  );
};

AnnaForm.propTypes = {
  search: PropTypes.instanceOf(Search),
  setSearch: PropTypes.func.isRequired,
};

AnnaForm.defaultProps = {
  search: new Search(),
};

export default AnnaForm;
