import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import AppViewContext from 'contexts/AppViewContext';
import { checkEditable } from 'components/metadata/utils';

import CustomTable from 'components/custom-table/CustomTable';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import { actionCell } from 'utils/table';
import Report from '../../models/Report';
import Option from '../../models/Option';
// import TableToggle from './TableToggle';

const ReportTable = ({
  handle, hasError, isLoading, options, reports,
}) => {
  const { user } = React.useContext(AppViewContext);

  const actionsMenu = (report) => [{
    label: checkEditable(report, user) ? 'Edit' : 'View',
    callback: handle.edit,
    type: 'info',
  },
  {
    label: 'Delete',
    callback: handle.delete,
    type: 'error',
  },
  ];

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'name',
      header: 'Name',
      tooltip: 'The name you gave to the Report.',
    }, {
      accessor: 'source',
      header: 'Source',
    }, {
      accessor: (row) => {
        const option = options.flatMap((o) => o.options).find((o) => o.value === `${row.source}|${row.searchId}`);
        return option ? option.label : 'N/A';
      },
      header: 'Search',
      id: 'search',
    },
    {
      accessor: (row) => {
        const { frequency } = row;
        if (frequency) {
          return frequency.value && frequency.type
            ? `Every ${frequency.value} ${frequency.type.toLowerCase()}`
            : `Daily at ${frequency.time}`;
        }
        return '';
      },
      header: 'Frequency',
      id: 'frequency',
    },
  ]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Reports" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={reports}
        emptyMessage="You have no reports yet. Please create a new one."
        errorMessage="Unable to download reports. Please check connection."
        hasError={hasError}
        loading={isLoading}
      />
    </Card>
  );
};

ReportTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
    toggleActive: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Option)),
  reports: PropTypes.arrayOf(PropTypes.instanceOf(Report)),
};

ReportTable.defaultProps = {
  hasError: false,
  isLoading: false,
  options: [],
  reports: [],
};

export default ReportTable;
