/* global APP_CONFIG */
import envs from '../utils/system/envs-constants';

class ServerURL extends URL {
  constructor(path) {
    const { currEnv } = process.env;

    let ROOT_URL;
    if (currEnv === envs.MOCK) {
      // Using JSON Server
      ROOT_URL = 'http://localhost:3000';
    } if (currEnv === envs.DEV) {
      // BE with another source
      ROOT_URL = 'https://localhost:9000';
    } else {
      ROOT_URL = APP_CONFIG.API_EXT_URL;
    }

    super(`${ROOT_URL}${path}`, ROOT_URL);
  }
}

export default ServerURL;
