import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import DownloadContext from 'contexts/DownloadContext';
import { passwordRequirements } from 'utils/passwords';
import ServerRequest from 'models/ServerRequest';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import User from 'models/User';
import {
  change, getOptionsMulti, validationChanged,
} from 'utils/change';

const UserForm = ({ save, user }) => {
  const { download } = React.useContext(DownloadContext);
  const [data, setData] = React.useState(user);
  const [errorState, setErrorState] = React.useState({});
  const [options, setOptions] = React.useState({ authorities: [] });

  const validators = {
    username: Validators.Required,
    password: CustomValidators.Password,
    firstName: Validators.Required,
    lastName: Validators.Required,
    displayName: Validators.Required,
    emailAddress: [CustomValidators.Email, Validators.Required],
  };

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/admin/users/options');
      const body = await download.json(request, 'Error downloading authorities.');
      if (body !== null) {
        setOptions({ authorities: body });
      }
    })();
  }, []);

  const onChange = change(data, setData);
  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    document.querySelectorAll('input[type="password"]').forEach((element) => {
      const passwordElement = element;
      passwordElement.onfocus = (event) => {
        const { name } = event.target;
        setData({ ...data, [name]: '' });
        passwordElement.onfocus = null;
      };
    });
  }, []);

  const passwordInfo = passwordRequirements.map((t) => `* ${t}`).join('');

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      name="User"
      save={save}
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="user-form">
        <Validation validator={validators.username} errorMessage={errorMessage} onValidationChanged={onValidationChanged('username')}>
          <TextField label="User Name" maxLength="40" name="username" onChange={onChange.input} value={data.username || ''} />
        </Validation>

        <Validation validator={validators.password} errorMessage={errorMessage} onValidationChanged={onValidationChanged('password')}>
          <TextField label="Password" maxLength="40" name="password" onChange={onChange.input} tooltip={passwordInfo} value={data.password || ''} />
        </Validation>

        <Validation validator={validators.firstName} errorMessage={errorMessage} onValidationChanged={onValidationChanged('firstName')}>
          <TextField label="First Name" maxLength="40" name="firstName" onChange={onChange.input} value={data.firstName || ''} />
        </Validation>

        <Validation validator={validators.lastName} errorMessage={errorMessage} onValidationChanged={onValidationChanged('lastName')}>
          <TextField label="Last Name" maxLength="40" name="lastName" onChange={onChange.input} value={data.lastName || ''} />
        </Validation>

        <Validation validator={validators.displayName} errorMessage={errorMessage} onValidationChanged={onValidationChanged('displayName')}>
          <TextField label="Display Name" maxLength="40" name="displayName" onChange={onChange.input} value={data.displayName || ''} />
        </Validation>

        <Validation validator={validators.emailAddress} errorMessage={errorMessage} onValidationChanged={onValidationChanged('emailAddress')}>
          <TextField label="Email Address" maxLength="40" name="emailAddress" onChange={onChange.input} value={data.emailAddress || ''} />
        </Validation>

        <TextField label="Title" maxLength="40" name="title" onChange={onChange.input} value={data.title || ''} />

        <TextField label="Job Function" maxLength="40" name="jobFunction" onChange={onChange.input} value={data.jobFunction || ''} />

        <TextField label="Company" maxLength="40" name="company" onChange={onChange.input} value={data.company || ''} />

        <TextField label="Location" maxLength="40" name="location" onChange={onChange.input} value={data.location || ''} />

        <TextField label="Department" maxLength="40" name="department" onChange={onChange.input} value={data.department || ''} />

        <TextField label="Division" maxLength="40" name="division" onChange={onChange.input} value={data.division || ''} />

        <TextField label="Mobile Phone Number" maxLength="40" name="mobilePhoneNumber" onChange={onChange.input} value={data.mobilePhoneNumber || ''} />

        <TextField label="Work Phone Number" maxLength="40" name="workPhoneNumber" onChange={onChange.input} value={data.workPhoneNumber || ''} />

        <Dropdown isMultiSelect label="Authorities" name="authorities" onChange={onChange.dropdown('authorities')} options={options.authorities} value={getOptionsMulti(data.authorities, options.authorities)} />
      </div>

    </FormWrapper>
  );
};

UserForm.propTypes = {
  save: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User),
};

UserForm.defaultProps = {
  user: new User(),
};

export default UserForm;
