import React from 'react';

import Link from '@symphony-ui/uitoolkit-components/components/link/Link';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './BottomPanel.scss';

const BottomPanel = () => {
  const link = 'https://symphony.com/2021/01/06/cedar-bot-a-symphony-hackathon-winner/';

  return (
    <div id="bottom-panel">
      <div>
        <Link href={link} url={link}>Winners of 2020 Symphony London Hackathon</Link>
      </div>
      <div>
        <Typography type="h1">Most Progressive Business Workflow</Typography>
      </div>
    </div>
  );
};

export default BottomPanel;
