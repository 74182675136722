import React from 'react';

import PageWrapper from 'pages/PageWrapper';
import NITSettings from './NITSettings';
import Searches from './Searches';
import Results from './Results';
import Reports from './Reports';
import Companies from './Companies';
import Overview from './Overview';

const NewIssueTrackerApp = () => (
  <PageWrapper
    appName="NIT"
    logoLocation="/assets/NIT_48x48.png"
    pages={[
      { element: (<Overview />), label: 'Overview' },
      { defaultView: 'FORM', element: (<Searches />), label: 'Search' },
      { element: (<Results />), label: 'Results' },
      { defaultView: 'TABLE', element: (<Reports />), label: 'Reports' },
      { element: (<Companies />), label: 'Companies' }]}
    settings={(<NITSettings />)}
  />
);

export default NewIssueTrackerApp;
