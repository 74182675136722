import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';

import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import { getOptions } from 'utils/change';
import Search from '../../../models/Search';
import NameValuePair from '../../name-value-pair/NameValuePair';
import './EsmaForm.css';

const EsmaForm = ({ search, setSearch }) => {
  const { download } = React.useContext(DownloadContext);

  const [data, setData] = React.useState(search);
  const [selector, setSelector] = React.useState(null);
  const [nameValueList, setNameValueList] = React.useState([]);
  const [nameValueFields, setNameValueFields] = React.useState([]);
  const [nameValueOptions, setNameValueOptions] = React.useState([]);
  const [selectorDisabled, setSelectorDisabled] = React.useState(selector === null);

  const change = {
    input(event) {
      if (event.target) {
        const { name, value } = event.target;

        const updatedSearch = data.updateQuery(name, value);
        setData(updatedSearch);
      }
    },
    select(event) {
      const { value } = event.target;
      setSelector(value.value);
      setSelectorDisabled(value === null);
    },
  };

  const click = {
    add() {
      const newSearch = { ...data };
      if (selector === 'tags') {
        const { filter } = data || {};
        filter.tags = [];
        newSearch.filter = filter;
      } else {
        const query = data.query || {};
        const newList = query.textCriteria ? [...query.textCriteria] : [];
        newList.push({ name: selector, values: [] });
        query.textCriteria = newList;
        newSearch.query = query;
      }
      setData(new Search(newSearch));
      setSelector(null);
    },
  };

  const handle = {
    remove: (n) => () => {
      const { query } = data;
      const newList = query.textCriteria
        ? query.textCriteria.filter((l) => l.name !== n.name) : [];
      query.textCriteria = newList;
      setData(new Search({ ...data, query }));
    },
    removeFilter: (n) => () => {
      const { filter } = data;
      delete filter[n];
      setData(new Search({ ...data, filter }));
    },
    setValues: (n) => (val) => {
      const { query } = data;
      //     console.log('value set to: %o, initial list: %o, N: %o', val, query.textCriteria, n);
      const index = query.textCriteria.findIndex((l) => l.name === n.name);
      //    console.log('INDEX: %o', index);
      const newN = { ...n, values: val };
      const textCriteria = [...query.textCriteria];
      textCriteria[index] = newN;
      query.textCriteria = textCriteria;
      setData(new Search({ ...search, query }));
    },
    setValuesFilter: (event) => {
      const { name, value } = event.target;
      const { filter } = data;
      filter[name] = value;
      setData(new Search({ ...search, filter }));
    },
  };

  const optionNotDisabled = (option) => {
    const criteria = data.query.textCriteria;
    if (criteria) {
      const chosenOptions = data.query.textCriteria.map((t) => t.name);
      return !chosenOptions.includes(option.value);
    }
    return true;
  };

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/nit/search/esma/name-fields');
      const fields = await download.json(request);

      if (fields !== null) {
        setNameValueFields(fields);
        const options = fields.map((o) => ({ label: o.description, value: o.name }));
        const leiOption = options.find((o) => o.value === 'lei');
        leiOption.label = 'Issuer Name';
        const cfiOption = options.find((o) => o.value === 'gnr_cfi_code');
        cfiOption.label = 'Category';
        const currencyOption = options.find((o) => o.value === 'bnd_nmnl_value_curr_code');
        currencyOption.label = 'Currency';
        options.push({ label: 'Tags', value: 'tags' });
        setNameValueOptions(options);
      }
    })();
  }, []);

  React.useEffect(() => {
    setSearch(data);
    if (data.query.textCriteria) {
      const chosenOptions = data.query.textCriteria.map((t) => t.name);
      const newNVOptions = nameValueOptions
        .map((o) => ({ ...o, disabled: chosenOptions.includes(o.value) }));
      setNameValueOptions(newNVOptions);
    }
  }, [data]);

  React.useEffect(() => {
    console.log('New Data: %o', data);

    //    console.log('search changed so running again');
    const elements = data.query.textCriteria ? data.query.textCriteria.map((n) => {
      const field = nameValueFields.find((f) => f.name === n.name);
      const type = field ? field.type : '';
      return (
        <NameValuePair
          description={field ? field.description : ''}
          key={n.name}
          name={n.name}
          remove={handle.remove(n)}
          setValues={handle.setValues(n)}
          type={type}
          values={n.values}
        />
      );
    }) : [];
    if (data.filter) {
      Object.entries(data.filter).forEach(([key, value]) => {
        elements.push(
          <NameValuePair
            description=""
            key={key}
            name={key}
            remove={handle.removeFilter(key)}
            setValues={handle.setValuesFilter}
            type="FILTER"
            values={value}
          />,
        );
      });
    }

    setNameValueList(elements);
  }, [data, nameValueFields]);

  return (
    <div>
      <div className="base-form">
        {nameValueList}
      </div>
      <div className="co-dropdown-row co-item">
        <Dropdown
          className="co-dropdown"
          label="Add a field for the search"
          onChange={change.select}
          options={nameValueOptions.filter(optionNotDisabled)}
          value={getOptions(selector, nameValueOptions.filter(optionNotDisabled))}
        />
        <Button className="co-button" disabled={selectorDisabled} iconButton onClick={click.add}><Icon iconName="plus" /></Button>
      </div>
    </div>
  );
};

EsmaForm.propTypes = {
  search: PropTypes.instanceOf(Search),
  setSearch: PropTypes.func.isRequired,
};

EsmaForm.defaultProps = {
  search: new Search(),
};

export default EsmaForm;
