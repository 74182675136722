import PropTypes from 'prop-types';
import React from 'react';

import CustomTable from 'components/custom-table/CustomTable';
import ResultsModal from './ResultsModal';
import sources from '../../sources/sources';

const SecuritiesTable = ({
  currencies, filteredData, hasError, isLoading, maxHeight, searchable, source,
}) => {
  const [modalContent, setModalContent] = React.useState('');

  const showDetails = ({ original: security }) => {
    const hideModal = () => {
      setModalContent('');
    };

    setModalContent(
      <ResultsModal
        hideModal={hideModal}
        security={security}
        source={source}
      />,
    );
  };

  const actionsMenu = [{
    callback: showDetails,
    label: 'Details',
    type: 'info',
  }];

  return (
    <div>
      {modalContent}
      <CustomTable
        actionsMenu={actionsMenu}
        columns={source ? sources[source].columns(currencies) : []}
        data={filteredData}
        emptyMessage="You have no results yet. Run a search first."
        hasError={hasError}
        isLoading={isLoading}
        searchable={searchable}
        maxHeight={maxHeight}
      />
    </div>
  );
};

SecuritiesTable.propTypes = {
  filteredData: PropTypes.arrayOf(PropTypes.shape({

  })),
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxHeight: PropTypes.number.isRequired,
  searchable: PropTypes.bool.isRequired,
  source: PropTypes.string,
};

SecuritiesTable.defaultProps = {
  filteredData: [],
  hasError: false,
  isLoading: false,
  source: null,
};

export default SecuritiesTable;
