import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import TimePicker from '@symphony-ui/uitoolkit-components/components/time-picker/TimePicker';

import { getOptions } from 'utils/change';

import './FrequencyInput.scss';
import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';
import ToggleDecorator from '../toggle-decorator/ToggleDecorator';

const FrequencyInput = ({
  defaultPeriod, disabled, value: frequency, label, name, onChange, tooltip,
}) => {
  const [value, setValue] = React.useState({
    time: frequency !== null ? frequency.time : '',
    type: frequency !== null && frequency.type ? frequency.type : defaultPeriod,
    value: frequency !== null && Number.isInteger(frequency.value) ? frequency.value.toString() : '',

  });
  const [toggled, setToggle] = React.useState('true');

  React.useEffect(() => {
    let returnValue;
    if ('time' in value && value.time !== '') {
      returnValue = { time: value.time, zoneId: 'Europe/London' };
    } else if ('type' in value && value.type !== '' && value.value !== '') {
      returnValue = { type: value.type, value: Number.parseInt(value.value, 10) };
    } else {
      returnValue = null;
    }

    onChange({ target: { name, value: returnValue } });
  }, [value]);

  const valuePattern = '[1-9][0-9]{0,2}';

  const change = {
    input(event) {
      const regex = new RegExp(`^${valuePattern}$`, 'u');
      const newValue = event.target.value;
      if (regex.test(newValue) || newValue === '') {
        setValue({ ...value, value: newValue });
      }
    },
    time(event) {
      document.activeElement.blur();
      setValue({ ...value, time: event.target.value });
    },
    type(event) {
      document.activeElement.blur();
      setValue({ ...value, type: event.target.value.value });
    },

  };

  React.useEffect(() => {
    if (toggled === 'true' && value.type === '') {
      const newValue = {
        ...value, time: '', value: '', type: defaultPeriod,
      };
      setValue(newValue);
    } else if (toggled === 'false') {
      const newValue = { ...value, value: '' };
      setValue(newValue);
    }
  }, [toggled]);

  const options = [{ value: 'MINUTES', label: 'Mins' }, { value: 'HOURS', label: 'Hours' }];

  return (
    <div className="tk-input-group">
      <LabelTooltipDecorator label={label} tooltip={tooltip} />

      <div className="tk-input__container select-container">
        <ToggleDecorator toggled={toggled} setToggle={setToggle} />
        {toggled === 'true'
          ? (
            <TextField
              className="input-with-addon"
              disabled={disabled}
              label=""
              name="value"
              onChange={change.input}
              pattern={valuePattern}
              rightDecorators={(
                <Dropdown
                  className="select-addon"
                  isDisabled={disabled}
                  isTypeAheadEnabled={false}
                  label=""
                  onChange={change.type}
                  options={options}
                  value={getOptions(value.type, options)}
                />
              )}
              value={value.value}
            />
          )
          : ''}
        {toggled === 'false'
          ? (
            <TimePicker className="time-picker2" disabled={disabled} name="frequency" value={value.time} onChange={change.time} />
          )
          : ''}
      </div>
    </div>
  );
};

FrequencyInput.propTypes = {

  defaultPeriod: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    time: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

FrequencyInput.defaultProps = {
  defaultPeriod: 'MINUTES',
  disabled: false,
  value: null,
  label: '',
  tooltip: '',
};

export default FrequencyInput;
