import QualifiedSearch from './QualifiedSearch';
import TimeRange from './TimeRange';

class Query {
  constructor(query) {
    const timeFields = ['maturitydate', 'codecreationdate', 'maturity', 'update'];
    const qualifiedSearrchFields = ['q', 'rate'];

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (timeFields.includes(key)) {
          this[key] = new TimeRange(value);
        } else if (qualifiedSearrchFields.includes(key)) {
          this[key] = new QualifiedSearch(value);
        } else {
          this[key] = value;
        }
      });
    }
  }

  safe() {
    if (!this.codecreationdate) {
      this.codecreationdate = new TimeRange();
    }
    if (!this.maturitydate) {
      this.maturitydate = new TimeRange();
    }
  }
}

export default Query;
