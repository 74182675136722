const columns = {
  BORSA: [
    { accessor: 'security', header: 'Security' },
    { accessor: 'offeringPrice', header: 'Offering Price' },
    { accessor: 'market', header: 'Market' },
    { accessor: 'stockDescription', header: 'Stock Description' },
  ],
  EURONEXT: [
    { accessor: 'iponiDisplayTitle.name', header: 'Name' },
    { accessor: 'iponiIpoDate', header: 'Date' },
    { accessor: 'iponiTickerSymbol', header: 'Ticker' },
    { accessor: 'issueType', header: 'Issue Type' },
    { accessor: 'iponiPriceRange', header: 'Price Range' },
    { accessor: 'tradingLocation', header: 'Location' },
    { accessor: 'listingSponsor', header: 'Listing Sponsor' },
    { accessor: 'iponiWebSiteAddress', header: 'Web Site Address' },
    { accessor: 'iponiIsinCode', header: 'ISIN' },
    { accessor: 'iponiLeiCode', header: 'LEI' },
  ],
  HKSE: [],
  LSE: [
    { accessor: 'name', header: 'Name' },
    { accessor: 'priceRange', header: 'Price Range' },
    { accessor: 'market', header: 'Market' },
    { accessor: 'currency', header: 'Currency' },
    { accessor: 'type', header: 'Type' },
    { accessor: 'link', header: 'Link' },
  ],
  NASDAQ: [
    { accessor: 'companyName', header: 'Name' },
    { accessor: 'expectedPriceDate', header: 'Expected Price Date' },
    { accessor: 'proposedSharePrice', header: 'Proposed Share Price' },
    { accessor: 'proposedExchange', header: 'Proposed Exchange' },
    { accessor: 'proposedTickerSymbol', header: 'Ticker Symbol' },
  ],
  NYSE: [
    { accessor: 'issuerNm', header: 'Name' },
    { accessor: 'expected_dt_report', header: 'Expected Date' },
    { accessor: 'current_file_price_range_usd', header: 'Price Range' },
    { accessor: 'deal_status_desc', header: 'Deal Status' },
    { accessor: 'offer_px_usd', header: 'Offer Price' },
    { accessor: 'current_shares_filed', header: 'Shares Filed' },
  ],
  XETRA: [
    { accessor: 'name', header: 'Name' },
    { accessor: 'date', header: 'Date' },
    { accessor: 'bookbuildingRange', header: 'Bookbuilding Range' },
    { accessor: 'issuingMethod', header: 'Issuing Method' },
  ],

};

export default columns;
