import React from 'react';
import PropTypes from 'prop-types';

import { DropdownMenu, DropdownMenuItem } from '@symphony-ui/uitoolkit-components';

const ActionsMenu = ({
  actionsMenu, row, setShow, show, x, y,
}) => {
  const [itemList, setItemList] = React.useState([]);
  console.log('SHOW AT MENU: %o', show);
  const click = () => {
    document.removeEventListener('click', click);
    console.log('DOCUMENT CLICK HAS BEEN CLIECKD. Show is now: %o, setting to: %o', show, !show);
    setShow(false);
  };

  React.useEffect(() => {
    console.log('SHOW: %o', show);
  }, [show]);

  const action = (item) => () => {
    console.log('Menu item clicked. Show set to false');
    document.removeEventListener('click', click);
    setShow(false);
    item.callback(row);
  };

  React.useEffect(() => {
    console.log('Row changes');
    let theseActions = [];
    if (Array.isArray(actionsMenu)) {
      theseActions = actionsMenu;
    } else if (row !== null) {
      theseActions = actionsMenu(row);
    }

    //    const theseActions = Array.isArray(actionsMenu) ? actionsMenu : actionsMenu(row);
    const list = theseActions.map((item) => (
      <DropdownMenuItem
        key={item.label}
        onClick={action(item)}
      >
        {item.label}
      </DropdownMenuItem>
    ));

    setItemList(list);
  }, [row]);

  React.useEffect(() => {
    console.log('Adding one off eventlistener?');
    console.log('We are starting of with show: %o', show);
    if (show) {
      console.log('YES ADDING IT!!!');
      document.addEventListener('click', click);
    } else {
      console.log('NO REMOVING IT!!!');
      document.removeEventListener('click', click);
    }
  }, [show]);

  return (
    <DropdownMenu show={show} style={{ position: 'absolute', left: `${x}px`, top: `${y}px` }}>
      {itemList}
    </DropdownMenu>
  );
};

ActionsMenu.propTypes = {
  actionsMenu: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  row: PropTypes.shape({}),
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
};

ActionsMenu.defaultProps = {
  row: null,
  x: 0,
  y: 0,
};
export default ActionsMenu;
