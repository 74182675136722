import React from 'react';

import DownloadContext from 'contexts/DownloadContext';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';

import UnknownPage from 'components/unknown-page/UnknownPage';
import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import Ipo from '../../models/Ipo';
import IpoTable from './IpoTable';
import IpoForm from './IpoForm';

const Ipos = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [ipos, setIpos] = React.useState();
  const [exchangeOptions, setExchangeOptions] = React.useState([]);
  const [exchange, setExchange] = React.useState(null);

  const service = new CrudService({ download, name: 'Ipo', path: '/bb/ipo' });

  React.useEffect(() => {
    download.options('/bb/ipo/options', 'Error getting source options', setExchangeOptions);
  }, []);

  React.useEffect(() => {
    if (exchange !== null) {
      (async () => {
        const url = new ServerURL('/bb/ipo');
        url.searchParams.append('exchange', exchange);
        const request = new ServerRequest(url);
        const body = await download.json(request, 'Error downloading ipos');
        setLoading(false);
        if (body !== null) {
          console.log('BOYD: %o', body);
          const downloadedIpos = body.map((u) => new Ipo(u));
          setIpos(downloadedIpos);
        } else {
          setError(true);
        }
      })();
    }
  }, [exchange]);

  const handle = {
    create: async () => {
      setDetails(new Ipo());
      return setView('FORM');
    },
    save: (data) => async () => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedIpo = new Ipo(body);
        const updatedCurrencies = CrudService.updateData(ipos, updatedIpo);
        setIpos(updatedCurrencies);
        await setView('TABLE');
      }
    },
    delete: async ({ original: ipo }) => {
      const body = await service.delete(ipo);
      if (body) {
        const filteredIpos = ipos.filter((u) => u.id !== ipo.id);
        setIpos(filteredIpos);
      }
    },
    edit({ original: ipo }) {
      setDetails(ipo);
      setView('FORM');
    },

  };

  switch (view) {
    case 'FORM':
      return (
        <IpoForm
          handle={handle}
          ipo={details}
          sourceOptions={exchangeOptions}
        />
      );
    case 'TABLE':
      return (
        <IpoTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          ipos={ipos}
          exchangeOptions={exchangeOptions}
          setExchange={setExchange}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Ipos;
