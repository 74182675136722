import PropTypes from 'prop-types';
import React from 'react';

import { DropdownMenu, DropdownMenuDivider, DropdownMenuItem } from '@symphony-ui/uitoolkit-components/components/dropdown-menu/DropdownMenu';

import AppViewContext from 'contexts/AppViewContext';
import ServerRequest from 'models/ServerRequest';

const MenuItems = ({ show }) => {
  const { authorities, setAppView } = React.useContext(AppViewContext);

  const click = {
    app: (key) => () => {
      setAppView(key);
    },
    logout: async () => {
      const request = new ServerRequest('/logout', { method: 'POST' });
      await fetch(request);
      try {
        localStorage.removeItem('authorities');
      } catch (error) {
        // ignore in case no access to localStorage
      }
      window.location = window.location.href;
    },
    settings: () => {

    },
  };

  const closeMenu = (event) => {
    //    setMenuOpen(false);
  };

  const menuItems = React.useMemo(() => {
    const items = [
      (<DropdownMenuItem key="settings" onClick={click.settings}>Settings</DropdownMenuItem>),
      (<DropdownMenuDivider key="divider1" />),
    ];

    const apps = {
      CEDAR: 'Cedar',
      NIT: 'New Issue Tracker',
      ADMIN: 'Admin',
      MTN: 'MTN',
      SYND: 'Syndicate App',
    };

    Object.keys(authorities).forEach((key) => {
      items.push(
        (<DropdownMenuItem key={key} onClick={click.app(key)}>{apps[key]}</DropdownMenuItem>),
      );
    });

    items.push((<DropdownMenuDivider key="divider2" />));
    items.push((<DropdownMenuItem key="log-out" onClick={click.logout}>Log Out</DropdownMenuItem>));
    return items;
  });

  return (<DropdownMenu className="co-menu-dropdown" show={show} onClose={closeMenu}>{menuItems}</DropdownMenu>);
};

MenuItems.propTypes = {
  show: PropTypes.bool,
};

MenuItems.defaultProps = {
  show: false,
};

export default MenuItems;
