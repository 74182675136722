import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import ViewContext from 'contexts/ViewContext';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import Workflow from '../models/Workflow';

const WorkflowTable = ({
  handle, hasError, isLoading, workflows,
}) => {
  const { setView } = React.useContext(ViewContext);

  const columns = React.useMemo(() => [
    {
      accessor: 'name',
      header: 'Name',
      tooltip: 'Name of the workflow, which is also the name of the rooms in Symphony.',
    }, {
      accessor: (row) => row.latestDraft().version,
      header: 'Version',
      id: 'version',
      tooltip: 'Version name or number of the draft.',
    }, {
      accessor: (row) => new Date(row.latestDraft().deadline).toLocaleString().substring(0, 17),
      header: 'Deadline',
      id: 'deadline',
      tooltip: 'Deadline by which the draft needs to be approved by the parties.',
    }, {
      accessor: (row) => `(${row.latestDraft().approvals.length}/${row.parties.length})`,
      className: 'co-centered',
      header: 'Approved',
      id: 'approved',
      tooltip: 'Number of approved vs approvals needed',
    },
  ]);

  const actionsMenu = [
    {
      label: 'Details',
      callback: handle.details,
      type: 'info',
    },
    {
      label: 'Update',
      callback: handle.update,
      type: 'info',
    },
    {
      label: 'Send Reminder',
      callback: handle.remind,
      type: 'info',
    },
    {
      label: 'Close',
      callback: handle.close,
      type: 'error',
    },
  ];

  const click = {
    create: async () => {
      setView('CREATE');
    },
  };

  return (
    <Card>
      <CardHeaderTable create={click.create} disabled={hasError} title="Workflow Overview" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={workflows}
        emptyMessage="You have no work flows yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

WorkflowTable.propTypes = {
  handle: PropTypes.shape({
    close: PropTypes.func,
    create: PropTypes.func,
    details: PropTypes.func,
    remind: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  workflows: PropTypes.arrayOf(PropTypes.instanceOf(Workflow)).isRequired,
};

WorkflowTable.defaultProps = {
  hasError: false,
  isLoading: false,
};

export default WorkflowTable;
