import PropTypes from 'prop-types';
import React from 'react';

import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';

import Benchmark from 'models/Benchmark';

import { errorMessage } from 'utils/initialValidation';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import { change, validationChanged } from 'utils/change';

const BenchmarkForm = ({ benchmark, save }) => {
  const [data, setData] = React.useState(benchmark);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    name: Validators.Required,
  };

  const onChange = change(data, setData);
  const onValidationChange = validationChanged(errorState, setErrorState);

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      name="Benchmark"
      save={save}
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="benchmark-form">
        <Validation validator={validators.name} errorMessage={errorMessage} onValidationChanged={onValidationChange('name')}>
          <TextField label="Name" maxLength="24" name="name" onChange={onChange.input} value={data.name || ''} />
        </Validation>
      </div>
    </FormWrapper>
  );
};

BenchmarkForm.propTypes = {
  benchmark: PropTypes.instanceOf(Benchmark),
  save: PropTypes.func.isRequired,
};

BenchmarkForm.defaultProps = {
  benchmark: new Benchmark(),
};

export default BenchmarkForm;
