import { defaultPermissions } from 'components/metadata/utils';

class Report {
  constructor(report) {
    Object.assign(this, report);

    this.permissions = this.permissions || defaultPermissions;
    this.streamIds = this.streamIds || [];
  }

  setSearchId(value) {
    this.searchId = value;
    return this;
  }

  update(name, value) {
    return new Report({ ...this, [name]: value });
  }
}

export default Report;
