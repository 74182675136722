import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import sources from '../../sources/sources';

import './ResultsModal.css';

const ResultsModal = ({
  hideModal, security, source,
}) => {
  const values = [...sources[source].properties];

  const array = [];
  values.forEach((v) => {
    const array2 = v.field.split('.');
    let t = { ...security };
    for (let i = 0; i < array2.length; i += 1) {
      t = t[array2[i]];
    }
    let text = t;
    if (text instanceof Date) {
      text = text.toLocaleDateString();
    }
    if (typeof text === 'number') {
      text = (new Intl.NumberFormat()).format(text);
    }

    array.push(<span key={`${v.field}label`}>{v.label}</span>);
    array.push(<span key={`${v.field}value`}><b>{text || '-'}</b></span>);
  });

  return (
    <Modal className="co-items" show closeButton onClose={hideModal} parentNode={document.body} size="full-width">
      <div>
        <Typography type="h1">{security.shortname}</Typography>
      </div>
      <div className="security-grid">
        {array}
      </div>
    </Modal>
  );
};

ResultsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  security: PropTypes.shape({
    shortname: PropTypes.string,
  }).isRequired,
  source: PropTypes.string.isRequired,
};

export default ResultsModal;

/*
additionalspecificationname: ""
affidavitcode: "No certification required"
afterexchangeglobalformholder: "&nbsp;"
afterexchangephysicalform: "&nbsp;"
cficode: "DYZXXB"
clearancesLink: "https://my.euroclear.com/eb/en/reference/services/settlement/internal-settlement-basics.html"
clearancesauth: "Yes"
clearingid: "     "
clearstream: "No"
clearstreamLink: "https://my.euroclear.com/eb/en/reference/services/settlement/bridge-settlement-basics.html"
codecreationdate: "25 Feb 2021"
commoncode: "230934843"
commondepositary: "83"
commondepositarylabel: "DEUTSCHE BANK, FRANKFURT         "
commonsafekeeper: ""
currencylabel: "EUR"
deliveriesLink: "https://my.euroclear.com/eb/en/reference/services/settlement/external-settlement-basics.html"
deliveriesauth: "No"
depositaryid: null
depositarylabel: null
ecbhaircut: "      "
ecbtiering: "  "
effectiveexchangedate: ""
eventintrestperiodbegindate: ""
eventintrestperiodenddate: ""
eventintrestratefixingdate: ""
events351: null
events350362: []
fatca: "Not subject to FATCA withholding Tax"
fatcaLink: "https://my.euroclear.com/eb/en/reference/services/tax/Are-you-ready-for-FATCA.html"
firstclosingdate: "01 Mar 2021"
fisnshortname: "ALLIANZ SE/ZERO CPNEUR CP 20210601 "
futureevents: null
globalcertification: "&nbsp;"
globalnotetype: "Classical global note"
globalnotetypecheck: false
initialglobalformholder: "Both Euroclear and Clearstream"
initialphysicalform: "Permanent global"
interestRateLink: "https://my.euroclear.com/eb/en/reference/services/custody/income-and-redemption/calculation-method.html"
isin: "XS2309348436"
ismag: "Not applicable"
issuercategory: "Other financial institution"
issuercityname: "MUNICH          "
issuercountryname: "GERMANY        "
issuername: "ALLIANZ SE                       "
lastcouponamount: ""
lastexdate: ""
lastfixed: "No"
lastintrestcalculationdaycount: 0
lastintrestpaymentcurrencylabel: "EUR"
lastintrestrate: ""
lastpaymentdate: ""
lastrecorddate: ""
lastupdate: "25 Feb 2021"
lastvaluedate: ""
legalform: "Bearer"
lendingBorrowingLink: null
lendingBorrowingLinkLabel: null
lendingborrowing: "Not lendable"
marketClaimsLink: "https://my.euroclear.com/eb/en/reference/services/custody/market-claims-basics.html"
marketDirectoryLink: "https://my.euroclear.com/apps/en/market-directory.html"
marketInfoLink: null
marketInfoLinkLabel: null
marketclaims: "Neither processed nor reported in Euroclear"
marketlabel: "Euro"
marketnaturelabel: "Euro"
marketnaturetype: "80"
markettype: "70"
maturitydate: "01 Jun 2021"
minimaltradevalueamount: "500,000"
multipleamount: "1"
nextaccruedintrestdaycalculationrule: "      "
nextcouponamount: ""
nextexdate: ""
nextfixed: "No"
nextintrestcalculationdaycount: 0
nextintrestpaymentcurrencylabel: "EUR"
nextintrestrate: ""
nextpaymentdate: ""
nextrecorddate: ""
nexttyperate: "Zero coupon"
nextvaluedate: ""
opencities: []
paymentdefinition: "The payment processing indicator is expected to be displayed around 20 days before the next payment date (if any)"
paymentfrequencylabel: null
previousintrestperiodbegindate: ""
previousintrestperiodenddate: ""
quantitytype: "Face amount"
receiptauth: null
receiptsLink: "https://my.euroclear.com/eb/en/reference/services/settlement/external-settlement-basics.html"
receiptsauth: "Yes, free of payment only"
recorddes: null
remotebiccode: null
seasoningdate: ""
securityid: "BE47C4"
securitylastclosingdate: ""
securityname: "ALLIANZ SE (CP)"
securitynextclosingdate: "01 Mar 2021"
securitytaxwithholding: "No"
securitytype: "CP   "
securitytypelabel: "Commercial paper"
securitytypenaturelabel: "Debt"
securitytypenaturetype: "60"
serviceagents: []
settlementFinderLink: null
settlementFinderLinkLabel: null
settlementFormatFinderUrl: "https://my.euroclear.com/eb/en/reference/services/settlement/format-finders/common-depositories/common-dep-DB-Frankfurt-dep-83-settlement-format-finder.html"
settlementTimingLink: null
settlementTimingLinkLabel: null
settlementauth: "Yes"
shortname: "ALLIANZ SE          ZCP     01/06/21"
showcuponflag: false
specializeddepositary: ""
specializeddepositarylabel: null
tags: []
taxLinkUrl: null
taxMaxWithholdingPct: "0 %"
telexcertificate: "No certification"
totalissuedamount: "13,000,000"
us_euronext: null
worldHolidaysLink: null
worldHolidaysLinkLabel: null
*/
