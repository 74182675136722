import React from 'react';

import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';

import MyCard from '../MyCard';
import { localIconUrl as cedarIconUrl } from '../../../../cedar/utils/iconUrls';
import { localIconUrl as nitIconUrl } from '../../../../nit/utils/iconUrls';

import './Cards.scss';

const Cards = () => {
  const size = 32;
  const cedarImg = (<img alt="CEDAR logo" className="my-image" src={cedarIconUrl(size)} width={size} height={size} />);
  const certifiedImg = (<img className="my-image" alt="certification logo" src="/assets/SY2-Developer-Badges-FINAL-BOT-sml.png" width={size} height={size} />);
  const nitImg = (<img alt="NIT logo" className="my-image" src={nitIconUrl(size)} width={size} height={size} />);

  return (
    <div id="main-cards">
      <MyCard header="Secure by Design" icon={(<Icon iconName="lock" />)} text="Our products are hosted on Symphony, benefitting from the strong security environment of Symphony" />
      <MyCard header="Certified Developers" icon={certifiedImg} text="All our developers are Symphony / Certified Developers and Symphony / Certified Bot Developers." />
      <MyCard header="New Issue Tracker" icon={nitImg} text="Our New Issue Tracker notifies you of the latest securities issued." />
      <MyCard header="CEDAR" icon={cedarImg} text="Our Cross-Entity Document Approval and Review bot, helps speed up the workflow between capital markets lawyers." />
    </div>
  );
};

export default Cards;
