/**
 * Helper method to find the active tab stored in LocalStorage using the id (e.g. 'cedar-tab').
 *
 * @param {string} id
 */
export const activeTab = (id) => {
  let storedTab = null;
  try {
    storedTab = localStorage.getItem('tab');
    if (storedTab) {
      const tabObject = JSON.parse(storedTab);
      return tabObject[id] || '';
    }
  } catch (error) {
    // no access to localStorage
  }
  return '';
};

/**
 * Helper method to save the active tab in LocalStorage, in case we have access to LocalStorage.
 * This would not be the case under certain circumstances as an extension app.
 *
 * @param {string} code
 * @param {string} tab
 */
export const setActiveTab = (code, tab) => {
  try {
    const tabString = localStorage.getItem('tab');
    const tabObject = tabString ? JSON.parse(tabString) : {};
    tabObject[code] = tab;
    localStorage.setItem('tab', JSON.stringify(tabObject));
  } catch (error) {
    // no access to localStorage
  }
};

export const checkButton = (inputState, element = document.body) => {
  const hasValidationError = Object.entries(inputState).some(([, value]) => value === 'error');
  return element.querySelectorAll('input[value=""][data-required="true"]').length > 0 || hasValidationError;
};
