import PropTypes from 'prop-types';
import React from 'react';

import { Banner } from '@symphony-ui/uitoolkit-components';

import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import Download from 'services/Download';

const TabContainer = ({
  children, defaultView,
}) => {
  const [message, setMessage] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [view, setView] = React.useState(defaultView);
  const { setOffline } = React.useContext(AppViewContext);

  React.useEffect(() => {
    setView(defaultView);
  }, [defaultView]);

  const setError = () => {
    setOffline(true);
  };

  const download = new Download({ setError, setMessage, setOffline });

  const onClose = () => {
    setShow(false);
  };

  React.useEffect(() => {
    setShow(message !== null);
  }, [message]);

  return (
    <ViewContext.Provider value={{ view, setView }}>
      <MessageContext.Provider value={{ setError, setMessage }}>
        <DownloadContext.Provider value={{ download }}>
          <div className="base-box">
            {show ? (
              <Banner
                content={message !== null ? message.text : ''}
                isClosable
                onClose={onClose}
                show={show}
                variant={message !== null ? message.type : ''}
              />
            ) : ''}

            {children}
          </div>
        </DownloadContext.Provider>
      </MessageContext.Provider>
    </ViewContext.Provider>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  defaultView: PropTypes.node,
};

TabContainer.defaultProps = {
  defaultView: null,
};

export default TabContainer;
