import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import ServerRequest from 'models/ServerRequest';
import ViewContext from 'contexts/ViewContext';
import MessageContext from 'contexts/MessageContext';

import MetadataButtons from 'components/metadata/MetadataButtons';
import { checkEditable } from 'components/metadata/utils';
import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import { getOptions } from 'utils/change';
import Search from '../../models/Search';
import SearchNameInput from './SearchNameInput';
import EuroclearForm from './euroclear-search-form/EuroclearForm';
import AnnaForm from './anna-search-form/AnnaForm';
import EsmaForm from './esma-search-form/EsmaForm';
import SecForm from './sec-search-form/SecForm';
import IpoForm from './ipo-search-form/SecForm';

const SearchForm = ({
  database, handle, search, setDatabase, setSearch,
}) => {
  const { download } = React.useContext(DownloadContext);
  const { setMessage } = React.useContext(MessageContext);
  const { setView } = React.useContext(ViewContext);
  const { user } = React.useContext(AppViewContext);

  const [disabled, setDisabled] = React.useState(false);
  const [isSearching, setSearching] = React.useState(false);
  const [modalContent, setModalContent] = React.useState('');

  const databaseOptions = [
    { label: 'Euroclear', value: 'EUROCLEAR' },
    { label: 'ESMA', value: 'ESMA' },
    { label: 'ANNA', value: 'ANNA' },
    { label: 'SEC', value: 'SEC' },
    { label: 'IPO', value: 'IPO' },
  ];

  React.useEffect(() => {
    try {
      localStorage.setItem('search', JSON.stringify(search));
    } catch (error) {
      // unable to set search as no access to localStorage
    }
  }, [search]);

  React.useEffect(() => {
    try {
      localStorage.setItem('database', database);
    } catch (error) {
      // unable to set search as no access to localStorage
    }
  }, [database]);

  React.useEffect(() => {
    if (search[database] && search[database].id) {
      const isEditable = checkEditable(search, user);
      setDisabled(!isEditable);
    }
  }, []);

  const change = {
    database: (event) => {
      setDatabase(event.target.value.value);
    },
    input: (name) => (event) => {
      const updatedSearch = search[database].updateInput(name, event.target.value);
      setSearch(updatedSearch);
    },
    filter: (name) => (value) => {
      const updatedSearch = search[database].updateFilter(name, value);
      setSearch(updatedSearch);
    },
    permissions(event) {
      setSearch(new Search({ ...search, permissions: event }));
    },
    query: (name) => (value) => {
      const updatedSearch = search[database].updateQuery(name, value);
      setSearch(updatedSearch);
    },
  };

  const click = {
    clear: async () => {
      setSearch({ ...search, [database]: new Search() });
      setDisabled(false);
    },
    save: search[database] && search[database].name
      ? handle.update(database)
      : () => {
        const hideModal = () => {
          setModalContent('');
        };

        setModalContent(
          <SearchNameInput hideModal={hideModal} save={handle.create(database)} />,
        );
      },
    search: async () => {
      setSearching(true);
      const request = new ServerRequest(`/nit/${database.toLowerCase()}/query`, { body: JSON.stringify(search[database]), headers: { 'Content-Type': 'application/json' }, method: 'POST' });

      const body = await download.json(request, 'Error performing search');
      setSearching(false);
      if (body !== null) {
        setMessage({ text: `${body.length} securities found. Please view securities on the Results page.`, type: 'success' });
      }
    },
    table: async () => {
      await setView('TABLE');
    },
  };

  const setSpecificSearch = (source) => (value) => {
    const newSearch = { ...search, [source]: value };
    setSearch(newSearch);
  };

  return (
    <Card>
      {modalContent}
      <div className="card-header">
        <Typography type="h1">
          {search[database] ? search[database].name || 'Search' : 'Search'}
        </Typography>
      </div>

      <div className="co-container co-item">
        <Dropdown className="co-dropdown" isTypeAheadEnabled={false} label="Database" onChange={change.database} options={databaseOptions} value={getOptions(database, databaseOptions)} />
      </div>
      {(() => {
        switch (database) {
          case 'ANNA':
            return (<AnnaForm search={search.ANNA} setSearch={setSpecificSearch('ANNA')} />);
          case 'ESMA':
            return (<EsmaForm search={search.ESMA} setSearch={setSpecificSearch('ESMA')} />);
          case 'SEC':
            return (<SecForm search={search.SEC} setSearch={setSpecificSearch('SEC')} />);
          case 'IPO':
            return (<IpoForm search={search.IPO} setSearch={setSpecificSearch('IPO')} />);
          default:
            return (<EuroclearForm search={search.EUROCLEAR} setSearch={setSpecificSearch('EUROCLEAR')} />);
        }
      })()}
      <div className="co-form-buttons-plus co-item">
        <div className="co-form-buttons">
          <Button loading={isSearching} onClick={click.search}>Search</Button>
          <Button
            disabled={!checkEditable(search, user)}
            onClick={click.save}
          >
            Save
          </Button>
          <Button onClick={click.table}>Open</Button>
          <Button onClick={click.clear}>Clear</Button>
        </div>
        <MetadataButtons
          disabled={disabled}
          onChange={change.permissions}
          data={search[database] || {}}
        />
      </div>
    </Card>
  );
};

SearchForm.propTypes = {
  database: PropTypes.string.isRequired,
  handle: PropTypes.shape({
    create: PropTypes.func,
    update: PropTypes.func,
  }).isRequired,
  search: PropTypes.shape({
    ANNA: PropTypes.instanceOf(Search),
    ESMA: PropTypes.instanceOf(Search),
    EUROCLEAR: PropTypes.instanceOf(Search),
    SEC: PropTypes.instanceOf(Search),
    IPO: PropTypes.instanceOf(Search),
  }).isRequired,
  setDatabase: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default SearchForm;
