import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';
import { getOptionsMulti } from 'utils/change';

const MultiInput = ({
  label, name, onChange, values,
}) => {
  const [options, setOptions] = React.useState([]);

  const asyncOptions = async (inputText) => {
    const url = new ServerURL('/nit/esma/options');
    url.searchParams.append('name', name);
    url.searchParams.append('q', inputText);
    url.searchParams.append('values', values);
    const request = new ServerRequest(url);
    const response = await fetch(request);
    const body = await response.json();
    setOptions(body);
    return body;
  };

  return (
    <Dropdown
      asyncOptions={asyncOptions}
      label={label}
      isMultiSelect
      onChange={onChange}
      value={getOptionsMulti(values, options)}
    />
  );
};

MultiInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default MultiInput;
