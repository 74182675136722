import React from 'react';

import ConnectivityAlert from 'pages/ConnectivityAlert';

import CookieConsent from './CookieConsent';
import Footer from './footer/Footer';
import RightPanel from './right-panel/RightPanel';
import Cards from './cards/Cards';

import './front-page.scss';
import LeftPanel from './left-panel/LeftPanel';
import BottomPanel from './bottom-panel/BottomPanel';

const FrontPage = () => (
  <div id="front-page">
    <ConnectivityAlert />

    <div id="top-card">
      <LeftPanel />
      <RightPanel />
    </div>
    <Cards />
    <BottomPanel />
    <Footer />
    {!window.jwt ? <CookieConsent /> : ''}
  </div>
);

export default FrontPage;
