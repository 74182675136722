/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const PrivacyPolicy = ({ hideModal }) => (
  <div className="modal-content">

    <Typography type="small">First Implemented: November 30, 2020</Typography>

    <Typography>Protecting your privacy is important to Vanadis Limited (collectively, “Vanadis”, “us”, “our” or “we”).</Typography>

    <Typography>With this in mind, we’re providing this Privacy Policy to explain our practices regarding the collection, use and disclosure of personal data that we receive and process through symphony.capitalmarkets.online and our other websites (collectively, the “Site”) and our cloud-based communication service which provides a secure, efficient and robust ecosystem for high-valued information exchange (the "Vanadis Services” and together with the Site, the “Services”). “Personal data”, as used in this Privacy Policy, refers to any data, information, or combination of data and information that is collected or processed by Vanadis, and which relates to an identifiable individual.</Typography>

    <Typography>If you are using a non-paid version of the Vanadis Services (whether through the Site, through your mobile phone, as a sponsored institution on the Symphony Community Pod, or otherwise), then you are referred to in this Privacy Policy as a “Direct User.”</Typography>
    <Typography>If you are an authorized user (generally an employee) of a company (the “Company”) that has paid to use the Vanadis Services in the workplace pursuant to a services agreement (“MSA”) then you are referred to in this Privacy Policy as an “Authorized User.”</Typography>
    <Typography>If you are a visitor to the Site, then you are referred to in this Privacy Policy as a “Visitor.”</Typography>
    <Typography>Visitors, Direct Users and Authorized Users are referred to in this Privacy Policy collectively as “Users.”  Certain sections of this Privacy Policy will apply or be read differently depending on which type of User you are.  This is because under applicable data protection law, we are deemed to be the “data controller” for Direct Users and Visitors’ data, and a “data processor” for Authorized Users’ data.  For Visitors and Direct Users located in the European Union (EU) or the United Kingdom (UK), the legal basis of Symphony’s processing of the foregoing information is further described at the end of this page. For Authorized Users located in the EU or UK, your Company is the data controller with respect to the processing of your data.</Typography>

    <Typography>This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our Services. Additionally, this Privacy Policy should be read in conjunction with the end user license agreement applicable to your particular use of the Services (each, a “EULA”). Please make sure that you have read and understand the EULA. For Visitors, the applicable EULA is our Terms of Service, and for Direct Users, the applicable EULA is available at https://symphony.com/public-eula/.</Typography>

    <Typography type="h2">1. Collection and Use of Information</Typography>
    <Typography>Our primary goals in collecting and using information are to provide and improve our Services, to administer your use of the Services (including your Account, as defined below), to respond to your comments and questions, to use your email address or other contact information to send you information related to the Services and to enable you to enjoy and easily navigate our Services. Vanadis has implemented and will maintain reasonable security controls to protect the confidentiality, integrity and availability of the personal data that Vanadis processes.</Typography>

    <Typography>Visitors.  If you are a Visitor, Vanadis will collect the following information about you:</Typography>

    Information Collected Using Cookies and Other Web Technologies. Like many website operators, we use automated data collection tools such as Cookies and Web Beacons to collect certain information about your use of the Site.
    “Cookies” are small Typography files that are placed on your device by a Web server when you access our Services. We use both session Cookies and persistent Cookies to identify that you’ve accessed the Site, logged in to the Services, and to tell us how and when you interact with our Services. We also use Cookies to monitor aggregate usage and web traffic routing on our Services, to customize and improve our Services, and to deliver relevant content and advertising to you. Please note, however, that if you don’t accept Cookies, you will not be able to access certain portions or features of the Services. When you access our Site, certain third-party services providers that we engage could also place their own Cookies on your device. Note that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third parties.
    “Web Beacons” (also known as Web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that could be included on our Services for several purposes, including to deliver or communicate with Cookies, to track and measure the performance of our Services and to monitor how many visitors view our Services.
    For more details as to how we use these technologies in connection with the Services, please refer to our Cookie Policy.
    Other Information. You may choose to provide us with other information by contacting us or by submitting your information through our Services (“Other Information”). For example, if you submit your contact information through our Services, such as to request more information about the Services or to register for events or webinars, we will use your contact information to send Symphony-related communications that may be of interest to you, including information about the Vanadis Services, promotions, or upcoming events.
    Direct Users and Authorized Users.  If you are a Direct User or an Authorized User, we will collect and use the following information about you in connection with your use of the Services:

    Account Information. To use the Vanadis Services, you will need to create an account (if you are a Direct User) or your Company will create an account for you (if you are an Authorized User) (each, an “Account”). When an Account is created, we’ll collect certain personal data that can be used to identify you, such as your name, title, job title, location, email alias, email address and, in certain cases, profile photo and other biographical information about you. If you are an Authorized User and your Account is created on behalf of your organization, we will also collect certain information about your organization. We will only use such personal data and other information for the purposes of providing the Vanadis Services to you (if you are a Direct User) and your Company (if you are an Authorized User) or notifying you about relevant information relating to your use of the Vanadis Services.
    Other Information.  Other Information provided to us by Direct Users and Authorized Users may include, but is not limited to, user feedback and user support requests, in which case, we will collect your name and email address as well as any other content included in your communication, in order to send you a reply or in order to improve the Services.
    Information Related to Use of the Services. We collect certain information about how our Services are used (we refer to this information as “Usage Data”). Usage Data could include information such as a User’s Internet Protocol (IP) address, browser type, operating system, a User’s interactions and/or activities within the Services, including the pages or features of our Services to which a User browsed, the time spent on those pages or features, frequency with which our Services and its features are used by a User, search terms, and other statistics. We use Usage Data to administer the Services and we analyze (and could engage third parties, who are under an obligation of confidentiality, to analyze) Usage Data to improve, customize, and enhance our Services. For Authorized Users, all use of your Usage Data will be subject to the terms and conditions of the MSA. Any use of Usage Data by third parties on our behalf will be subject to requirements that such third parties access such Usage Data on an unattributed basis (such that the identity of the individual User cannot be ascertained by the third party) and that such third parties maintain the confidentiality and integrity of such Usage Data.
    Posted Data. When you use the Vanadis Services to post, send, or receive messages (including voice or video chat messages), files or any other information or Typography, audio, or video communication exchange posted to the Vanadis Services, we will transmit and store the content of such exchange (“Posted Data”) as necessary to provide the Vanadis Services.
    Information Sent by Your Device. We collect certain information that your device may send when you use our Services, like a device identifier, user settings, and the operating system of your device, as well as information about your use of our Services. Such information helps us to, and will only be used in order to, improve, customize and enhance our Services.
    Location Information. When you use our Services and you enable location services on your device, we will collect and store information about your location by converting your IP address into a rough geo-location or by accessing your device’s location services (if applicable), including GPS coordinates or coarse location. We will only use location information to improve and personalize our Services for you. How much of this information we collect depends on the type and settings of the device you use to access the Services.
    Please note that if you decide not to provide us with the personal data that we or the Services request, you will not be able to access or use certain features of the Services.

    Retention of Personal Data.

    Authorized Users.  If you are an Authorized User, Symphony will retain your personal data in accordance with your Company’s instructions, including any applicable terms in the MSA, and subject to the requirements of applicable law.
    Visitors & Direct Users.  Symphony will retain your personal data for the period necessary to fulfill the purposes described in this Privacy Policy unless a longer retention period is required or permitted by law.
    2. Information that We Share with Others
    We will not share any personal data that we have collected from or regarding you except as described below, and in the sections of this Privacy Policy entitled “Where We Send Your Personal Data” and “Collection and Use of Information”.

    Information Shared with Our Service Providers. We engage certain service providers to work with us to administer and provide a portion of the Services. Such service providers include, but are not limited to:

    customer relationship management software providers, including Salesforce, headquartered in the US, which process certain personal data globally, in order to assist us in providing the Services and contacting you;
    Internet hosting and cloud service provider services, such as Amazon Web Services and Google Cloud Platform, headquartered in the US, which process certain personal data globally and enable us to offer a Software as a Service (SaaS) platform;
    customer support services and software, headquartered in the US and which process certain personal data globally, which allow us to deal with any service issues that Users may face and respond quickly to any questions about our Services;
    business analytics and billing service providers, headquartered in the US and which process certain personal data in the US, which allow us to provide the Services to you and better understand how the Services are used; and
    a software engineering service provider, headquartered in Brazil and which processes certain personal data in Brazil and the US, which allows us to efficiently build and maintain our platform.
    These service providers have access to and use personal data only for the purpose of performing services on our behalf, and in compliance with applicable laws and regulations (including, without limitation, the CAN-SPAM Act of 2003, the EU General Data Protection Regulation (“GDPR”) and the Privacy Shield (as defined below), as applicable). Such performance can include the processing of personal data, provided that no such service provider has access to your unencrypted Posted Data.

    Such service providers will be required to maintain the confidentiality of all personal data that they process on our behalf and to implement and maintain reasonable security controls to protect the confidentiality, integrity and availability of such personal data. For Authorized Users, Symphony will comply with the terms and conditions of the MSA in effect between Symphony and your Company (who is the data controller of your personal data) in connection with any such onward transfer of your personal data. Any such service provider to whom Symphony transfers personal data for processing on behalf of Symphony is also required to only employ staff who have committed themselves to confidentiality or are under a statutory obligation of confidentiality with respect to your personal data. Symphony could remain legally accountable for the protection of your personal data that we transfer to our service providers.

    Symphony will also take reasonable steps to confirm that any such service provider processes such personal data in a manner that provides at least the same level of data protection as is provided for by this Privacy Policy and, for Authorized Users, as is required of us pursuant to the MSA in place between Symphony and your Company. Lastly, Symphony will obligate any such service provider to notify Symphony if such provider becomes unable to satisfy such data protection obligations, and Symphony will take reasonable steps to stop and remediate unauthorized or noncompliant processing by such service provider, upon becoming aware of such processing.

    Aggregated and De-identified Information Shared with Third Parties. We could collect and share aggregated and de-identified information with third parties for industry research and analysis, demographic profiling and other similar purposes, and for third-party programs to access the Services in a manner that extends the Symphony user experience and helps us operate and improve the Services.

    Information Disclosed in Connection with Business Transactions. Information that we collect from our Users, including personal data, is considered to be a business asset. Thus, if we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your personal data, will be disclosed or transferred to a third-party acquirer in connection with the transaction. The disclosure and transfer of any of your personal data to such third-party acquirer will be done in compliance with applicable law and regulation (including but not limited to the GDPR and Privacy Shield, as the case may be), and only as necessary in order to enable Symphony or the relevant acquiror to continue to perform services to you or your Company.

    Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. In certain circumstances, we will disclose any of your personal data to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas), law enforcement requests and national security requests; (ii) to protect our rights and safety and the rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity. In the event of such disclosure or transfer of your personal data to public authorities, there is a chance that Symphony will not be able to require such public authorities to implement and maintain reasonable security controls to protect the confidentiality, integrity and availability of your personal data.

    Information Shared with Other Users. For Direct Users and Authorized Users, the information that is listed in your user profile, including, but not limited to, your profile photo, your organization and your name (collectively “Profile Data”), will be published and viewable by other Users in the Symphony directory of Users so that other Users can find you using the “Search” feature in the Vanadis Services. For Authorized Users, the content of your Profile Data and its publication in the Symphony directory of Users will be controlled by your Company.

    3. Where We Send Your Personal Data
    As our Users and Services operate globally, we may need to transfer personal data outside of your country of residence in order to provide the Services, and therefore your personal data may be transferred to and processed in countries that may not be deemed to provide the same level of data protection as the country in which you reside.  When we transfer personal data from the European Economic Area (EEA), the UK, or Switzerland to other countries, we make sure that such personal data is sent with appropriate safeguards, including the following:

    Model Clauses: Symphony utilizes the European Commission’s Model Clauses, also known as the Standard Contractual Clauses pursuant to Decision 2010/87/EU, to ensure an adequate level of protection for the transfer of personal data to third countries such as the United States.
    Privacy Shield: Although Symphony Communication Services, LLC complies with and is self-certified under the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework (together, the “Privacy Shield”), we are not currently relying on the Privacy Shield as the legal mechanism for transfers of personal data to the United States. Symphony Communication Services, LLC commits to adhere to, and has certified to the U.S. Department of Commerce that it adheres to, the Privacy Shield Principles of notice, choice, accountability for onward transfer, security, data integrity and purpose limitation, access, recourse, enforcement and liability, as well as the Supplemental Privacy Shield Principles, in each case with respect to personal data that Symphony Communication Services, LLC receives within the scope of the Privacy Shield. If there is any conflict between the terms in this Privacy Policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.  To learn more about the Privacy Shield program, and to view Symphony Communication Services, LLC’s certification, please visit https://www.privacyshield.gov and https://www.privacyshield.gov/list, respectively.
    VeraSafe Privacy Program. Symphony is a member of the VeraSafe™ Privacy Program, meaning that VeraSafe has assessed Symphony’s data governance for compliance with the VeraSafe Privacy Program Certification Criteria. The certification criteria require that participants maintain a high standard for data privacy and implement specific best practices pertaining to notice, onward transfer, choice, access, data security, data quality, recourse and enforcement.

    The Federal Trade Commission, the relevant EU data protection authorities, the UK Information Commissioner’s Office, and the Swiss Federal Data Protection and Information Commissioner (as the case may be) have jurisdiction to investigate and enforce any issues relating to compliance with or certification to the Privacy Shield. Symphony will cooperate and comply with the dispute resolution panel established by the relevant EU data protection authorities, UK Information Commissioner’s Office, and the Swiss Federal Data Protection and Information Commissioner (as the case may be) in addition to the VeraSafe Dispute Resolution Procedure outlined in Section 7 below.

    4. Your Choices – EU & UK Users
    If you are a User located in the EU or the UK, then depending on whether you are an Authorized User, a Visitor, or a Direct User, we will offer you certain choices regarding the collection, use and sharing of your personal data.

    Authorized Users. If you are an Authorized User, the personal data associated with your Account and your Profile Data is managed by your Company’s administrator. Symphony can only act on the instructions of your Company, so you must contact them directly to exercise any rights over your personal data.

    Visitors and Direct Users.  If you are a Visitor or a Direct User, you have specific rights over the personal data that Symphony controls which you can exercise in specific circumstances, such as:

    Access: to know whether we process personal data about you, to access that personal data and find out how we use it and who we share it with;
    Portability: to receive a subset of the personal data we collect from you in a structured, commonly-used and machine-readable format, and to request that we transfer such personal data to another party.
    Correction: to require us to correct personal data about you that is accurate or incomplete;
    Erasure: to request that we erase personal data we hold about you in certain circumstances. Note that in cases where we grant your request for deletion, copies of erased personal data could remain in archived/backup copies for our records, as we are not always able to delete information from those locations;
    Restriction: to require us to stop processing the personal data we hold about you other than for storage purposes in certain circumstances; and
    Objection: to object to our processing of personal data about you and we will consider your request.
    Please contact us at legal@symphony.com with such requests. We will respond to your request as soon as we reasonably can and we will attempt to respond to all requests within 30 days of verifying your identity.

    5. Access and Choice – Swiss Users
    If you are a Visitor or Direct User located in Switzerland, then we will:

    provide you with access to personal data about you which we hold, and allow you to correct, amend, or delete that information where it is inaccurate, or has been processed in violation of the Swiss-U.S. Privacy Shield Principles, in each case to the extent required under such Principles;
    permit you to choose (“Choice”) whether your personal data is (i) to be disclosed to a third party or (ii) to be used for a purpose that is materially different from the purpose(s) for which it was originally collected or subsequently authorized by you, in each case to the extent required under the Swiss-U.S. Privacy Shield Principles; and
    to the extent we receive sensitive personal data from you, obtain your affirmative express consent prior to disclosing such sensitive personal data to a third party or using it for a purpose other than for those for which such personal data was originally collected or subsequently authorized by you.
    You may exercise your Choice rights by contacting legal@symphony.com.

    6. California Users
    If you are a California resident, please refer to our Supplemental Privacy Notice for California Residents.

    7. Questions; Complaints; Other
    Questions?
    If you have any questions about this Privacy Policy or our treatment of your information, please write to our General Counsel by email at legal@symphony.com or by postal mail at:

    Symphony Communication Services, LLC
    1117 S. California Avenue
    Palo Alto, CA 94304
    USA
    Attn: General Counsel

    For the purposes of EU data protection laws, our representative in the EU is Symphony Communication Services Sweden AB, registered at c/o Head Office, Grev Turegatan 3 (Trapphus D, vån 6), SE-114 6 Stockholm, Sweden with the contact email address legal@symphony.com.

    Complaints and Data Requests Under the Privacy Shield. You can at any time submit a complaint, personal data access request or communicate any other issues arising under the Privacy Shield with respect to your use of the Services or Symphony’s processing of your personal data to Symphony’s General Counsel at legal@symphony.com, or by courier to:

    Symphony Communication Services, LLC
    1117 S. California Avenue
    Palo Alto, CA 94304
    USA
    Attn: General Counsel

    Our London-based Office Manager, a representative of our UK subsidiary, Symphony Communication Services UK Ltd., can also be used as a point of contact for Europe-based and UK-based Users. The address of our UK office is:

    Symphony Communication Services UK Ltd.
    US&Co Monument
    7 Harp Lane
    London EC3R 6DP

    Further, our Head of Sales – APAC, a representative of our Hong Kong subsidiary, Symphony Communication Services Hong Kong Ltd., can also be used as a point of contact for Asia-based Users. The address of our Hong Kong office is:

    Symphony Communication Services Hong Kong Ltd.
    Spaces 4/F – 6/F
    Lee Garden 3
    1 Sunning Road
    Causeway Bay
    Hong Kong

    Symphony will respond promptly to any such complaints or inquiries, within one month from the date on which we receive such complaint or inquiry and have verified your identity. This is without prejudice to your right to launch a complaint with the data protection authority in the UK or in the EEA country in which you live or work.

    If a privacy complaint or dispute cannot be resolved through Symphony’s internal process, Symphony has agreed to participate in the VeraSafe Dispute Resolution Procedure. Subject to the terms of the VeraSafe Dispute Resolution Procedure, VeraSafe will provide appropriate recourse free of charge to you. To file a complaint with VeraSafe and participate in the VeraSafe Dispute Resolution Procedure, please submit the required information here: https://www.verasafe.com/privacy-services/dispute-resolution/submit-dispute/.

    In addition, under certain conditions, you could be entitled to invoke binding arbitration to resolve a complaint or dispute arising under the Privacy Shield.

    Conflict
    Notwithstanding any other provisions of this Privacy Policy, nothing in this Privacy Policy will be interpreted to expand Symphony’s rights under the privacy and data processing provisions of any agreement applicable to you, and specifically:

    Authorized Users: If you are an Authorized User, in the event of any conflict or inconsistency between the provisions of this Privacy Policy, the MSA with your Company, and the EULA, the applicable provision of the MSA shall govern.
    Direct Users: If you are a Direct User, in the event of any conflict or inconsistency between the provisions of this Privacy Policy and the EULA, the applicable provision of the EULA shall govern.
    Visitors: If you are a Visitor, in the event of any conflict or inconsistency between the provisions of this Privacy Policy and the Terms of Service governing use of the Site, the applicable provision of this Privacy Policy shall govern.
    Our Policy Toward Children
    You must be at least 18 years of age to use the Services. Our Services are not directed to individuals under 18 and we do not knowingly collect personal data from individuals under 18. If we learn that we have collected personal data of an individual under 18, we will take steps to delete such information from our files.

    8. Revisions to this Privacy Policy
    Any personal data that is collected via our Services is processed in accordance with the Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from time to time. We’ll update the “Last Updated” date at the top of this page to indicate when the Privacy Policy was last materially changed.

    If we make any material changes to this Privacy Policy that adversely impact or decrease the privacy and security of personal data, we’ll notify you as follows:

    Authorized Users: We’ll send your Company a notification at least 30 days prior to such change taking effect and provide your Company with a link to such updated Privacy Policy so they can notify you, and we will update the Site.
    Visitors and Direct Users: We will update the Site and comply with any notification requirements under applicable law.
    Legal Basis Table
    (see Section 1 for further information)

    Please note that this table applies only to Visitors and Direct Users located in the EU and the UK where Symphony is acting as the data controller.  If you are an Authorized User, please contact your Company for information about its legal basis for processing your data.

    Data Type

    Processing Purpose

    Legal Basis

    Account Information

    to notify you about changes to our Services;
    to provide you with user support;
    to enforce our terms, conditions and policies;
    to communicate with you;
    to administer the Vanadis Services and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes
    Contract
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    Other Information (as defined above)

    to make sure that the Services work for you and to provide you with user support;
    to respond to your requests or inquiries;
    to send you Symphony-related communications
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    Consent
    Information collected using cookies and other web technologies

    to make sure that the Services work properly for you;
    to improve, customize, and enhance our Services;
    to deliver relevant content to you
    Consent
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    Usage Data

    to provide the Services to you
    to improve, customize, and enhance our Services
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    Posted Data

    to provide the Services to you and fulfill our contract with you
    Contract
    Information Sent by your Device

    to improve, customize, and enhance the Services
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    Location information

    to provide you with location-based services and improve, customize, and enhance our Services
    Consent
    Legitimate Interests (among others, to make sure that our Services function properly and meet your needs)
    <Button onClick={hideModal}>OK</Button>
  </div>
);

PrivacyPolicy.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
