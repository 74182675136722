import PropTypes from 'prop-types';
import React from 'react';

import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import FormWrapper from 'components/form-wrapper.js/FormWrapper';
import { change, getOptions, validationChanged } from 'utils/change';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import { errorMessage } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import TermSheet from '../../model/TermSheet';

const TermSheetForm = ({
  currencyOptions, interestBasisOptions, issuerOptions, save, termSheet,
}) => {
  const [data, setData] = React.useState(termSheet);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    issuer: CustomValidators.RequiredOption,
    currency: CustomValidators.RequiredOption,
    nominal: Validators.Required,
    interestBasis: CustomValidators.RequiredOption,
    issueDate: Validators.Required,
    maturityDate: Validators.Required,
    coupon: Validators.Required,
  };

  const onChange = change(data, setData);
  const onValidationChanged = validationChanged(errorState, setErrorState);

  return (
    <FormWrapper
      data={data}
      errorState={errorState}
      name="Term Sheet"
      save={save}
      setErrorState={setErrorState}
      validators={validators}
    >
      <div className="base-form" id="termsheet-form">

        <Validation validator={validators.issuer} errorMessage={errorMessage} onValidationChanged={onValidationChanged('issuer')}>
          <Dropdown isInputClearable label="Issuer" name="issuer" onChange={onChange.dropdown} options={issuerOptions} value={getOptions(data.issuer, issuerOptions)} />
        </Validation>

        <Validation validator={validators.currency} errorMessage={errorMessage} onValidationChanged={onValidationChanged('currency')}>
          <Dropdown label="Currency" name="currency" onChange={onChange.dropdown} options={currencyOptions} value={getOptions(data.currency, currencyOptions)} />
        </Validation>

        <Validation validator={validators.nominal} errorMessage={errorMessage} onValidationChanged={onValidationChanged('nominal')}>
          <TextField label="Nominal" name="nominal" onChange={onChange.input} value={data.nominal || ''} />
        </Validation>

        <Validation validator={validators.issueDate} errorMessage={errorMessage} onValidationChanged={onValidationChanged('issueDate')}>
          <TextField label="Issue Date" name="issueDate" onChange={onChange.input} value={data.issueDate || ''} />
        </Validation>

        <Validation validator={validators.maturityDate} errorMessage={errorMessage} onValidationChanged={onValidationChanged('maturityDate')}>
          <TextField label="Maturity Date" name="maturityDate" onChange={onChange.input} value={data.maturityDate || ''} />
        </Validation>

        <Validation validator={validators.interestBasis} errorMessage={errorMessage} onValidationChanged={onValidationChanged('interestBasis')}>
          <Dropdown label="Interest Basis" name="interestBasis" onChange={onChange.dropdown} options={interestBasisOptions} value={getOptions(data.interestBasis, interestBasisOptions)} />
        </Validation>

        <Validation validator={validators.coupon} errorMessage={errorMessage} onValidationChanged={onValidationChanged('coupon')}>
          <TextField label="Coupon" name="coupon" onChange={onChange.input} value={data.coupon || ''} />
        </Validation>
      </div>

    </FormWrapper>
  );
};

TermSheetForm.propTypes = {
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  save: PropTypes.func.isRequired,
  interestBasisOptions: PropTypes.arrayOf(PropTypes.shape({})),
  issuerOptions: PropTypes.arrayOf(PropTypes.shape({})),
  termSheet: PropTypes.instanceOf(TermSheet),
};

TermSheetForm.defaultProps = {
  currencyOptions: [],
  interestBasisOptions: [],
  issuerOptions: [],
  termSheet: new TermSheet(),
};

export default TermSheetForm;
