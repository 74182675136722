import Period from './Period';

class PeriodRange {
  constructor(periodRange) {
    Object.assign(this, periodRange);

    if (this.start) {
      this.start = new Period(this.start);
    }
    if (this.end) {
      this.end = new Period(this.end);
    }
  }

  toString() {
    if (this.start && this.end) {
      if (this.start.period === this.end.period
        && this.start.periodMultiplier === this.end.periodMultiplier) {
        return this.start.toString();
      }
      return `from ${this.start.toString()} to ${this.end.toString()}`;
    } if (this.start && !this.end) {
      return `from ${this.start.toString()}`;
    } if (!this.start && this.end) {
      return `up to ${this.end.toString()}`;
    }
    return 'Any';
  }

  update(name, value) {
    return new PeriodRange({ ...this, [name]: value });
  }

  clean() {
    const clone = new PeriodRange(this);
    if (clone.start === null) {
      delete clone.start;
    }
    if (clone.end === null) {
      delete clone.end;
    }
    return clone;
  }

  isEmpty() {
    if ('start' in this && this.start !== null && !this.start.isEmpty()) return false;
    if ('end' in this && this.end !== null && !this.end.isEmpty()) return false;
    return true;
  }
}

export default PeriodRange;
