import PropTypes from 'prop-types';
import React from 'react';

const FileInput = ({
  disabled, id, label, name, onChange,
}) => {
  const setValue = (event) => {
    const { files } = event.target;
    onChange({
      target: {
        name, value: files[0],
      },
    });
  };

  return (
    <div className="tk-input-group">
      <div className="tk-input-group__header">
        {label ? (<label className="tk-label" htmlFor={id}>{label}</label>) : ''}
      </div>
      <div className="tk-input__container">
        <input
          className="tk-input"
          disabled={disabled}
          id={id}
          name={name}
          onChange={setValue}
          type="file"
        />
      </div>
    </div>
  );
};

FileInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FileInput.defaultProps = {
  disabled: false,
  id: '',
  label: '',
};

export default FileInput;
