import { defaultPermissions } from 'components/metadata/utils';
import Filter from './Filter';
import Option from './Option';
import Query from './Query';
import TimeRange from './TimeRange';

class Search {
  constructor(search) {
    Object.assign(this, search);

    this.filter = new Filter(this.filter);
    this.query = new Query(this.query);

    this.permissions = this.permissions || defaultPermissions;
  }

  static init() {
    const search = new Search();
    search.query.maturitydate = new TimeRange();
    search.query.codecreationdate = new TimeRange();
    return search;
  }

  safe() {
    this.query.safe();
  }

  // TODO #288 Fix Search table bug
  toTableRow(options) {
    //    console.log('Options is %o for currency %o', options, this.query.currency);
    switch (this.database) {
      case 'ESMA':
        return {
          codecreationdate: '?',
          currency: '?',
          data: this,
          maturitydate: '?',
          name: this.name,
        };
      case 'ANNA':
        return {
          codecreationdate: this.query.update ? this.query.update.toString() : '',
          currency: this.query.issueCurrency,
          data: this,
          maturitydate: this.query.maturitydate ? this.query.maturitydate.toString() : '',
          name: this.name,
        };
      case 'SEC':
        return {
          codecreationdate: '?',
          currency: '?',
          data: this,
          maturitydate: '?',
          name: this.name,
        };
      default:
        return {
          codecreationdate: this.query.codecreationdate ? this.query.codecreationdate.toString() : '',
          currency: options.currency ? this.query.currency.map((c) => Option.find(options.currency, c)).join(', ') : '',
          data: this,
          maturitydate: this.query.maturitydate ? this.query.maturitydate.toString() : '',
          name: this.name,
        };
    }
  }

  updateQuery(name, value) {
    const updatedSearch = new Search({ ...this });
    if ((value && Array.isArray(value) && !value.length)
   || (value && value instanceof TimeRange && value.isEmpty())) {
      delete updatedSearch.query[name];
    } else {
      updatedSearch.query[name] = value;
    }
    return updatedSearch;
  }

  updateFilter(name, value) {
    const updatedSearch = new Search({ ...this });
    if ((value && Array.isArray(value) && !value.length)) {
      delete updatedSearch.filter[name];
    } else {
      updatedSearch.filter[name] = value;
    }
    return updatedSearch;
  }

  updateInput(name, value) {
    return new Search({ ...this, [name]: value });
  }

  clone() {
    return new Search({ ...this });
  }
}

export default Search;
