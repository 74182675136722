import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { getOptions } from 'utils/change';

const BaseCurrency = () => {
  const [currencies, setCurrencies] = React.useState([]);
  const [currency, setCurrency] = React.useState(null);
  const [storageAvailable, setStorageAvailable] = React.useState(false);

  const { download } = React.useContext(DownloadContext);

  const change = {
    currency(event) {
      setCurrency(event.target.value.value);
      localStorage.setItem('base-currency', event.target.value.value);
    },
  };

  React.useEffect(() => {
    /**
    * Only when the user has access to localStorage can he choose the base currency
    */
    try {
      const storedValue = localStorage.getItem('base-currency') || 'EUR';
      setCurrency(storedValue);
      setStorageAvailable(true);

      (async () => {
        const request = new ServerRequest('/nit/currencies');
        const body = await download.json(request);
        if (body !== null) {
          setCurrencies(body.sort().map((c) => ({ value: c, label: c })));
        }
      })();
    } catch (error) {
      setStorageAvailable(false);
    }
  }, []);

  return storageAvailable
    ? (
      <Card className="co-card-stack">
        <div className="co-item">
          <Typography type="h1">Base Currency</Typography>
        </div>
        <div className="co-info co-item">
          <Dropdown
            label="Base currency from which equivalent amounts are calculated."
            name="period"
            onChange={change.currency}
            options={currencies}
            value={getOptions(currency, currencies)}
          />
        </div>
      </Card>
    )
    : '';
};

export default BaseCurrency;
