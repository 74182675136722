import sources from '../sources/sources';
import Security from '../models/Security';

const eqvAmount = (currencies, security, source) => {
  const currency = security[sources[source].fields.currency];
  if (currencies && currency in currencies && security.totalissuedamount) {
    let baseRate = 1;
    try {
      const baseCurrency = localStorage.getItem('base-currency');
      if (baseCurrency) baseRate = Number.parseFloat(currencies[baseCurrency]);
    } catch (error) {
      //
    }
    const fxRate = Number.parseFloat(currencies[currency]) / baseRate;
    const total = Number.parseInt(security[sources[source].fields.amount].replaceAll(',', ''), 10);
    return new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format(total / fxRate);
  }
  return '-';
};

const markupCurrencies = (source, securities, currencies) => securities
  .sort(Security.sort)
  .map((s) => ({
    ...s,
    eqvamount: Object.keys(currencies).length ? eqvAmount(currencies, s, source) : '',
  }));

export default markupCurrencies;
