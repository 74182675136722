import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Switch from '@symphony-ui/uitoolkit-components/components/selection/Switch';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';

import AppViewContext from 'contexts/AppViewContext';
import MetadataButtons from 'components/metadata/MetadataButtons';
import { checkEditable } from 'components/metadata/utils';

import { getOptions, validationChanged } from 'utils/change';
import {
  errorMessage, initialValidation, checkDisabled,
} from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';

import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import { TimePicker } from '@symphony-ui/uitoolkit-components';
import Report from '../../models/Report';
import FrequencyInput from '../frequency-input/FrequencyInput';

const ReportForm = ({ handle, options, report }) => {
  const { user } = React.useContext(AppViewContext);

  const [data, setData] = React.useState(report);
  const [disabled, setDisabled] = React.useState(false);
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});
  const [title, setTitle] = React.useState('');

  const validators = {
    frequency: CustomValidators.Frequency,
    search: CustomValidators.RequiredOption,
    name: Validators.Required,

  };

  const onChange = {
    dropdown: (name) => (event) => {
      const { value } = event.target;
      const newValue = value ? value.map((v) => v.value) : null;
      setData(data.update(name, newValue));
    },
    frequency(event) {
      const { name, value } = event.target;
      console.log('New Value: %o', value);
      const newReport = data.update(name, value);
      setData(newReport);
    },
    input(event) {
      const { name, value } = event.target;
      setData(data.update(name, value));
    },
    permissions(event) {
      setData(data.update('permissions', event));
    },
    select(event) {
      const newValue = event.target.value;
      const [source, searchId] = newValue ? newValue.value.split('|') : [null, null];
      const newData = new Report({ ...data, source, searchId });

      setData(newData);
    },
  };

  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    if (data.id) {
      const isEditable = checkEditable(report, user);
      setDisabled(!isEditable);
      setTitle(isEditable ? 'Edit Report' : 'Report');
    } else {
      setTitle('Reports');
    }
    console.log('INITIAL REPORT: %o', report);

    (async () => {
      const initialErrorState = await initialValidation(validators, data);
      console.log('Setting initial error state: %o based on data: %o', initialErrorState, data);
      setErrorState(initialErrorState);
    })();
  }, []);

  React.useEffect(() => {
    console.log('ErrorState: %o', errorState);
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  // Give a name to the Report so that you can easily find it later.
  return (
    <Card>
      <CardHeaderForm title={title} />
      <div className="base-form">
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('search')} validator={validators.search}>
          <Dropdown isDisabled={disabled} label="Search to run" onChange={onChange.select} name="search" options={options} value={data.source ? getOptions(`${data.source}|${data.searchId}`, options) : ''} />
        </Validation>

        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('name')} validator={validators.name}>
          <TextField disabled={disabled} id="name" label="Name" name="name" value={data.name || ''} onChange={onChange.input} tooltip="" />
        </Validation>

        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('frequency')} validator={validators.frequency}>
          <FrequencyInput
            defaultPeriod="MINUTES"
            disabled={disabled}
            label="Frequency"
            name="frequency"
            onChange={onChange.frequency}
            tooltip="Set how often you want the report to run, and post into the stream. You can toggle whether you want it to run every day at a fixed time, or at an interval of a fixed number of minutes or hours."
            value={data.frequency}
          />
        </Validation>

        <Switch disabled={disabled} label="Show Empty Search" value={data.showEmpty ? 'true' : 'false'} name="showEmpty" onChange={onChange.input} tooltip="Post the search result in the stream even if not new securities have been found." />
      </div>

      <div className="co-form-buttons-plus co-item">
        <Button disabled={disabledButton} onClick={handle.save(data)}>Save</Button>
        <MetadataButtons data={data} onChange={onChange.permissions} disabled={disabled} />
      </div>
    </Card>
  );
};

ReportForm.propTypes = {
  handle: PropTypes.shape({
    save: PropTypes.func,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  report: PropTypes.instanceOf(Report),
};

ReportForm.defaultProps = {
  report: new Report(),
};

export default ReportForm;
