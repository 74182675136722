import React from 'react';
import PropTypes from 'prop-types';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import AppViewContext from 'contexts/AppViewContext';
import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import { checkEditable } from 'components/metadata/utils';

import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import Order from '../../model/Order';

const OrderTable = ({
  handle, hasError, isLoading, orders,
}) => {
  const { download } = React.useContext(DownloadContext);
  const { user } = React.useContext(AppViewContext);

  const [data, setData] = React.useState([]);
  const [emptyMessage, setEmptyMessage] = React.useState('');
  const [newIssues, setNewIssues] = React.useState([]);

  const columns = React.useMemo(() => [
    actionCell,
    { accessor: 'investorName', header: 'Investor' },
    { accessor: 'newIssue', header: 'New Issue' },
    { accessor: 'amount', header: 'Amount' },
    { accessor: 'createdDate', header: 'Created' },
    { accessor: 'lastModifiedDate', header: 'Last Modified' },
  ]);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/synd/new-issues/map');
      const foundNewIssues = await download.json(request, 'Error downloading new issues from server.');
      setNewIssues(foundNewIssues);
    })();
  }, []);

  const actionsMenu = (order) => [{
    label: checkEditable(order, user) ? 'Edit' : 'View',
    callback: handle.edit,
    type: 'info',
  },
  {
    label: 'Delete',
    callback: handle.delete,
    type: 'error',
  },
  ];

  const mapOrderToRow = (order) => ({
    data: order,
    createdDate: new Date(order.createdDate).toLocaleString(),
    investorName: order.investorName,
    newIssue: newIssues[order.newIssueId],
    amount: new Intl.NumberFormat().format(order.amount),
    lastModifiedDate: new Date(order.lastModifiedDate).toLocaleString(),
  });

  React.useEffect(() => {
    if (orders.length === 0 && !isLoading) {
      setEmptyMessage('You have no Orders yet');
    } else {
      const newData = orders.map(mapOrderToRow);
      setData(newData);
    }
  }, [orders, newIssues]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Orders" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={data}
        emptyMessage="You have no Orders yet"
        hasError={hasError}
        loading={isLoading}
      />
    </Card>
  );
};

OrderTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.instanceOf(Order)),
};

OrderTable.defaultProps = {
  hasError: false,
  isLoading: false,
  orders: [],
};

export default OrderTable;
