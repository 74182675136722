import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import apiSource from 'utils/system/apiSource';
import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';
import CustomValidators from 'components/CustomValidators';
import { change, validationChanged } from 'utils/change';

const ResetForm = ({ setMessage, setTitle, switchForm }) => {
  const [data, setData] = React.useState({});
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const validators = {
    email: CustomValidators.Email,
  };

  const click = {
    reset: async () => {
      setLoading(true);
      const url = apiSource('/reset-password');
      const init = {
        body: data.email,
        method: 'POST',
      };
      try {
        const response = await fetch(url, init);
        if (response.status === 204) {
          switchForm('TOKEN');
        } else {
          setMessage({ text: 'Error obtaining token', type: 'error' });
          switchForm('LOGIN');
        }
      } catch (error) {
        setMessage({ text: `Error connecting to the Server: ${error.message}`, type: 'error' });
        switchForm('LOGIN');
      }
    },
  };
  const onChange = change(data, setData);

  const onValidationChanged = validationChanged(errorState, setErrorState);

  React.useEffect(() => {
    setTitle('Enter your email address to reset Password');

    (async () => {
      const newErrorObject = await initialValidation(validators, data);
      setErrorState(newErrorObject);
    })();
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <div>
      <div className="co-item">
        <Validation errorMessage={errorMessage} onValidationChanged={onValidationChanged('email')} validator={validators.email}>
          <TextField label="Email Address" name="email" onChange={onChange.input} value={data.email || ''} />
        </Validation>
      </div>

      <div className="co-form-buttons co-item">
        <Button
          disabled={disabledButton}
          loading={loading}
          onClick={click.reset}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

ResetForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  switchForm: PropTypes.func.isRequired,
};

export default ResetForm;
