export const dateSort = ({ original: data1 }, { original: data2 }, colId) => {
  const date1 = data1[colId];
  const date2 = data2[colId];
  if (date1 === 'Perpetual' && date2 === 'Perpetual') {
    return 0;
  } if (date1 === 'Perpetual' && date2 !== 'Perpetual') {
    return 1;
  } if (date1 !== 'Perpetual' && date2 === 'Perpetual') {
    return -1;
  }
  if (date1 === '' && date2 === '') {
    return 0;
  } if (date1 === '' && date2 !== '') {
    return -1;
  } if (date1 !== '' && date2 === '') {
    return 1;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  if (d1 > d2) {
    return 1;
  } if (d2 > d1) {
    return -1;
  }
  return 0;
};

export const realDateSort = ({ original: data1 }, { original: data2 }, colId) => {
  const date1 = data1[colId];
  const date2 = data2[colId];
  if (date1 === 'Perpetual' && date2 === 'Perpetual') {
    return 0;
  } if (date1 === 'Perpetual' && date2 !== 'Perpetual') {
    return 1;
  } if (date1 !== 'Perpetual' && date2 === 'Perpetual') {
    return -1;
  }
  if (date1 === '' && date2 === '') {
    return 0;
  } if (date1 === '' && date2 !== '') {
    return -1;
  } if (date1 !== '' && date2 === '') {
    return 1;
  }
  if (date1 > date2) {
    return 1;
  } if (date2 > date1) {
    return -1;
  }
  return 0;
};

export const numberSort = ({ original: data1 }, { original: data2 }, colId) => {
  const number1 = data1[colId];
  const number2 = data2[colId];

  const n1 = number1 === '' || number1 === null ? Number.NEGATIVE_INFINITY : Number(number1.replaceAll(',', ''));
  const n2 = number2 === '' || number2 === null ? Number.NEGATIVE_INFINITY : Number(number2.replaceAll(',', ''));
  if (n1 > n2) {
    return 1;
  } if (n2 > n1) {
    return -1;
  }
  return 0;
};

export const realNumberSort = ({ original: data1 }, { original: data2 }, colId) => {
  const number1 = data1[colId] || Number.NEGATIVE_INFINITY;
  const number2 = data2[colId] || Number.NEGATIVE_INFINITY;
  if (number1 > number2) {
    return 1;
  } if (number2 > number1) {
    return -1;
  }
  return 0;
};

export const rateSort = (rowA, rowB, colId) => {
  const number1 = rowA.original[colId];
  const number2 = rowB.original[colId];

  const n1 = number1 === '' ? Number.NEGATIVE_INFINITY : Number(number1.replaceAll('%', ''));
  const n2 = number2 === '' ? Number.NEGATIVE_INFINITY : Number(number2.replaceAll('%', ''));
  if (n1 > n2) {
    return 1;
  } if (n2 > n1) {
    return -1;
  }
  return 0;
};

export const printAsDate = (field) => (row) => (row[field] instanceof Date
  ? row[field].toLocaleDateString() : row[field]);

export const dateCell = (id, header) => ({
  accessor: (row) => (row[id] instanceof Date ? row[id].toLocaleDateString() : row[id]),
  className: 'co-centered',
  header,
  id,
  sortType: realDateSort,
});

export const numberCell = (id, header) => ({
  className: 'co-table-right co-table-number',
  header,
  id,
  accessor: (row) => (row[id] ? (new Intl.NumberFormat()).format(row[id]) : ''),
  sortType: realNumberSort,
});

export const eqvAmountCell = (currencyId, amountId, currencies) => {
  let baseCurrency = null;
  try {
    baseCurrency = localStorage.getItem('base-currency');
  } catch (error) {
    //
  }
  const baseRate = baseCurrency ? currencies[baseCurrency] : 1;
  return {
    accessor: (row) => ((currencies && row[currencyId] in currencies && row[amountId])
      ? new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format((row[amountId] * baseRate) / currencies[row[currencyId]])
      : '-'),
    className: 'co-table-right co-table-number',
    header: `Eqv in ${baseCurrency || 'EUR'}`,
    id: 'eqvAmount',
    sortType: numberSort,
  };
};

export const actionCell = {
  className: 'co-table-actions',
  hasActions: true,
  header: '',
  id: 'actions',
  sortable: false,
};
