import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import { getOptions } from 'utils/change';

const LEIInput = ({ onChange, values }) => {
  const [options, setOptions] = React.useState([]);
  const [defaultOptions, setDefaultOptions] = React.useState([]);

  const fetchOptions = { headers: { Accept: 'application/vnd.api+json' } };

  React.useEffect(() => {
    if (values.length && values[0]) {
      (async () => {
        const leiUri = new URL(`/api/v1/lei-records/${values[0]}`, 'https://api.gleif.org');
        const response = await fetch(leiUri, fetchOptions);
        const { data } = await response.json();
        const newOptions = [{
          label: data.attributes.entity.legalName.name,
          value: data.id,
        }];
        setDefaultOptions(newOptions);
        setOptions(newOptions);
      })();
    }
  }, []);

  const asyncOptions = async (inputText) => {
    if (inputText.length) {
      const leiUri = new URL('/api/v1/fuzzycompletions', 'https://api.gleif.org');
      leiUri.searchParams.append('field', 'entity.legalName');
      leiUri.searchParams.append('q', inputText);
      const response = await fetch(leiUri, fetchOptions);
      const { data } = await response.json();
      const newOptions = data.filter((o) => 'relationships' in o).map((o) => ({ label: o.attributes.value, value: o.relationships['lei-records'].data.id }));
      setOptions(newOptions);
      return newOptions;
    }
    return [];
  };

  return (
    <Dropdown
      asyncOptions={asyncOptions}
      defaultOptions={defaultOptions}
      label="Issuer Name"
      onChange={onChange}
      value={getOptions(values[0], options)}
    />
  );
};

LEIInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

LEIInput.defaultProps = {
  values: [],
};
export default LEIInput;
