class QualifiedSearch {
  constructor(qualifiedSearch) {
    Object.assign(this, qualifiedSearch);
    if (!this.qualifier) {
      this.qualifier = 'sw';
    }
    if (!this.text) {
      this.text = [];
    }
  }

  toString() {
    return `${this.qualified} ${this.text.join(', ')}`;
  }

  clean() {
    return this.text.length && this.qualifier ? this : null;
  }

  updateQualifier(value) {
    return new QualifiedSearch({ ...this, qualifier: value });
  }

  chooseTag(value) {
    return new QualifiedSearch({ ...this, text: [...this.text, value.toUpperCase()] });
  }

  clearTag() {
    return new QualifiedSearch({ ...this, text: [] });
  }

  removeTag(removingValue) {
    return new QualifiedSearch({
      ...this,
      text: this.text.filter((el) => el !== removingValue.value),
    });
  }

  static options = [{ value: 'sw', label: 'Starts with' }, { value: 'eq', label: 'Equal to' }, { value: 'ct', label: 'Contains' }]
}
export default QualifiedSearch;
