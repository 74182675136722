import React from 'react';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Benchmark from '../../models/Benchmark';

import BenchmarkTable from './BenchmarkTable';
import BenchmarkForm from './BenchmarkForm';

const Benchmarks = () => {
  const { view, setView } = React.useContext(ViewContext);
  const { download } = React.useContext(DownloadContext);

  const [benchmarks, setBenchmarks] = React.useState();
  const [details, setDetails] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  const service = new CrudService({ download, name: 'Benchmark', path: '/core/benchmarks' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const downloadedBenchmarks = body.map((u) => new Benchmark(u));
        setBenchmarks(downloadedBenchmarks);
      } else {
        setError(true);
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setDetails(new Benchmark());
      await setView('FORM');
    },

    delete: async ({ original: benchmark }) => {
      const body = await service.delete(benchmark);
      if (body) {
        const filteredBenchmarks = benchmarks.filter((u) => u.id !== benchmark.id);
        setBenchmarks(filteredBenchmarks);
      }
    },
    edit: async ({ original: benchmark }) => {
      setDetails(benchmark);
      await setView('FORM');
    },
    save: async (data) => {
      const body = await (data.id ? service.update(data) : service.create(data));

      if (body !== null) {
        const updatedBenchmark = new Benchmark(body);
        const updatedCurrencies = CrudService.updateData(benchmarks, updatedBenchmark);
        setBenchmarks(updatedCurrencies);
        await setView('TABLE');
      }
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <BenchmarkForm
          benchmark={details}
          save={handle.save}
        />
      );
    case 'TABLE':
      return (
        <BenchmarkTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          benchmarks={benchmarks}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Benchmarks;
