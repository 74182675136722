import React from 'react';

import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './LeftPanel.scss';

const LeftPanel = () => (
  <div id="left-panel">
    <Typography id="main-title" type="h1">CapitalMarkets Online</Typography>
    <Typography id="sub-title" type="h2"> Building bots and apps for Symphony</Typography>
  </div>
);

export default LeftPanel;
