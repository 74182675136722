import PropTypes from 'prop-types';
import React from 'react';

const ToggleDecorator = ({ toggled, setToggle }) => {
  const click = () => {
    const newToggle = toggled === 'true' ? 'false' : 'true';
    document.activeElement.blur();
    setToggle(newToggle);
  };

  const keyUp = (event) => {
    console.log('Key up: %o', event);
  };

  return (
    <i
      aria-label="Switch between Frequency and Time"
      className={`tk-icon-chevron-${toggled === 'true' ? 'right' : 'left'} tk-input__icon`}
      onClick={click}
      onKeyUp={keyUp}
      role="button"
      tabIndex={0}
    />
  );
};

ToggleDecorator.propTypes = {
  setToggle: PropTypes.func.isRequired,
  toggled: PropTypes.string,
};

ToggleDecorator.defaultProps = {
  toggled: false,
};

export default ToggleDecorator;
