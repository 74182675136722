import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import './CookieConsent.scss';

const CookieConsent = () => {
  const [agreed, setAgreed] = React.useState(localStorage.getItem('accept-cookie'));

  const handleClick = () => {
    setAgreed(true);
    localStorage.setItem('accept-cookie', true);
  };

  if (agreed === null) {
    return (
      <div id="cookie-consent">
        <div id="cookie-consent-text">
          <Typography>
            This site uses cookies to enhance the user experience
          </Typography>
        </div>
        <Button onClick={handleClick}>I agree</Button>
      </div>
    );
  }
  return '';
};

export default CookieConsent;
