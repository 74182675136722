import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import TrancheObject from '../../model/Tranche';

const TrancheOverview = ({
  tranche, setTranche,
}) => (
  <div vertical>
    <div className="co-item">
      <Typography variant="bold">
        {`${tranche.currency} - ${tranche.term ? tranche.term : tranche.maturity} years`}
      </Typography>
    </div>

    <div className="co-item">
      <Typography>
        {'Spread Guidance: '}
        <b>{tranche.spreadGuidance}</b>
        {` over ${tranche.benchmark}`}
      </Typography>
      <Button>Revise spread guidance</Button>
    </div>

    <div className="co-item">
      <Typography>Size ordersbook: </Typography>
    </div>
  </div>
);

TrancheOverview.propTypes = {
  setTranche: PropTypes.func.isRequired,
  tranche: PropTypes.instanceOf(TrancheObject).isRequired,
};

export default TrancheOverview;
