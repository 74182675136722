import {
  dateCell, rateSort, numberCell, eqvAmountCell, actionCell,
} from '../../core/utils/table';

const parseDate = (date) => {
  if (date === 'Perpetual') {
    return date;
  }
  return new Date(date);
};

const parseNumber = (number) => (number ? number.replaceAll(',', '') : number);

const parseRate = (number) => (number ? number.replaceAll('%', '') : number);

const euroclear = {
  objectify(security) {
    return {
      ...security,
      firstclosingdate: parseDate(security.firstclosingdate),
      maturitydate: parseDate(security.maturitydate),
      nextintrestrate: parseRate(security.nextintrestrate),
      totalissuedamount: parseNumber(security.totalissuedamount),
    };
  },
  columns(currencies) {
    return [
      actionCell,
      { accessor: 'issuername', className: 'co-table-text co-table-text-l', header: 'Name' },
      { accessor: 'currencylabel', className: 'co-centered', header: 'Currency' },
      numberCell('totalissuedamount', 'Amount'),
      eqvAmountCell('currencylabel', 'totalissuedamount', currencies),
      dateCell('maturitydate', 'Maturity Date'),
      dateCell('firstclosingdate', 'First Closing Date'),
      { accessor: 'nexttyperate', header: 'Rate Type' },
      {
        accessor: 'nextintrestrate', className: 'co-table-rate', header: 'Interest Rate', sortType: rateSort,
      },
      { accessor: 'securitytypelabel', header: 'Security Type' },
      { accessor: 'issuercityname', header: 'City' },
      { accessor: 'issuercountryname', header: 'Country' },
      { accessor: 'cfi.category', header: 'Category' },
      { accessor: 'cfi.group', className: 'co-table-text co-table-text-l', header: 'Group' },
      { accessor: 'cfi.attribute1', header: 'Attribute 1' },
      { accessor: 'cfi.attribute2', header: 'Attribute 2' },
      { accessor: 'cfi.attribute3', header: 'Attribute 3' },
      { accessor: 'cfi.attribute4', header: 'Attribute 4' },
      { accessor: 'tags', header: 'Tags' },
    ];
  },
  companyColumns: [
    actionCell,
    { accessor: 'name', header: 'Name', sortable: true },
    { accessor: 'cityname', header: 'City', sortable: true },
    { accessor: 'countryname', header: 'Country', sortable: true },
    { accessor: 'category', header: 'Category', sortable: true },
  ],
  companyMatcher: (security) => (c) => c.company.category === security.issuercategory
    && c.company.cityname === security.issuercityname
    && c.company.countryname === security.issuercountryname
    && c.company.name === security.issuername,
  companySortField: 'name',
  fields: {
    amount: 'totalissuedamount',
    cfi: 'cficode',
    currency: 'currencylabel',
    issuername: 'issuername',
  },
  getCurrency: (search, options) => (search.query.currency ? options.filter((o) => search.query.currency.includes(o.value)).map((o) => o.label).join(', ') : ''),
  getCreationDate: (search) => (search.query.codecreationdate ? search.query.codecreationdate.toString() : ''),
  getMaturity: (search) => (search.query.maturitydate ? search.query.maturitydate.toString() : ''),
  properties: [
    { label: 'Issuer Name', field: 'issuername', width: 36 },
    { label: 'ISIN', field: 'isin', width: 16 },
    { label: 'Currency', field: 'currencylabel', width: 6 },
    {
      label: 'Amount', field: 'totalissuedamount', width: 16, type: 'number',
    },
    {
      label: 'First Closing Date', field: 'firstclosingdate', width: 14, type: 'date',
    },
    {
      label: 'Maturity Date', field: 'maturitydate', width: 14, type: 'date',
    },
    {
      label: 'Code Creation Date', field: 'codecreationdate', width: 14, type: 'date',
    },
    {
      label: 'Last Update', field: 'lastupdate', width: 14, type: 'date',
    },
    { label: 'Issuer Category', field: 'issuercategory', width: 24 },
    { label: 'Issuer City', field: 'issuercityname', width: 18 },
    { label: 'Issuer Country', field: 'issuercountryname', width: 18 },
    { label: 'Security Type', field: 'securitytypelabel', width: 20 },
    { label: 'Rate Type', field: 'nexttyperate', width: 14 },
    { label: 'Rate', field: 'nextintrestrate', width: 14 },
    { label: 'Market', field: 'marketlabel', width: 20 },
    {
      label: 'Min Trade Amount', field: 'minimaltradevalueamount', width: 12, type: 'number',
    },
    { label: 'Category', field: 'cfi.category' },
    { label: 'Group', field: 'cfi.group', width: 36 },
    { label: 'Attribute1', field: 'cfi.attribute1', width: 20 },
    { label: 'Attribute2', field: 'cfi.attribute2', width: 20 },
    { label: 'Attribute3', field: 'cfi.attribute3', width: 20 },
    { label: 'Attribute4', field: 'cfi.attribute4', width: 20 },
    { label: 'Legal Form', field: 'legalform', width: 12 },
    { label: 'Common Depositary', field: 'commondepositarylabel', width: 36 },
    { label: 'Tags', field: 'tags', type: 'array' },
  ],
};

export default euroclear;
