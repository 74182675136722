import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

import './Footer.scss';

const Footer = () => {
  const [modalContent, setModalContent] = React.useState('');

  const hideModal = () => {
    setModalContent('');
  };
  const click = {
    cookiePolicy() {
      setModalContent(
        <Modal parentNode={document.body} show size="full-width">
          <CookiePolicy hideModal={hideModal} />
        </Modal>,
      );
    },
    privacyPolicy() {
      setModalContent(
        <Modal parentNode={document.body} show size="full-width">
          <PrivacyPolicy hideModal={hideModal} />
        </Modal>,
      );
    },
    termsOfService() {
      setModalContent(
        <Modal parentNode={document.body} show size="full-width">
          <TermsOfService hideModal={hideModal} />
        </Modal>,
      );
    },

  };

  return (
    <div id="co-footer">
      {modalContent}
      <div id="co-footer-buttons">
        <Button onClick={(click.privacyPolicy)} variant="tertiary">Privacy Policy</Button>

        <Button onClick={click.cookiePolicy} variant="tertiary">Cookie policy</Button>

        <Button onClick={click.termsOfService} variant="tertiary">Terms of Service</Button>
      </div>

      <div id="co-copyright">
        <Typography type="small">
          &#169; 2021 Vanadis Ltd. All rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
