import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';

import { checkDisabled, errorMessage, initialValidation } from 'utils/initialValidation';

import CustomValidators from 'components/CustomValidators';
import FileInput from 'components/file-input/FileInput';
import DateTime from 'components/date-time/DateTime';

import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import Workflow from '../models/Workflow';

const Update = ({ details, handle }) => {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [data, setData] = React.useState({ deadline: new Date(), version: 'initial' });
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [errorState, setErrorState] = React.useState({});

  const validators = {
    deadline: Validators.Required,
    file: CustomValidators.RequiredFile,
    version: Validators.Required,
  };

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const getFormData = () => {
    const formData = new FormData();
    const jsonOption = { type: 'application/json' };

    const draft = {
      deadline: data.deadline,
      version: data.version,
      filename: data.file.name,
      contentType: data.file.contentType,
    };
    formData.append('id', details.workflowId);
    formData.append('draft', new Blob(
      [JSON.stringify(draft)],
      jsonOption,
    ));
    formData.append('file', data.file);
    return formData;
  };

  const click = {
    update: async () => {
      setButtonLoading(true);
      handle.updateDraft(getFormData());
    },
  };

  /**
   * This function checks when the value is changed. As this does
   * not happen for files it has to be done manually.
   *
   * @param {*} name
   * @returns
   */
  const changevalidation = (name) => (isValid) => {
    setErrorState({ ...errorState, [name]: isValid });
  };

  React.useEffect(() => {
    (async () => {
      const newErrorState = await initialValidation(validators, data);
      setErrorState(newErrorState);
    })();
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  return (
    <Card>
      <CardHeaderForm title={`New draft for ${details.name}`} />

      <div className="base-form">
        <Validation validator={validators.version} errorMessage={errorMessage} onValidationChanged={changevalidation('version')}>
          <TextField label="Version" maxLength="20" name="version" onChange={change.input} value={data.version || ''} />
        </Validation>

        <Validation validator={validators.deadline} errorMessage={errorMessage}>
          <DateTime label="Deadline" name="deadline" onChange={change.input} value={data.deadline} />
        </Validation>

        <Validation validator={validators.file} errorMessage={errorMessage}>
          <FileInput label="File" name="file" onChange={change.input} />
        </Validation>
      </div>

      <div className="form-buttons">
        <Button
          disabled={disabledButton}
          loading={buttonLoading}
          onClick={click.update}
        >
          Create New Draft
        </Button>
      </div>
    </Card>
  );
};

Update.propTypes = {
  details: PropTypes.instanceOf(Workflow).isRequired,
  handle: PropTypes.shape({
    updateDraft: PropTypes.func,
  }).isRequired,
};

export default Update;
