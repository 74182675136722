import React from 'react';

import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import ServerURL from 'models/ServerUrl';
import ServerRequest from 'models/ServerRequest';

import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Comment from '../models/Comment';
import Update from './Update';
import Create from './Create';
import Details from './Details';
import Workflow from '../models/Workflow';
import WorkflowTable from '../components/WorkflowTable';

const Workflows = () => {
  const { download } = React.useContext(DownloadContext);
  const { setMessage } = React.useContext(MessageContext);
  const { view, setView } = React.useContext(ViewContext);

  const [details, setDetails] = React.useState({});
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoaded] = React.useState(true);
  const [update, setUpdate] = React.useState({});
  const [workflows, setWorkflows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest('/workflows');
      const body = await download.json(request, 'Error getting Workflows from server.');
      setLoaded(false);

      if (body !== null) {
        const test = body.map((w) => new Workflow(w));
        setWorkflows(test);
      } else {
        setError(true);
      }
    })();
  }, []);

  const quickUpdate = (newDetails) => {
    setDetails(newDetails);
    const index = workflows.findIndex((d) => d.workflowId === newDetails.workflowId);
    const newData = [...workflows];
    if (index === -1) {
      newData.push(newDetails);
    } else {
      newData[index] = newDetails;
    }

    setWorkflows(newData);
  };

  const handle = {
    save: async (formData) => {
      const request = new ServerRequest('/workflows/create', { body: formData, method: 'POST' });

      const body = await download.json(request, 'Error creating workflow');
      if (body !== null) {
        const updatedWorkflowObject = new Workflow(body);
        const combinedWorkflows = workflows.concat(updatedWorkflowObject);
        setWorkflows(combinedWorkflows);
        await setView('TABLE');
      }
    },
    amend: (type) => async (row) => {
      const formData = new FormData();
      formData.append('workflowId', details.workflowId);
      formData.append('party', row.values.party);
      const request = new ServerRequest(`/document/${type}`, { body: formData, method: 'POST' });

      const body = await download.json(request, 'Error approving workflow');
      if (body !== null) {
        const updatedWorkflowObject = new Workflow(body);
        quickUpdate(updatedWorkflowObject);
      }
    },
    close: async ({ original: { workflowId } }) => {
      const url = new ServerURL('/workflows/close');
      url.searchParams.append('id', workflowId);
      const request = new ServerRequest(url, { method: 'DELETE' });

      const isDeleted = await download.noContent(request, 'Error closing workflow');
      if (isDeleted) {
        const filteredWorkflows = workflows.filter((w) => w.workflowId !== workflowId);
        setWorkflows(filteredWorkflows);
        setMessage({ text: 'Workflow closed succesfully.', type: 'success' });
      }
    },
    details({ original: workflow }) {
      setDetails(workflow);
      setView('DETAILS');
    },
    remind: async ({ original: { workflowId } }) => {
      const url = new ServerURL('/workflows/remind');
      url.searchParams.append('id', workflowId);
      const request = new ServerRequest(url);

      const isReminded = await download.noContent(request, 'Error sending reminders');
      if (isReminded) {
        setMessage({ text: 'Reminders sent succesfully.', type: 'success' });
      }
    },
    update({ original: workflow }) {
      setUpdate(workflow);
      setView('UPDATE');
    },
    updateDraft: async (formData) => {
      const request = new ServerRequest('/document/update', { body: formData, method: 'POST' });
      const body = await download.json(request, 'Error updating draft');
      if (body !== null) {
        const updatedWorkflowObject = new Workflow(body);
        quickUpdate(updatedWorkflowObject);
        await setView('TABLE');
      }
    },
    updateTags: async (comment) => {
      const formData = new FormData();
      formData.append('tags', comment.tags);
      formData.append('index', comment.id);
      formData.append('workflowId', details.workflowId);

      const request = new ServerRequest('/document/comment-tag', { body: formData, method: 'POST' });
      const isTagged = await download.noContent(request, 'Error tagging comment');
      if (isTagged) {
        const newComment = new Comment(comment);
        const updatedWorkflow = details.updateComment(newComment);
        setDetails(updatedWorkflow);

        const index2 = workflows.findIndex((d) => d.id === updatedWorkflow.id);
        const newData = [...workflows];
        newData[index2] = updatedWorkflow;

        setWorkflows(newData);
      }
    },
  };

  switch (view) {
    case 'CREATE':
      return (<Create handle={handle} />);
    case 'DETAILS': {
      return (<Details details={details} handle={handle} />); }
    case 'UPDATE':
      return (<Update details={update} handle={handle} />);
    case 'TABLE':
      return (
        <WorkflowTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          workflows={workflows}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Workflows;
