import React from 'react';

import MessageContext from 'contexts/MessageContext';
import ViewContext from 'contexts/ViewContext';
import CrudService from 'services/CrudService';

import ServerRequest from 'models/ServerRequest';
import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import SearchForm from '../components/searches/SearchForm';
import SearchTable from '../components/searches/SearchTable';
import Search from '../models/Search';

const Searches = () => {
  const PATH = '/nit/search';
  const { setMessage } = React.useContext(MessageContext);
  const { view, setView } = React.useContext(ViewContext);

  const { download } = React.useContext(DownloadContext);

  const [isLoading, setLoading] = React.useState(true);

  const storedVal = (key, defaultVal) => {
    try {
      return localStorage.getItem(key) || defaultVal;
    } catch (error) {
      return defaultVal;
    }
  };

  const [database, setDatabase] = React.useState(storedVal('database', 'EUROCLEAR'));

  const [search, setSearch] = React.useState(() => {
    const newSearch = {};
    try {
      const storedSearch = localStorage.getItem('search');
      if (storedSearch !== null) {
        const searchObject = JSON.parse(storedSearch);
        Object.entries(searchObject).forEach(([key, value]) => {
          newSearch[key] = new Search(value);
        });
      }
    } catch (error) {
      //
    }
    return newSearch;
  });

  const path = (db) => `${PATH}/${db.toLowerCase()}`;

  const [searches, setSearches] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const request = new ServerRequest(PATH);
      const body = await download.json(request, 'Error downloading Searches');

      if (body !== null) {
        const tableData = [];
        Object.entries(body).forEach(([db, searchList]) => {
          searchList.forEach((t) => tableData.push(new Search({ ...t, database: db })));
        });
        setSearches(tableData);
        setLoading(false);
      }
    })();
  }, []);

  const handle = {
    create: (db, context) => async (name) => {
      const data = { ...search[db], name };
      const request = new ServerRequest(path(db), { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'POST' });

      const updatedObject = await download.json(request, `Error saving ${db} search`);

      //      const updatedObject = await service.create({ ...search, name });
      if (updatedObject !== null) {
        const item = new Search(updatedObject);
        const updatedData = CrudService.updateData(searches, item);
        setSearch({ ...search, [db]: item });
        setSearches(updatedData);
        setMessage({ text: `Search ${name} created succesfully`, type: 'success' });
      }

      return new Promise(() => context.hideModal());
    },
    delete: async ({ original: newSearch }) => {
      //      const isDeleted = await service.delete(entry);
      const db = newSearch.database;
      const request = new ServerRequest(path(db), { body: JSON.stringify(newSearch), headers: { 'Content-Type': 'application/json' }, method: 'DELETE' });

      const isDeleted = await download.noContent(request, `Error deleting ${db} search`);

      if (isDeleted) {
        const filteredData = searches.filter((i) => i.id !== newSearch.id);
        setSearches(filteredData);
      }
    },
    edit: async ({ original: newSearch }) => {
      setSearch({ ...search, [newSearch.database]: newSearch });
      setDatabase(newSearch.database);
      await setView('FORM');
    },
    update: (db) => async () => {
      //      const updatedObject = await service.update(search);

      const request = new ServerRequest(path(db), { body: JSON.stringify(search[db]), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });

      const updatedObject = await download.json(request, `Error updating ${db} search`);

      if (updatedObject !== null) {
        const item = new Search(updatedObject);
        const updatedData = CrudService.updateData(searches, item);
        setSearch({ ...search, [db]: item });
        setSearches(updatedData);
        setMessage({ text: `Search ${item.name} updated succesfully`, type: 'success' });
      }
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <SearchForm
          database={database}
          search={search}
          setDatabase={setDatabase}
          setSearch={setSearch}
          handle={handle}
        />
      );
    case 'TABLE':
      return (
        <SearchTable
          isLoading={isLoading}
          searches={searches}
          handle={handle}
        />
      );
    default:
      return <UnknownPage />;
  }
};

export default Searches;
