import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CardHeaderTable from 'components/card-header/CardHeaderTable';

import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import Workflow from '../../model/Workflow';

const WorkflowTable = ({
  handle, hasError, isLoading, workflows,
}) => {
  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const columns = React.useMemo(() => [
    actionCell,
    {
      header: 'Status Bloomberg',
      accessor: 'issuer',
    },
  ]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="MTN Workflows" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={workflows}
        emptyMessage="You have no MTN work flows yet"
        hasError={hasError}
        isLoading={isLoading}
      />

    </Card>
  );
};

WorkflowTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  workflows: PropTypes.arrayOf(PropTypes.instanceOf(Workflow)),
};

WorkflowTable.defaultProps = {
  hasError: false,
  isLoading: false,
  workflows: [],
};

export default WorkflowTable;
