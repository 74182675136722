const CustomValidators = {
  RequiredFile: (event) => {
    const invalid = typeof event === 'undefined' || event === '';
    return Promise.resolve(invalid ? { required: true } : null);
  },
  RequiredArray: (event) => {
    const invalid = event === null || event.length === 0;
    return Promise.resolve(invalid ? { required: true } : null);
  },
  RequiredOption: (event) => {
    console.log('REQUIRED OPTION: %o', event);
    if (typeof event === 'undefined') {
      return null;
    }
    const invalid = event === null || event === '';
    return Promise.resolve(invalid === true ? { required: true } : null);
  },
  Email: (event) => {
    const invalid = !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(event);
    return Promise.resolve(invalid ? { emailAddress: true } : null);
  },
  Frequency: (event) => {
    console.log('CHECKING FREQUENCY EVENT: %o', event);
    return Promise.resolve(event === null || event === '' ? { required: true } : null);
  },
  Password: (event) => {
    const invalidLength = event.length < 8 || event.length > 17;
    const invalidLetters = !/[a-z]/.test(event) || !/[A-Z]/.test(event);
    const invalidNumbers = !/\d/.test(event);
    const invalid = invalidLength || invalidLetters || invalidNumbers;
    return Promise.resolve(invalid ? {
      passwordLength: invalidLength,
      passwordLetters: invalidLetters,
      passwordNumbers: invalidNumbers,
    } : null);
  },
  Token: (event) => {
    const regex = /^\d{6}$/u;
    if (regex.test(event)) {
      return null;
    }
    return { token: true };
  },
};

export default CustomValidators;
