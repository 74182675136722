import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import validate from 'utils/validation';
import MetadataButtons from 'components/metadata/MetadataButtons';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';
import ViewContext from 'contexts/ViewContext';
import NewIssue from '../../model/NewIssue';
import TrancheOverview from './TrancheOverview';
import TrancheObject from '../../model/Tranche';
import NewIssueTask from './NewIssueTask';

const NewIssueManager = ({
  handle, issuerOptions, newIssue, states,
}) => {
  const { setView } = React.useContext(ViewContext);

  const [data, setData] = React.useState(newIssue);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [inputState, setInputState] = React.useState({
    name: 'initial',
    issuer: 'initial',
  });
  const [modalContent, setModalContent] = React.useState('');
  const [task, setTask] = React.useState('');

  const bloombergMessage = `Issuer: ${issuerOptions.find((o) => o.value === newIssue.issuerId).label}
     \nTranches:
     ${newIssue.tranches.map((t) => `\nTranche: ${t.term} year ${t.currency}`)}`;

  const tasks = [
    {
      header: 'Set up New Issue',
      label: 'Set Up',
      state: 'SET_UP',
      text: 'Set up',
    },
    {
      header: 'Send invite to Lead Managers',
      label: 'Send invite',
      state: 'PRE_LAUNCH',
      text: 'Send invite',
    },
    {
      header: 'Launch New Issue',
      label: 'Launch',
      state: 'LAUNCH',
      text: 'Send a message to all investors and news feeds',
    },
    {
      header: 'Open Order Books',
      label: 'Open Books',
      state: 'BOOKS_OPEN',
      text: 'Send a message to all investors that books are open.',
    },
    {
      header: 'Ask Investors to Confirm',
      label: 'Confirm Orders',
      state: 'CONFIRMATION',
      text: 'Send a message to all investors that put orders in asking to confirm their orders.',
    },
    {
      header: 'Close Order Books',
      label: 'Close Books',
      state: 'BOOKS_CLOSED',
      text: 'Send a message that books are closing',
    },
    {
      header: 'Allocate Order Books',
      label: 'Allocate',
      state: 'ALLOCATED',
      text: 'Send allocations to investors',
    },
    {
      header: 'Send pricing',
      label: 'Price',
      state: 'PRICED',
      text: 'Send pricing information to investors',
    },
  ];

  React.useEffect(() => {
    setData(newIssue);

    const stateIndex = tasks.findIndex((s) => s.state === newIssue.state);

    if (stateIndex + 1 < states.length) {
      setTask(tasks[stateIndex + 1]);
    }
  }, [newIssue]);

  const [tranches, setTranches] = React
    .useState(newIssue.tranches
      ? newIssue.tranches.map((t, index) => new TrancheObject({ ...t, key: index })) : []);

  const change = {
    input(event) {
      const { name } = event.target;
      const validation = validate(event.target, inputState[name]);

      if (validation) {
        setInputState({ ...inputState, [name]: validation.inputState });
        setErrorMessage({ ...errorMessage, [name]: validation.errorMessage });
        setData({ ...data, [name]: validation.newValue });
      }
    },
    permissions(event) {
      const updated = new NewIssue({ ...newIssue, event });
      setData(updated);
    },
    select: (name) => (event) => {
      setData({ ...data, [name]: event || [] });
    },
  };

  const setTranche = (key) => (value) => {
    const trancheIndex = tranches.findIndex((t) => t.key === key);
    const newTranches = [...tranches];
    newTranches[trancheIndex] = new TrancheObject(value);
    setTranches(newTranches);
  };

  React.useEffect(() => {
    const updated = new NewIssue({ ...newIssue, tranches });
    setData(updated);
  }, [tranches]);

  const click = {
    action() {
      handle.nextState(newIssue);
    },
    edit() {
      handle.edit(newIssue);
    },
  };

  const clickTask = () => {
    const hideModal = () => {
      setModalContent('');
    };

    setModalContent(<NewIssueTask hideModal={hideModal} nextState={click.action} task={task} />);
  };

  return (
    <Card>
      {modalContent}
      <CardHeaderForm title={`${data.issuer} - ${data.name}`} />
      <div className="co-item">
        <div>
          <Typography type="h1">Tranches</Typography>
          <Typography type="h1">{data.state ? states.find((s) => s.value === data.state).label : ''}</Typography>
        </div>
        {tranches.length ? tranches.map((t, index) => (
          <TrancheOverview
            key={t.key}
            states={states}
            top={index === 0}
            tranche={t}
            setTranche={setTranche(t.key)}
          />
        )) : (<Typography type="h1">No Tranches yet</Typography>)}
      </div>

      <div className="co-form-buttons-plus co-item">
        <div className="co-form-buttons">
          { task ? (
            <Button onClick={clickTask}>
              {task.label}
            </Button>
          ) : ''}
          <Button onClick={click.edit}>Edit</Button>
        </div>
        <MetadataButtons data={data} onChange={change.permissions} disabled />
      </div>

    </Card>
  );
};

NewIssueManager.propTypes = {
  newIssue: PropTypes.instanceOf(NewIssue),
  handle: PropTypes.shape({
    edit: PropTypes.func,
    nextState: PropTypes.func,
  }).isRequired,
  issuerOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
};

NewIssueManager.defaultProps = {
  newIssue: new NewIssue(),
};

export default NewIssueManager;
