import PropTypes from 'prop-types';
import React from 'react';

import LabelTooltipDecorator from '@symphony-ui/uitoolkit-components/components/label-tooltip-decorator/LabelTooltipDecorator';

import DateRangeObject from '../../models/DateRange';
import TimeRange from '../../models/TimeRange';
import DateRange from '../DateRange';
import PeriodRange from '../PeriodRange';

import './TimeRangeInput.css';
import ToggleDecorator from '../toggle-decorator/ToggleDecorator';

const TimeRangeInput = ({
  defaultPeriod, disabled, label, name, onChange, tooltip, value,
}) => {
  const [data, setData] = React.useState(value);
  const [toggled, setToggle] = React.useState((value && value.absolute instanceof DateRangeObject
    && (typeof value.absolute.start !== 'undefined' || typeof value.absolute.end !== 'undefined')) ? 'true' : 'false');

  const change = {
    input(event) {
      const { name: newName, value: newValue } = event.target;
      const updatedTimeRange = { ...data, [newName]: newValue };
      setData(updatedTimeRange);
    },
  };

  React.useEffect(() => {
    let returnObject = new TimeRange();
    if (toggled === 'true' && data.absolute) {
      returnObject.absolute = data.absolute;
    } else if (toggled === 'false' && data.relative) {
      returnObject.relative = data.relative;
    } else {
      returnObject = null;
    }
    onChange({
      target: {
        name,
        value: returnObject,
      },
    });
  }, [data, toggled]);

  return (
    <div className="tk-input-group">
      <LabelTooltipDecorator label={label} tooltip={tooltip} />

      <div className="tk-input__container select-container">
        <ToggleDecorator toggled={toggled} setToggle={setToggle} />
        {toggled === 'true'
          ? (
            <DateRange
              disabled={disabled}
              name="absolute"
              onChange={change.input}
              value={data.absolute}
            />
          )
          : (
            <PeriodRange
              defaultPeriod={defaultPeriod}
              disabled={disabled}
              name="relative"
              onChange={change.input}
              value={data.relative}
            />
          )}
      </div>
    </div>
  );
};
TimeRangeInput.propTypes = {
  defaultPeriod: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.instanceOf(TimeRange),
};

TimeRangeInput.defaultProps = {
  disabled: false,
  label: '',
  tooltip: '',
  value: new TimeRange(),
};

export default TimeRangeInput;
