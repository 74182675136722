import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import User from 'models/User';
import CustomTable from 'components/custom-table/CustomTable';
import CardHeaderTable from 'components/card-header/CardHeaderTable';
import { actionCell } from 'utils/table';

const UserTable = ({
  handle, hasError, isLoading, users,
}) => {
  const columns = React.useMemo(() => [
    actionCell,
    {
      header: 'Display Name',
      accessor: 'displayName',
      className: 'co-table-text co-table-text-l',
    }, {
      header: 'First Name',
      accessor: 'firstName',
      className: 'co-table-text-s',
    }, {
      header: 'Last Name',
      accessor: 'lastName',
      className: 'co-table-text-s',
    }, {
      header: 'Company',
      accessor: 'company',
      className: 'co-table-text co-table-text-m',
    }, {
      header: 'Email Address',
      accessor: 'emailAddress',
      className: 'co-table-text co-table-text-l',
    }, {
      header: 'Division',
      accessor: 'division',
      className: 'co-table-text-l',
    }, {
      header: 'Department',
      accessor: 'department',
      className: 'co-table-text-m',
    }, {
      header: 'Job Function',
      accessor: 'jobFunction',
      className: 'co-table-text-m',
    }, {
      header: 'Location',
      accessor: 'location',
      className: 'co-table-text-l',
    }, {
      header: 'Phone (Mobile)',
      accessor: 'mobilePhoneNumber',
      className: 'co-table-text-m',
    }, {
      header: 'Phone (Work)',
      accessor: 'workPhoneNumber',
      className: 'co-table-text-m',
    },
  ]);

  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Users" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={users}
        emptyMessage="You have no users yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

UserTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.instanceOf(User)),
};

UserTable.defaultProps = {
  hasError: false,
  isLoading: false,
  users: [],
};

export default UserTable;
