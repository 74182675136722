import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import { checkDisabled, initialValidation } from 'utils/initialValidation';
import CardHeaderForm from 'components/card-header-form/CardHeaderForm';

const FormWrapper = ({
  children, data, errorState, name, save, setErrorState, validators,
}) => {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [disabledButton, setButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const newErrorState = await initialValidation(validators, data);
      setErrorState(newErrorState);
    })();
  }, []);

  React.useEffect(() => {
    checkDisabled(setButtonDisabled, errorState);
  }, [errorState]);

  const click = {
    save: async () => {
      setButtonLoading(true);
      save(data);
    },
  };

  return (
    <Card>
      <CardHeaderForm title={data.id ? `Edit ${name}` : `Create ${name}`} />
      {children}
      <div className="form-buttons">
        <Button
          disabled={disabledButton}
          loading={buttonLoading}
          onClick={click.save}
        >
          {data.id ? 'UPDATE' : 'CREATE'}
        </Button>
      </div>
    </Card>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  errorState: PropTypes.shape({

  }).isRequired,
  name: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  setErrorState: PropTypes.func.isRequired,
  validators: PropTypes.shape({

  }).isRequired,
};

export default FormWrapper;
