import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import { getOptionsMulti } from 'utils/change';

const TagInput = ({
  className, disabled, label, name, onChange, tagOptions, tags,
}) => {
  const [options, setOptions] = React.useState(tagOptions.map((t) => ({ label: t, value: t })));
  const [inputValue, setInputValue] = React.useState('');
  const [data, setData] = React.useState(tags);

  const onInputChange = (value2) => {
    setInputValue(value2);
  };

  const onKeyUp = (event) => {
    if (event.key === 'Enter') {
      if (!options.some((d) => d.value === inputValue)) {
        const newOptions = [...options, { label: inputValue, value: inputValue }];
        setOptions(newOptions);
      }

      if (!data.includes(inputValue)) {
        const newValue = [...data, inputValue];
        setData(newValue);
      }
      setInputValue('');
    }
  };

  const onChangeValue = (event) => {
    setData(event.target.value !== null ? event.target.value.map((o) => o.value) : []);
  };

  React.useEffect(() => {
    console.log('new data: %o', data);
    onChange({
      target: {
        name,
        value: data,
      },
    });
  }, [data]);

  return (
    <Dropdown
      className={className}
      isDisabled={disabled}
      inputValue={inputValue}
      isMultiSelect
      label={label}
      onChange={onChangeValue}
      onInputChange={onInputChange}
      onKeyUp={onKeyUp}
      options={options}
      value={getOptionsMulti(data, options)}
    />
  );
};

TagInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
};

TagInput.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  tags: [],
  tagOptions: [],
};

export default TagInput;
