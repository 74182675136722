import PropTypes from 'prop-types';
import React from 'react';

import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import Approval from '../../models/Approval';

const ApprovalsTable = ({
  approvals, handle, isLatest, parties,
}) => {
  const [shownApprovals, setShownApprovals] = React.useState(approvals);
  const [approvalTable, setApprovalTable] = React.useState([]);

  React.useEffect(() => {
    setShownApprovals(approvals);
  }, [approvals]);

  const partyMap = (p) => {
    const foundApproval = shownApprovals.find((a) => a.party === p);
    return foundApproval ? foundApproval.toRowMap() : { party: p, user: '', time: 'not yet' };
  };

  const actionsMenu = (row) => {
    if (!isLatest) {
      return [];
    }
    const { party } = row.original;

    return shownApprovals.some((a) => a.party === party) ? [{
      label: 'Revoke',
      callback: handle.amend('revoke'),
      type: 'info',
    }] : [{
      label: 'Approve',
      callback: handle.amend('approve'),
      type: 'info',
    }];
  };

  React.useEffect(() => {
    const approvalTableData = parties.map((p) => partyMap(p));
    setApprovalTable(approvalTableData);
  }, [shownApprovals]);

  const approvalColumns = [
    actionCell,
    {
      accessor: 'party',
      className: 'co-table-text',
      header: 'Party',
      tooltip: 'Name of the party involved.',
    }, {
      accessor: 'user',
      header: 'User',
    }, {
      accessor: 'time',
      header: 'Time',
    }];

  return (
    <CustomTable
      actionsMenu={actionsMenu}
      columns={approvalColumns}
      data={approvalTable}
      emptyMessage="No approvals yet"
    />
  );
};

ApprovalsTable.propTypes = {
  approvals: PropTypes.arrayOf(PropTypes.instanceOf(Approval)),
  handle: PropTypes.shape({
    amend: PropTypes.func,
  }).isRequired,
  isLatest: PropTypes.bool,
  parties: PropTypes.arrayOf(PropTypes.string),
};

ApprovalsTable.defaultProps = {
  approvals: [],
  isLatest: false,
  parties: [],
};

export default ApprovalsTable;
