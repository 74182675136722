import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Card from '@symphony-ui/uitoolkit-components/components/card/Card';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

import ServerRequest from 'models/ServerRequest';
import ServerURL from 'models/ServerUrl';

import DownloadContext from 'contexts/DownloadContext';
import MessageContext from 'contexts/MessageContext';
import { getOptions } from 'utils/change';

import ResultsShare from '../components/results/ResultsShare';
import SecuritiesTable from '../components/results/SecuritiesTable';
import sources from '../sources/sources';
import FilterObject from '../models/Filter';
import Option from '../models/Option';
import Security from '../models/Security';

import currencyDownload from '../utils/currencyDownload';
import markupCurrencies from '../utils/markupCurrencies';
import markupSecurities from '../utils/markupSecurities';

import Filter from './Filter';
import './Results.css';

const Results = () => {
  const { setMessage } = React.useContext(MessageContext);
  const { download } = React.useContext(DownloadContext);

  const [currencies, setCurrencies] = React.useState([]);
  const [filter, setFilter] = React.useState(new FilterObject());
  const [filterHeight, setFilterHeight] = React.useState(0);
  const [hasError, setError] = React.useState(false);
  const [initPassed, setInitPassed] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [isLoaded, setLoaded] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const [placeHolder, setPlaceHolder] = React.useState('Select...');
  const [rawData, setRawData] = React.useState([]);
  const [unfilteredData, setUnfilteredData] = React.useState([]);

  const [result, setResult] = React.useState(() => {
    const r = localStorage.getItem('result');
    return r !== null ? r : null;
  });
  const [showFilter, setShowFilter] = React.useState(false);
  const [showResultsShare, setShowResultsShare] = React.useState(false);
  const [source, setSource] = React.useState(null);

  const heightCalc = () => (window.innerHeight
    - (window.innerWidth < 700 ? 255 : 350) - filterHeight);

  const [maxHeight, setMaxHeight] = React.useState(heightCalc());

  React.useLayoutEffect(() => {
    const element = document.getElementById('filter-box');
    setFilterHeight(element !== null ? element.offsetHeight : 0);
  });

  //  const [excludeTagsValue, setExcludeTagsValue] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState({
    categories: [],
    cfiGroups: [],
    countries: [],
    tags: [],
  });

  const [companies, setCompanies] = React.useState([]);

  React.useEffect(() => {
    setMaxHeight(heightCalc());
  }, [filterHeight]);

  const frequencyCount = (allTags, tag) => {
    const newTags = { ...allTags };
    if (tag in allTags) {
      newTags[tag] += 1;
    } else {
      newTags[tag] = 1;
    }
    return newTags;
  };

  const requestWithId = (url) => {
    if (result) {
      const [sourceParam, idParam] = result.split('|');
      url.searchParams.append('id', idParam);
      url.searchParams.append('source', sourceParam);
    }
    return new ServerRequest(url);
  };

  const createdOptions = (array) => {
    const countedNames = array.reduce(frequencyCount, {});
    return Object.entries(countedNames)
      .sort(([, v1], [, v2]) => v2 - v1)
      .map(([key, value]) => (new Option({ label: `${key} [${value}]`, value: key })));
  };

  const getResults = () => {
    setUnfilteredData([]);
    setLoading(true);

    (async () => {
      const url = new ServerURL('/nit/results/all');
      const request = requestWithId(url);
      const body = await download.json(request, 'Error downloading securities');
      setLoading(false);

      if (body !== null) {
        const { securities: newSecurities, source: newSource, companies: newCompanies } = body;
        setSource(newSource);
        setCompanies(newCompanies);
        setFilter(new FilterObject(body.filter));
        const securities = newSecurities.map(sources[newSource].objectify ? sources[newSource].objectify : '');
        setRawData(securities.map((s) => ({ ...s, issuername: s.issuername || '' })));

        const cfiedSecurities = markupSecurities(body.source, securities, body.companies);
        const currenciedSecurities = markupCurrencies(body.source, cfiedSecurities, currencies);

        setFilterOptions({
          ...filterOptions,
          cfiGroups: createdOptions(cfiedSecurities.map((s) => (s.cfi ? s.cfi.group : ''))),
          countries: createdOptions(securities.map((s) => s.issuercountryname)),
          categories: createdOptions(securities.map((s) => s.issuercategory)),
        });
        setUnfilteredData(currenciedSecurities);
      }
    })();
  };

  React.useEffect(() => {
    window.onresize = () => {
      setMaxHeight(heightCalc());
    };

    getResults();

    if (!isLoaded) {
      const request = new ServerRequest('/nit/results/options');
      (async () => {
        const body = await download.json(request, 'Error downloading results');
        if (body !== null) {
          if (body.length > 0) {
            const cleaned = body.map((r) => {
              const c = { ...r };
              if (c.options === null) {
                delete c.options;
              }
              return c;
            });
            setOptions(cleaned);
            if (result === null) {
              const first = body[0];
              const option = first.options ? first.options[0] : first;
              setResult(option.value);
            }
            setDisabled(false);
            setLoaded(true);
            setInitPassed(true);
          } else {
            setPlaceHolder('No results found. Please run a search first.');
          }
        } else {
          setError(true);
          setMessage({ text: 'hello' });
          setPlaceHolder('Unable to download securities. Please check connection');
        }
      })();
    }

    (async () => {
      const parsedCurrencies = await currencyDownload();
      setCurrencies(parsedCurrencies);
    })();
  }, []);

  React.useEffect(() => {
    if (initPassed) {
      localStorage.setItem('result', result);

      setUnfilteredData([]);
      if (result) {
        getResults();
      }
    }
  }, [result]);

  React.useEffect(() => {
    const markedupSecurities = markupCurrencies(source, rawData, currencies);
    setUnfilteredData(markedupSecurities);
  }, [currencies]);

  React.useEffect(() => {
    if (companies && companies.length) {
      const tags = unfilteredData.flatMap((t) => t.tags);
      setFilterOptions({ ...filterOptions, tags: createdOptions(tags) });
    }
  }, [unfilteredData]);

  const change = (event) => {
    setResult(event.target.value.value);
  };

  const click = {
    filter() {
      setShowFilter(!showFilter);
    },
    share() {
      setShowResultsShare(true);
    },
  };

  return (
    <Card>
      <ResultsShare
        filter={filter}
        result={result}
        securities={unfilteredData.filter(filter.create()).map((s) => new Security(s))}
        setShow={setShowResultsShare}
        show={showResultsShare}
        source={source}
      />
      <div className="co-item">
        <Typography type="h1">Results</Typography>
      </div>
      <div className="co-dropdown-row co-item">
        <Button className="co-button" disabled={hasError} iconButton onClick={click.filter}><Icon iconName="filter" /></Button>
        <Button className="co-button" disabled={hasError} iconButton onClick={click.share}><Icon iconName="share" /></Button>

        <Dropdown
          className="co-dropdown"
          isDisabled={hasError}
          label="Please select from saved results"
          onChange={change}
          options={options}
          placeHolder={placeHolder}
          value={getOptions(result, options)}
        />
      </div>

      {showFilter
        ? <Filter setFilter={setFilter} filter={filter} options={filterOptions} /> : ''}

      {!isDisabled
        ? (
          <SecuritiesTable
            currencies={currencies}
            filteredData={unfilteredData.filter(filter.create())}
            hasError={hasError}
            isLoading={isLoading}
            maxHeight={maxHeight}
            searchable
            source={source}
          />
        ) : ''}
    </Card>
  );
};

export default Results;
