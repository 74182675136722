import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import Loader from '@symphony-ui/uitoolkit-components/components/loader/Loader';
import Nav from '@symphony-ui/uitoolkit-components/components/nav/Nav';

import ConnectivityAlert from 'pages/ConnectivityAlert';
import AppViewContext from 'contexts/AppViewContext';

import { activeTab, setActiveTab } from 'utils/helpers';
import TabContainer from 'components/TabContainer';
import MenuItems from 'components/menu-items-list/MenuItemsList';
import Settings from './settings/Settings';
import SettingsLabel from './settings/SettingsLabel';

const PageWrapper = ({
  appName, logoLocation, pages, settings,
}) => {
  const { authorities } = React.useContext(AppViewContext);

  const [tabId, setTabId] = React.useState(activeTab(appName));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [page, setPage] = React.useState({ element: (<Loader />) });

  const defaultPage = { element: (<Loader />) };

  const tabs = pages
    .filter((p) => (!p.auth || authorities[appName].includes(`ROLE_${p.auth}`)))
    .map((p) => ({ id: p.label, label: p.label }));

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const onActiveTabChange = (event) => {
    setTabId(event.id);
    setActiveTab(appName, event.id);
  };

  React.useEffect(() => {
    const newPage = pages.find((p) => p.label === tabId) || defaultPage;
    setPage(newPage);
  }, [tabId]);

  return (
    <div>
      <ConnectivityAlert />
      <div className="co-form-buttons-plus">

        <div className="co-fixed-top-row">
          <img alt="logo" className="co-app-icon" src={logoLocation} width={24} height={24} />
          <Nav activeItemId={tabId} className="co-nav-tabs" items={tabs} onActiveTabChange={onActiveTabChange} />
        </div>
        <div>
          <Button className="co-menu-button" iconButton onClick={openMenu} variant="tertiary"><Icon iconName="settings" /></Button>
          <MenuItems show={menuOpen} />
        </div>
      </div>
      <TabContainer defaultView={page.defaultView}>{page.element}</TabContainer>
    </div>

  );
};

PageWrapper.propTypes = {
  appName: PropTypes.string.isRequired,
  logoLocation: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    auth: PropTypes.string,
    defaultView: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  settings: PropTypes.node,
};

PageWrapper.defaultProps = {
  settings: null,
};

export default PageWrapper;
