const releaseSources = {
  BLS: [
    { accessor: 'summary', header: 'Summary' },
  ],
  BOE: [
    { accessor: 'category', header: 'Category' },
    { accessor: 'type', header: 'Type' },
  ],
  ECB: [
    { accessor: 'name', header: 'Name' },
    { accessor: 'dataSet', header: 'Data Set' },
  ],
  IFO: [
    { accessor: 'name', header: 'Name' },
  ],
  MARKIT: [
    { accessor: 'name', header: 'Name' },
  ],
};

export default releaseSources;
