import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';

import { getOptions } from 'utils/change';

import cfiOptions from '../../utils/cfiOptions';
import TimeRangeInput from '../time-range-input/TimeRangeInput';
import TimeRange from '../../models/TimeRange';
import Period from '../../models/Period';
import LEIInput from '../searches/esma-search-form/LEIInput';
import MultiInput from '../searches/esma-search-form/MultiInput';
import RemoteFilterItem from '../filter-item/RemoteFilterItem';
import './NameValuePair.css';

const NameValuePair = ({
  description, name,
  remove, setValues, type, values,
}) => {
  const printPeriod = (period) => (period && period?.periodMultiplier && period.period ? period.periodMultiplier + period.period : '');

  const parseDateBack = (timeRange) => [
    timeRange.absolute && timeRange.absolute.start ? timeRange.absolute.start : '',
    timeRange.absolute && timeRange.absolute.end ? timeRange.absolute.end : '',
    timeRange.relative ? printPeriod(timeRange.relative.start) : '',
    timeRange.relative ? printPeriod(timeRange.relative.end) : ''];

  const change = {
    date: (event) => {
      setValues(parseDateBack(event.target.value));
    },
    single: (event) => {
      setValues([event.target.value.value]);
    },
    multi: (event) => {
      const newValues = event.target.value.map((o) => o.value);
      setValues(newValues);
    },
    text: (event) => {
      setValues([event.target.value]);
    },
  };

  const parsePeriod = (periodString) => {
    if (!periodString || periodString === '') {
      return Period.init('D');
    }
    return Period.from(periodString);
  };

  const parseDate = (rangeValues) => new TimeRange({
    absolute: {
      start: rangeValues[0] && rangeValues[0] !== '' ? rangeValues[0] : null,
      end: rangeValues[1] && rangeValues[1] !== '' ? rangeValues[1] : null,
    },
    relative: {
      start: parsePeriod(rangeValues[2]),
      end: parsePeriod(rangeValues[3]),
    },
  });

  return (
    <div className="co-dropdown-row co-dropdown-first">
      {(() => {
        switch (type) {
          case 'FILTER':
            return (
              <RemoteFilterItem
                disabled={false}
                label={name.substring(0, 1).toUpperCase() + name.substring(1)}
                name={name}
                onChange={setValues}
                path={`/nit/companies${name !== 'tags' ? `/esma/${name}/options` : '/tags/options/esma'}`}
                value={values}
              />
            );

          case 'MULTI':
            return (
              <MultiInput
                label={description}
                name={name}
                onChange={change.multi}
                values={values}
              />
            );
          case 'LEI':
            return <LEIInput onChange={change.single} values={values} />;
          case 'DATE':
            return (
              <TimeRangeInput
                defaultPeriod="D"
                label={description}
                name={name}
                onChange={change.date}
                value={parseDate(values)}
              />
            );
          case 'DATETIME':
            return (
              <TimeRangeInput
                defaultPeriod="D"
                label={description}
                name={name}
                onChange={change.date}
                value={parseDate(values)}
              />
            );
          case 'CFI':
            return <Dropdown label="Category" name={name} onChange={change.single} options={cfiOptions} value={getOptions(values[0], cfiOptions)} />;
            // return <CFIInput onChange={change.single} values={nameValue.values} />;
          default:
            return <TextField label={description} name={name} onChange={change.text} value={values.length > 0 ? values[0] : ''} />;
        }
      })() }
      <Button className="co-button" iconButton onClick={remove}><Icon iconName="delete" /></Button>
    </div>
  );
};

NameValuePair.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({})]).isRequired,
  setValues: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default NameValuePair;
