import sources from '../sources/sources';

/**
 * This uses https://www.npmjs.com/package/xlsx-populate
 * @param {Security[]} data
 */

const exportToExcel = async (data, source) => {
  const XlsxPopulate = await import(
    /* webpackChunkName: "xlsx-chunk" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    'xlsx-populate/browser/xlsx-populate'
  );
  const securityProperties = sources[source].properties;

  const workbook = await XlsxPopulate.default.fromBlankAsync();

  const sheet = workbook.sheet('Sheet1');
  sheet.range(1, 1, 1, securityProperties.length + 1).autoFilter();
  sheet.freezePanes(0, 1);

  securityProperties.forEach((p, index) => {
    sheet.cell(1, index + 1).value(p.label);
    sheet.column(index + 1).columnName(p.label);
    if (p.width) sheet.column(index + 1).width(p.width);
  });

  data.forEach((s, rowIndex) => {
    securityProperties.forEach((p, columnIndex) => {
      const array2 = p.field.split('.');
      let t = { ...s };
      //      console.log('T: %o, array2: %o, p: o%', t, array2, p);
      for (let i = 0; i < array2.length; i += 1) {
        t = t[array2[i]];
      }
      //     console.log('T after: %o', t);
      const cell = sheet.cell(rowIndex + 2, columnIndex + 1);
      if (t === null || typeof t === 'undefined') {
        cell.value('-');
      } else if (p.type === 'date' && t !== 'Perpetual') {
        cell.value(new Date(t)).style('numberFormat', 'DD MMM YYYY');
      } else if (p.type === 'number' && t !== '') {
        const num = (typeof t === 'number' ? t : parseInt(t.replaceAll(',', ''), 10));
        cell.value(num).style('numberFormat', '#,##0;-#,##0');
      } else if (p.type === 'array') {
        cell.value(t.join(', '));
      } else {
        cell.value(t);
      }
    });
  });

  sheet.range(1, 1, data.length + 1, securityProperties.length + 1).style('fontSize', 10).style('fontFamily', 'Arial');

  const blob = await workbook.outputAsync();

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // If IE, you must uses a different method.
    window.navigator.msSaveOrOpenBlob(blob, 'out.xlsx');
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'out.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};

export default exportToExcel;
