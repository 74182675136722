export const passwordTest = (value) => {
  if (value.length < 8 || value.length > 16) {
    return false;
  }
  if (!/\d/.test(value)) {
    return false;
  }
  if (!/[a-z]/.test(value)) {
    return false;
  }
  if (!/[A-Z]/.test(value)) {
    return false;
  }
  return true;
};

export const passwordRequirements = [
  'between 8 and 16 characters',
  'at least one number',
  'one lower case and one upper case character',
];
