import React from 'react';

import Ipos from 'pages/ipos/Ipos';
import Benchmarks from '../benchmarks/Benchmarks';
import Companies from '../companies/Companies';
import Currencies from '../currencies/Currencies';
import Releases from '../releases/Releases';
import Users from './Users';

import { localIconUrl } from '../../../cedar/utils/iconUrls';
import PageWrapper from '../PageWrapper';
import AdminSettings from './AdminSettings';

const AdminApp = () => (
  <PageWrapper
    appName="ADMIN"
    logoLocation={localIconUrl(240)}
    pages={[
      { defaultView: 'TABLE', element: (<Users />), label: 'Users' },
      { defaultView: 'TABLE', element: (<Companies />), label: 'Companies' },
      { defaultView: 'TABLE', element: (<Currencies />), label: 'Currencies' },
      { defaultView: 'TABLE', element: (<Benchmarks />), label: 'Benchmarks' },
      { defaultView: 'TABLE', element: (<Releases />), label: 'Releases' },
      { defaultView: 'TABLE', element: (<Ipos />), label: 'Ipos' },
    ]}
    settings={(<AdminSettings />)}
  />
);

export default AdminApp;
