import React from 'react';

import FrontPage from '../../site/pages/main-page/FrontPage';
import Main from '../../site/pages/main-page/Main';

import CedarApp from '../../cedar/pages/CedarApp';
import NewIssueTrackerApp from '../../nit/pages/NewIssueTrackerApp';
import AdminApp from './admin-app/AdminApp';
import MTNApp from '../../mtn/pages/MTNApp';
import SyndicateApp from '../../syndicate/pages/SyndicateApp';

import './generic.css';
import AppViewContext from '../contexts/AppViewContext';

const LocationRouter = () => {
  const { appView } = React.useContext(AppViewContext);
  switch (appView) {
    case 'MAIN':
      return <Main />;
    case 'CEDAR':
      return <CedarApp />;
    case 'NIT':
      return <NewIssueTrackerApp />;
    case 'ADMIN':
      return <AdminApp />;
    case 'MTN':
      return <MTNApp />;
    case 'SYND':
      return <SyndicateApp />;
    default:
      return <FrontPage />;
  }
};

export default LocationRouter;
