/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const CookiePolicy = ({ hideModal }) => (
  <div className="modal-content">
    <Typography type="small">First implemented: November 30, 20</Typography>

    <Typography>We use cookies, web beacons (also known as pixel tags) and similar technologies to collect and store the information we automatically collect about your computer, device, and use of our website at symphony.capitalmarkets.online (the “Site”). You can find out more about cookies and how to control them in the information below.</Typography>

    <Typography>If you do not accept the use of these cookies, please disable them by changing your browser settings so that cookies from the Site cannot be placed on your computer or mobile device as described in Section 4.</Typography>
    <Typography type="h2">1. What is a cookie?</Typography>
    <Typography>        Cookies are Typography files containing small amounts of information which are downloaded to your computer or mobile device when you access our services. We use both session cookies and persistent cookies to identify that you’ve accessed the Site. A session cookie is used to identify a particular visit to our Site. These cookies expire after a short time, or when you close your web browser after using our Site. A persistent cookie will remain on your device for a set period of time specified in the cookie. We use these cookies when we need to identify you over a longer period of time. For example, we would use a persistent cookie if you asked that we keep you signed in to our Site.</Typography>

    <Typography type="h2">        2. How do we use cookies?</Typography>
    <Typography>        We use cookies to:</Typography>

    <Typography>        track traffic flow and patterns of travel in connection with the Site;</Typography>
    <Typography>        understand the total number of visitors to our Site on an ongoing basis and the types of internet browsers (e.g., Firefox, Chrome or Internet Explorer) and operating systems (e.g., Windows or Mac OS) used by our visitors;</Typography>
    <Typography>        monitor the performance of the Site and to continually improve it;</Typography>
    <Typography>        customize and enhance your online experience; and</Typography>
    <Typography>        enable us to advertise off this Site.</Typography>
    <Typography type="h2">        3. What types of cookies we use and what they are for?</Typography>
    <Typography>
      The types of cookies used by us in connection with the Site can be classified into one of four categories, namely ‘essential Site cookies’, ‘functionality cookies’, ‘analytics and performance cookies’ and ‘advertising cookies’. We’ve set out some further information about each category, and the purposes of the cookies we set below: Essential cookies

      These cookies are essential to provide you with this Site and any services available through the Site and to use some of its features, such as access to secure areas. Without these cookies, services you have asked for, like secure login accounts, would not be possible. Functionality cookies Functionality cookies record information about choices you’ve made and allow us to tailor the Site to you. These cookies mean that when you continue to use or come back to the Site, we can provide you with our services as you have asked for them to be provided. For example, these cookies allow us to:

      Save your location preference if you have set your location on your homepage, if applicable;
      Remember settings you have applied, such as layout, Typography size, preferences, and colors;
      Show you when you are logged in; and
      Store accessibility options.
      Performance / Analytics Cookies
      We use performance/analytics cookies to analyze how the Site is accessed, used, or is performing in order to provide you with a better user experience and to maintain, operate and continually improve the Site. For example, these cookies allow us to:

      Better understand our Site visitors so that we can improve how we present our content;
      Test different design ideas for particular pages, such as our homepage;
      Collect information about Site visitors such as where they are located and what browsers they are using;
      Determine the number of unique users of the Site;
      Improve the Site by measuring any errors that occur; and
      Conduct research and diagnostics to improve product offerings.
      Advertising and Tracking Cookies
      We allow third party advertising companies to place cookies on our Site. These cookies enable such companies to track your activity across various sites where they display ads and record your activities so they can show ads that they consider relevant to you as you browse the Internet. These cookies are anonymous — they store information about the content you are browsing, but not about who you are.
    </Typography>

    <Typography type="h2">        4. How to manage or remove cookies</Typography>
    <Typography>        You have the right to choose whether or not to accept cookies and we have explained how you can exercise this right below. However, please note that if you choose to refuse cookies you may not be able to use the full functionality of our Site. Most web browsers allow you to change your cookie settings. The “Help” function within your web browser will tell you what you need to do.</Typography>

    <Typography type="h2">        5. Revisions to this Cookie Policy</Typography>
    <Typography>        We may revise this Cookie Policy to reflect changes in our practices and services. We will revise the Cookie Policy to include a “Last Updated” date at the top of this Cookie Policy to indicate when the Cookie Policy was last materially changed. If we make any material changes in the way we collect, use, and/or share information held in cookies, we will notify you by prominently posting notice of the changes on the Site. We recommend that you check this page from time to time to inform yourself of any changes in this Cookie Policy.</Typography>

    <Typography type="h2">        6. Cookies set in the past</Typography>
    <Typography>        If you have disabled on or more cookies, we may still use information collected from cookies prior to your disabled preference being set, however, we will stop using the disabled cookie to collect any further information.</Typography>

    <Typography type="h2">        7. Contact us</Typography>
    <Typography>        If you have any questions or comments about this Cookie Policy, or privacy matters generally, please contact us via email at legal@capitalmarkets.online or at the address provided below:</Typography>

    <Typography>
      Vanadis Limited
      16 Stadium Street
      London
      SW10 0PT
      United Kingdom
      Attn: General Counsel

    </Typography>
    <Button onClick={hideModal}>OK</Button>
  </div>
);

CookiePolicy.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default CookiePolicy;
