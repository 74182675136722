import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';

import DownloadContext from 'contexts/DownloadContext';
import ServerRequest from 'models/ServerRequest';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';

const Credentials = ({ initial, source, title }) => {
  const [data, setData] = React.useState(initial);
  const [disabled, setDisabled] = React.useState(!initial.password || !initial.username);

  const { download } = React.useContext(DownloadContext);

  const change = {
    input(event) {
      const { name, value } = event.target;
      setData({ ...data, [name]: value });
    },
  };

  const click = async () => {
    const request = new ServerRequest(`/nit/credentials/${source.toLowerCase()}`,
      { body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, method: 'PUT' });

    await download.json(request, 'Error saving credentials');
  };

  React.useEffect(() => {
    const isEmpty = !initial.password || !initial.username;
    setDisabled(isEmpty);
  }, [data]);

  return (
    <div className="creds">
      <Typography type="h2">{title}</Typography>

      <div className="cred-input">
        <TextField label="username" name="username" onChange={change.input} value={data.username} />
      </div>

      <div className="cred-input">
        <TextField isMasked label="password" name="password" onChange={change.input} value={data.password} />
      </div>
      <Button className="cred-button" disabled={disabled} iconButton onClick={click}><Icon iconName="share" /></Button>
    </div>
  );
};

Credentials.propTypes = {
  initial: PropTypes.shape({
    password: PropTypes.string,
    username: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Credentials.defaultProps = {
  initial: {
    password: '',
    username: '',
  },
};

export default Credentials;
