import React from 'react';

import CrudService from 'services/CrudService';
import ServerRequest from 'models/ServerRequest';
import ViewContext from 'contexts/ViewContext';

import DownloadContext from 'contexts/DownloadContext';
import UnknownPage from 'components/unknown-page/UnknownPage';
import Report from '../models/Report';
import Option from '../models/Option';
import ReportForm from '../components/reports/ReportForm';
import ReportTable from '../components/reports/ReportTable';

const Reports = () => {
  const { download } = React.useContext(DownloadContext);
  const { view, setView } = React.useContext(ViewContext);

  const [data, setData] = React.useState();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [report, setReport] = React.useState();
  const [searchOptions, setSearchOptions] = React.useState();

  const service = new CrudService({ download, name: 'Report', path: '/nit/report' });

  React.useEffect(() => {
    (async () => {
      const body = await service.get();
      setLoading(false);
      if (body !== null) {
        const tableData = body.map((r) => new Report(r));
        setData(tableData);
      } else {
        setError(true);
      }
    })();

    (async () => {
      const request = new ServerRequest('/nit/search/options');
      const body = await download.json(request, 'Error downloading searches');
      if (body !== null) {
        setSearchOptions(body.map((o) => new Option(o)));
      }
    })();
  }, []);

  const handle = {
    create: async () => {
      setReport(new Report());
      await setView('FORM');
    },
    delete: async ({ original: report2 }) => {
      const isDeleted = await service.delete(report2);

      if (isDeleted) {
        const filteredData = data.filter((i) => i.id !== report2.id);
        setData(filteredData);
      }
    },
    edit: async ({ original: entry }) => {
      setReport(entry);
      await setView('FORM');
    },
    save: (item) => async () => {
      let updateEntry;
      if (item.id) {
        updateEntry = await service.update(item);
      } else {
        updateEntry = await service.create(item);
      }
      if (updateEntry !== null) {
        const newReport = new Report(updateEntry);
        const updatedData = CrudService.updateData(data, newReport);
        setData(updatedData);
        await setView('TABLE');
      }
    },
    toggleActive: async (id, value) => {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('active', value);

      const request = new ServerRequest('/nit/report/activate', { body: formData, method: 'PATCH' });

      const body = await download.json(request, 'Error (de)activating report');

      const newReport = new Report(body);
      const updatedData = CrudService.updateData(data, newReport);
      setData(updatedData);
    },
  };

  switch (view) {
    case 'FORM':
      return (
        <ReportForm
          handle={handle}
          options={searchOptions}
          report={report}
          service={service}
        />
      );
    case 'TABLE':
      return (
        <ReportTable
          handle={handle}
          hasError={hasError}
          isLoading={isLoading}
          options={searchOptions}
          reports={data}
        />
      );
    default:
      return (<UnknownPage />);
  }
};

export default Reports;
