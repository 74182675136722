import React from 'react';
import ReactDOM from 'react-dom';
// import 'symphony-bdk-ui-toolkit/dist/index.css';

import Routes from 'pages/routes';
import { handleOutline } from 'utils/helpers/help-functions';
import 'public/sass/main.scss';
import initServiceWorker from './init-service-worker';

window.SYMPHONY = {
  remote: {
    hello: async () => {
      const theme = localStorage.getItem('theme');
      if (theme) {
        return JSON.parse(theme);
      }
      return { themeV2: { color: 'light', size: 'normal' } };
    },
  },
  services: {
    subscribe: () => ({
      listen: (type, callback) => {
        document.addEventListener(type, callback);
      },
    }),
  },
};

handleOutline(); // Accessibility

const appWrapper = async () => {
  let theme = { themeV2: { name: 'light', size: 'normal' } };

  try {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) theme = JSON.parse(storedTheme);
  } catch (error) {
    // No access to localStorage so no theme
  }

  ReactDOM.render(
    <Routes theme={theme} />,
    document.getElementById('root'),
  );
};

appWrapper();

// initServiceWorker();
