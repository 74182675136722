class Approval {
  constructor(approval) {
    Object.assign(this, approval);
  }

  toRowMap() {
    return {
      party: this.party,
      user: this.user.displayName,
      time: new Date(this.time).toLocaleString(),
    };
  }
}

export default Approval;
