import PropTypes from 'prop-types';
import React from 'react';

import Card from '@symphony-ui/uitoolkit-components/components/card/Card';

import CardHeaderTable from 'components/card-header/CardHeaderTable';
import CustomTable from 'components/custom-table/CustomTable';
import { actionCell } from 'utils/table';
import TermSheet from '../../model/TermSheet';

const TermSheetTable = ({
  handle, hasError, isLoading, termSheets,
}) => {
  const actionsMenu = [
    { label: 'Edit', callback: handle.edit, type: 'info' },
    { label: 'Delete', callback: handle.delete, type: 'error' },
  ];

  const datePrinter = (date) => new Date(date).toLocaleDateString();

  const numberPrinter = (number) => new Intl.NumberFormat().format(number);

  const columns = React.useMemo(() => [
    actionCell,
    {
      accessor: 'issuer',
      header: 'Issuer',
    }, {
      accessor: 'currency',
      header: 'Currency',
    }, {
      accessor: ({ nominal }) => numberPrinter(nominal),
      header: 'Nominal',
      id: 'nominal',
    }, {
      accessor: ({ issueDate }) => datePrinter(issueDate),
      id: 'issueDate',
      header: 'Issue Date',
    }, {
      accessor: ({ maturityDate }) => datePrinter(maturityDate),
      header: 'Maturity',
      id: 'maturityDate',
    }, {
      accessor: 'interestBasis',
      header: 'Interest Basis',
    }, {
      accessor: 'coupon',
      header: 'Coupon',
    },
  ]);

  return (
    <Card>
      <CardHeaderTable create={handle.create} disabled={hasError} title="Term Sheets" />
      <CustomTable
        actionsMenu={actionsMenu}
        columns={columns}
        data={termSheets}
        emptyMessage="You have no term sheets yet"
        hasError={hasError}
        isLoading={isLoading}
      />
    </Card>
  );
};

TermSheetTable.propTypes = {
  handle: PropTypes.shape({
    create: PropTypes.func,
    delete: PropTypes.func,
    edit: PropTypes.func,
  }).isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  termSheets: PropTypes.arrayOf(PropTypes.instanceOf(TermSheet)),
};

TermSheetTable.defaultProps = {
  hasError: false,
  isLoading: false,
  termSheets: [],
};

export default TermSheetTable;
