class DateRange {
  constructor(dateRange) {
    Object.assign(this, dateRange);
    delete this.singleDay;
    if (this.start === null) {
      delete this.start;
    }

    if (this.end === null) {
      delete this.end;
    }
  }

  toString() {
    if (this.start && this.end) {
      return `from ${this.start} to ${this.end}`;
    } if (this.start && !this.end) {
      return `from ${this.start}`;
    } if (!this.start && this.end) {
      return `up to ${this.end}`;
    }
    return 'Any';
  }

  update(name, value) {
    return new DateRange({ ...this, [name]: value });
  }

  isEmpty() {
    if ('start' in this && this.start !== null) return false;
    if ('end' in this && this.end !== null) return false;
    return true;
  }
}

export default DateRange;
