import React from 'react';
import PropTypes from 'prop-types';

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.shape({}),
    preFilteredRows: PropTypes.arrayOf(PropTypes.shape({})),
    setFilter: PropTypes.func,
  }).isRequired,
};

export default DefaultColumnFilter;
