import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import TextArea from '@symphony-ui/uitoolkit-components/components/input/TextArea';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';

const NewIssueTask = ({
  bloombergMessage, hideModal, nextState, task,
}) => {
  const [bloombergInput, setBloombergInput] = React.useState(bloombergMessage);

  const change = {
    bloombergInput(event) {
      setBloombergInput(event.target.value);
    },
  };

  const click = {
    cancel() {
      return new Promise(hideModal);
    },
    copy: async () => {
      try {
        await navigator.clipboard.writeText(bloombergInput);
        console.log(`Copied ${bloombergInput}`);
      } catch (err) {
        console.error('Oops something went wrong');
      }
    },
    nextState: async () => {
      await nextState();

      return new Promise(hideModal);
    },
  };

  React.useEffect(() => {
    const label = document.getElementById('copy-label');
    if (label) { label.onclick = click.copy; }
  });

  return (
    <Modal show closeButton onClose={hideModal} parentNode={document.body}>

      <Typography type="h2">{task.text}</Typography>
      {bloombergMessage
        ? <TextArea label="Copy to use elsewhere" onChange={change.bloombergInput} tooltip="Click to copy, then paste into Bloomberg chat" value={bloombergInput} /> : ''}
      <div className="co-form-buttons co-item">
        <Button onClick={click.nextState} variant="destructive">yes</Button>
        <Button onClick={click.cancel} variant="secondary">No</Button>
      </div>
    </Modal>
  );
};

NewIssueTask.propTypes = {
  bloombergMessage: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  nextState: PropTypes.func.isRequired,
  task: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

export default NewIssueTask;
