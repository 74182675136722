import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@symphony-ui/uitoolkit-components/components/dropdown/Dropdown';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';

import { getOptions } from 'utils/change';
import Period from '../../models/Period';

import './PeriodInput.scss';

const PeriodInput = ({
  defaultPeriod, disabled, name, onChange, period,
}) => {
  const [data, setData] = React.useState({
    period: period && period.period ? period.period : defaultPeriod,
    periodMultiplier: period && period.periodMultiplier ? period.periodMultiplier.toString() : '',
  });

  const options = [{ label: 'D', value: 'D' }, { label: 'W', value: 'W' }, { label: 'M', value: 'M' }, { label: 'Y', value: 'Y' }];

  React.useEffect(() => {
    if (data.periodMultiplier !== '-') {
      const newPeriod = data.periodMultiplier !== ''
        ? new Period({
          period: data.period,
          periodMultiplier: Number.parseInt(data.periodMultiplier, 10),
        })
        : null;
      const returnObject = {
        target: {
          name,
          value: newPeriod,
        },
      };
      onChange(returnObject);
    }
  }, [data]);

  const change = {
    period(event) {
      document.activeElement.blur();
      setData({ ...data, period: event.target.value.value });
    },
    periodMultiplier(event) {
      //      const regex = /^(0|-?([1-9][0-9]{0,2})?)$/u;
      const regex = new RegExp(`^${Period.multiplierRegexString}$`, 'u');
      const newValue = event.target.value;
      if (regex.test(newValue) || newValue === '') {
        setData({ ...data, periodMultiplier: newValue });
      }
    },
  };

  return (
    <TextField
      className="input-with-addon period-input"
      disabled={disabled}
      label=""
      name="periodMultiplier"
      onChange={change.periodMultiplier}
      rightDecorators={(
        <Dropdown
          className="select-addon"
          disabled={disabled}
          isTypeAheadEnabled={false}
          label=""
          onChange={change.period}
          options={options}
          value={getOptions(data.period, options)}
        />
    )}
      value={data.periodMultiplier}
    />
  );
};

PeriodInput.propTypes = {
  defaultPeriod: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  period: PropTypes.instanceOf(Period),
};

PeriodInput.defaultProps = {
  disabled: false,
  period: null,
};

export default PeriodInput;
