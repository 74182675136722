import React from 'react';
import PropTypes from 'prop-types';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Modal from '@symphony-ui/uitoolkit-components/components/modal/Modal';
import TextField from '@symphony-ui/uitoolkit-components/components/input/TextField';
import Validation from '@symphony-ui/uitoolkit-components/components/validation/Validation';
import { Validators } from '@symphony-ui/uitoolkit-components/core/validators/validators';
import Typography from '@symphony-ui/uitoolkit-components/components/typography/Typography';

const SearchNameInput = ({ hideModal, save }) => {
  const [value, setValue] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);

  const validators = {
    name: Validators.MinLength(3),
  };

  const changeName = (event) => {
    setValue(event.target.value);
  };

  const click = {
    save: () => {
      save(value);
      hideModal();
    },
  };

  const onValidationChanged = (event) => {
    setDisabled(!event);
  };

  return (
    <Modal className="co-items" show closeButton onClose={hideModal} parentNode={document.body}>
      <div>
        <Typography type="h1">Please give your Search a name</Typography>
      </div>
      <div>
        <Validation errorMessage={{ minlength: 'Please provide a name with at least three letters' }} validator={validators.name} onValidationChanged={onValidationChanged}>
          <TextField label="Name" onChange={changeName} value={value} />
        </Validation>
      </div>
      <div className="co-Form-buttons">
        <Button disabled={disabled} onClick={click.save}>SAVE</Button>
      </div>
    </Modal>
  );
};

SearchNameInput.propTypes = {
  hideModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default SearchNameInput;
