import PropTypes from 'prop-types';
import React from 'react';

import Button from '@symphony-ui/uitoolkit-components/components/button/Button';
import Icon from '@symphony-ui/uitoolkit-components/components/icon/Icon';

import Metadata from 'components/Metadata';
import Permissions from 'components/permissions/Permissions';

const MetadataButtons = ({ disabled, data, onChange }) => {
  const [modalContent, setModalContent] = React.useState('');

  const hideModal = () => {
    setModalContent('');
  };

  const click = {
    showMeta() {
      setModalContent(<Metadata hideModal={hideModal} value={data} />);
    },
    showPermissions() {
      setModalContent(
        <Permissions
          hideModal={hideModal}
          onChange={onChange}
          value={data.permissions}
        />,
      );
    },
  };

  return (
    <div className="co-form-buttons">
      {modalContent}
      {data.id ? (
        <Button iconButton onClick={click.showMeta}>
          <Icon iconName="info-round" />
        </Button>
      ) : ''}

      {!disabled ? (
        <Button iconButton onClick={click.showPermissions}>
          <Icon iconName="people" />
        </Button>
      ) : ''}
    </div>
  );
};
MetadataButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({}),
  }),
  onChange: PropTypes.func.isRequired,
};

MetadataButtons.defaultProps = {
  data: {},
};

export default MetadataButtons;
