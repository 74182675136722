export const change = (data, setData) => ({
  dropdown: (name) => (event) => {
    const { value } = event.target;
    let newValue = null;
    if (Array.isArray(value)) {
      newValue = value ? value.map((v) => v.value) : null;
    } else if (value !== null && 'value' in value) {
      newValue = value.value;
    }
    setData({ ...data, [name]: newValue });
  },
  input(event) {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  },
});

export const validationChanged = (errorState, setErrorState) => (name) => (isValid) => {
  console.log('Checking validation changed %o, %o, %o, %o', errorState, name, isValid);
  setErrorState({ ...errorState, [name]: isValid });
};

export const getOptions = (value, dropdownOptions) => dropdownOptions
  .concat(dropdownOptions.filter((o) => 'options' in o && o.options !== null).flatMap((o) => o.options))
  .filter((o) => o.value === value);

export const getOptionsMulti = (value, dropdownOptions) => {
  if (typeof value === 'undefined' || value === null || value.length === 0) {
    return null;
  }
  return dropdownOptions
    .concat(dropdownOptions.filter((o) => 'options' in o && o.options !== null).flatMap((o) => o.options))
    .filter((o) => value.includes(o.value));
};

export const onMenuClose = () => {
  document.activeElement.blur();
};
